import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { tap } from 'rxjs/operators';
import { EventWithCheckAccountJoinEventResponseModel } from 'src/app/core/api/be/models';
import { EventService } from 'src/app/core/api/be/services';

@Component({
  selector: 'app-card-event',
  templateUrl: './card-event.component.html',
  styleUrls: ['./card-event.component.scss']
})
export class CardEventComponent implements OnInit {

  @Input() event!: EventWithCheckAccountJoinEventResponseModel
  @Output() showDetail = new EventEmitter();

  constructor(
    private _eventService: EventService
  ) { }

  ngOnInit(): void {
  }

  openDetailEvent(){
    this.showDetail.emit(this.event);
  }


}
