import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { NgWaveformComponent } from 'ng-waveform';
import { AccountResponseWithRoleInCLubModel, MediaPostResponseModel } from 'src/app/core/api/be/models';
import { AccountService } from 'src/app/core/api/be/services';

@Component({
  selector: 'app-layout-media-post',
  templateUrl: './layout-media-post.component.html',
  styleUrls: ['./layout-media-post.component.scss'],
})
export class LayoutMediaPostComponent implements OnInit {
  @ViewChild('waveform', { static: false }) waveform!: NgWaveformComponent;

  countMedia: number = 0;

  private _post: any;
  @Input() set post(value: any) {
    if (value) {
      this.countMedia = value?.mediaPosts.length;
      this._post = value;
      this.checkType(value?.mediaPosts);
    }
  }
  get post() {
    return this._post;
  }

  @Input() isJoined!: boolean;
  @Input() isAdmin!: boolean;
  @Input() listMember: AccountResponseWithRoleInCLubModel[] = [];

  listIsLandscape: boolean[] = [];
  typeLayout!: any[];

  showViewMediaPost: boolean = false;
  indexAsset: number = 0;

  // For audio files
  isPlayingAudio: boolean = false;
  timeOffAudio!: number;

  constructor(
    private sanitizer: DomSanitizer,
    public _accountService: AccountService
  ) { }

  ngOnInit(): void { }

  onLoad(event: Event, i: number) {
    const smallImage = event?.target as HTMLImageElement;
    let imageWidth = Number(smallImage.naturalWidth);
    let imageHeight = Number(smallImage.naturalHeight);
    this.listIsLandscape[i] = (imageWidth - 200) / imageHeight >= 1;
    this.post.mediaPosts[i]['isLandscape'] = this.listIsLandscape[i];
  }

  openMedia(index: number) {
    this.showViewMediaPost = true;
    this.indexAsset = index;
  }

  checkTypeMedia(asset: MediaPostResponseModel): string {
    if (!asset.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        if (asset?.url!.includes('agora/recording')) {
          return 'RECORD';
        } else {
          return 'VIDEO';
        }
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return 'AUDIO';
        }
        return 'IMAGE';
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    if (asset?.url!.split('?')[1].startsWith('a')) {
      return 'AUDIO';
    }

    return 'UNKNOWN';
  }

  getUrl(asset: MediaPostResponseModel) {
    if (!asset?.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (asset?.url.includes('agora/recording')) {
        return asset?.url!;
      }

      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return asset?.url!;
        }
      }
    } else {
      if (asset?.url!.split('?')[1].startsWith('i')) {
        if (this.countMedia == 1) {
          return asset?.url!;
        } else {
          return asset?.url!.replace('/file.', '/thumb.');
        }
      }

      if (asset?.url!.split('?')[1].startsWith('v')) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      }
    }
    return asset?.url!;
  }

  checkType(medias: MediaPostResponseModel[]) {
    const check: any[] = [];
    medias.forEach((element) => {
      check.push(this.checkTypeMedia(element));
    });
    this.typeLayout = _.union(check);
  }

  checkFail(asset: MediaPostResponseModel) {
    if (!asset.isProcessed) {
      if (asset.url?.includes('?')) {
        return true;
      } else {
        return false;
      }
    } else {
      if (asset.errorCode !== 'OK') {
        return true;
      }
      return false;
    }
  }
}
