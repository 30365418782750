import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { PostResponseModel } from 'src/app/core/api/be/models';
import { PostService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { LoadingService } from 'src/app/core/services/loading.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

import {
  EEvent,
  EventService,
  IEvent,
} from 'src/app/core/services/event.service';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
  selector: 'app-card-post-discussion',
  templateUrl: './card-post-discussion.component.html',
  styleUrls: ['./card-post-discussion.component.scss'],
})
export class CardPostDiscussionComponent implements OnInit {
  @Input() post!: PostResponseModel;
  @Input() postSelected!: PostResponseModel;

  defaultAvatar = defaultAvatar;
  roleOfPostAuthor!: string;

  duration!: moment.Duration;
  countDown!: NodeJS.Timeout;
  timeEnd = '';

  items!: MenuItem[];
  @Output() deletePostEvent = new EventEmitter<string>();

  constructor(
    private _postService: PostService,
    private eventService: EventService,
    private _translateService: TranslateService
  ) {
    this.eventService
      .receive()
      .pipe()
      .subscribe((data: IEvent) => {
        console.log(data);

        if (data.action == EEvent.CHANGE_TOTALCOMMENTS && this.post.id! == data.payload.id) {
          this.post.totalComment!--;
        }
      });
  }

  ngOnInit(): void {
    this.roleOfPostAuthor =
      this.post.club?.roleOfPostAuthor! == 'ROLE_ADMIN' &&
        this.post.author?.id! === this.post.club?.authorId!
        ? 'ROLE_CREATOR'
        : this.post.club?.roleOfPostAuthor! == 'ROLE_ADMIN' &&
          this.post.author?.id! !== this.post.club?.author?.id!
          ? 'ROLE_ADMIN'
          : '';

    if (this.post.isDiscussing) {
      this.timeRemain();
    } else {
      let currentLang =
        LocalStorageService.GetLocalStorage('language').toLowerCase();
      this.timeEnd = moment
        .utc(this.post.dateEnd)
        .locale(currentLang)
        .format('ll');
    }

    this.initMenu();
  }

  initMenu() {
    this.items = [
      {
        icon: 'pi pi-calendar-times',
        label: this._translateService.instant('Clôturer la discussion'),
        command: () => {
          this.endDiscussion();
        },
      },
      {
        id: 'delete',
        icon: 'pi pi-trash',
        label: this._translateService.instant('Supprimer la discussion'),
        command: () => {
          this.deletePost();
        },
      },
    ];
  }

  timeAgo(date: string) {
    let currentLang =
      LocalStorageService.GetLocalStorage('language').toLowerCase();
    return moment
      .utc(date)
      .locale(currentLang ? currentLang : 'fr')
      .fromNow();
  }

  getNumberWithZero(num: number) {
    return num >= 10 ? num.toString() : '0' + num;
  }

  timeRemain() {
    const eventTime: number = moment.utc(this.post.dateEnd).unix();
    const currentTime: number = moment.utc().unix();
    var diffTime = eventTime - currentTime;
    this.duration = moment.duration(diffTime * 1000, 'milliseconds');
    var interval = 1000;
    this.countDown = setInterval(() => {
      this.duration = moment.duration(
        this.duration.asMilliseconds() - interval,
        'milliseconds'
      );
      this.handleCountDownDone();
    }, 1000);
  }

  private handleCountDownDone() {
    if (
      this.duration.hours() <= 0 &&
      this.duration.minutes() <= 0 &&
      this.duration.seconds() <= 0 &&
      this.duration.milliseconds() <= 0
    ) {
      this.endDiscussion();
    }
  }

  endDiscussion() {
    LoadingService.$(true);
    this._postService
      .inlivewithPostEndPostDiscusionPostIdPut$Json({ postId: this.post.id! })
      .subscribe((res) => {
        LoadingService.$(false);
        this.post.isDiscussing = false;
        // this.eventService.push({ action: EEvent.END_POST, payload: this.post });
        // this.ngOnInit();
        window.location.reload();
      });
  }

  deletePost() {
    this.deletePostEvent.emit(this.post.id!);
  }
}
