/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountResponseInPostPaginationModelResponseModel } from '../models/account-response-in-post-pagination-model-response-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { CommentPostResponseModelPaginationModelResponseModel } from '../models/comment-post-response-model-pagination-model-response-model';
import { CreatePostResponseModelResponseModel } from '../models/create-post-response-model-response-model';
import { MediaCreateResponseModelListResponseModel } from '../models/media-create-response-model-list-response-model';
import { MediaPostDeleteModel } from '../models/media-post-delete-model';
import { MediaPostResponseModelListResponseModel } from '../models/media-post-response-model-list-response-model';
import { PostAdminResponseModelPaginationModelResponseModel } from '../models/post-admin-response-model-pagination-model-response-model';
import { PostAdminResponseModelResponseModel } from '../models/post-admin-response-model-response-model';
import { PostClubResponseModelPaginationModelResponseModel } from '../models/post-club-response-model-pagination-model-response-model';
import { PostInputCreateModel } from '../models/post-input-create-model';
import { PostInputEditContentModel } from '../models/post-input-edit-content-model';
import { PostResponseModelResponseModel } from '../models/post-response-model-response-model';
import { ProcessFileModel } from '../models/process-file-model';
import { ReactionPostResponseModelPaginationModelResponseModel } from '../models/reaction-post-response-model-pagination-model-response-model';

@Injectable({
  providedIn: 'root',
})
export class PostService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithPostCreatePost
   */
  static readonly InlivewithPostCreatePostPath = '/inlivewith/Post/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCreatePost$Plain$Response(params?: {
    body?: PostInputCreateModel
  }): Observable<StrictHttpResponse<CreatePostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatePostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCreatePost$Plain(params?: {
    body?: PostInputCreateModel
  }): Observable<CreatePostResponseModelResponseModel> {

    return this.inlivewithPostCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CreatePostResponseModelResponseModel>) => r.body as CreatePostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCreatePost$Json$Response(params?: {
    body?: PostInputCreateModel
  }): Observable<StrictHttpResponse<CreatePostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatePostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCreatePost$Json(params?: {
    body?: PostInputCreateModel
  }): Observable<CreatePostResponseModelResponseModel> {

    return this.inlivewithPostCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CreatePostResponseModelResponseModel>) => r.body as CreatePostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostCreateMediaPostPost
   */
  static readonly InlivewithPostCreateMediaPostPostPath = '/inlivewith/Post/CreateMediaPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCreateMediaPostPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCreateMediaPostPost$Plain$Response(params?: {
    postId?: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<MediaCreateResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCreateMediaPostPostPath, 'post');
    if (params) {
      rb.query('postId', params.postId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaCreateResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCreateMediaPostPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCreateMediaPostPost$Plain(params?: {
    postId?: string;
    body?: Array<string>
  }): Observable<MediaCreateResponseModelListResponseModel> {

    return this.inlivewithPostCreateMediaPostPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MediaCreateResponseModelListResponseModel>) => r.body as MediaCreateResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostCreateMediaPostPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCreateMediaPostPost$Json$Response(params?: {
    postId?: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<MediaCreateResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostCreateMediaPostPostPath, 'post');
    if (params) {
      rb.query('postId', params.postId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaCreateResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostCreateMediaPostPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostCreateMediaPostPost$Json(params?: {
    postId?: string;
    body?: Array<string>
  }): Observable<MediaCreateResponseModelListResponseModel> {

    return this.inlivewithPostCreateMediaPostPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MediaCreateResponseModelListResponseModel>) => r.body as MediaCreateResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostEditPostContentPut
   */
  static readonly InlivewithPostEditPostContentPutPath = '/inlivewith/Post/EditPostContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostEditPostContentPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostEditPostContentPut$Plain$Response(params?: {
    body?: PostInputEditContentModel
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostEditPostContentPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostEditPostContentPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostEditPostContentPut$Plain(params?: {
    body?: PostInputEditContentModel
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostEditPostContentPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostEditPostContentPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostEditPostContentPut$Json$Response(params?: {
    body?: PostInputEditContentModel
  }): Observable<StrictHttpResponse<PostResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostEditPostContentPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostEditPostContentPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostEditPostContentPut$Json(params?: {
    body?: PostInputEditContentModel
  }): Observable<PostResponseModelResponseModel> {

    return this.inlivewithPostEditPostContentPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelResponseModel>) => r.body as PostResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostMediaPostAddMediaPostPut
   */
  static readonly InlivewithPostMediaPostAddMediaPostPutPath = '/inlivewith/Post/MediaPost/AddMediaPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostMediaPostAddMediaPostPut$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostMediaPostAddMediaPostPut$Plain$Response(params?: {
    body?: {
'PostId'?: string;
'MediaPostFiles'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<MediaPostResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostMediaPostAddMediaPostPutPath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaPostResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostMediaPostAddMediaPostPut$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostMediaPostAddMediaPostPut$Plain(params?: {
    body?: {
'PostId'?: string;
'MediaPostFiles'?: Array<Blob>;
}
  }): Observable<MediaPostResponseModelListResponseModel> {

    return this.inlivewithPostMediaPostAddMediaPostPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MediaPostResponseModelListResponseModel>) => r.body as MediaPostResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostMediaPostAddMediaPostPut$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostMediaPostAddMediaPostPut$Json$Response(params?: {
    body?: {
'PostId'?: string;
'MediaPostFiles'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<MediaPostResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostMediaPostAddMediaPostPutPath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaPostResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostMediaPostAddMediaPostPut$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithPostMediaPostAddMediaPostPut$Json(params?: {
    body?: {
'PostId'?: string;
'MediaPostFiles'?: Array<Blob>;
}
  }): Observable<MediaPostResponseModelListResponseModel> {

    return this.inlivewithPostMediaPostAddMediaPostPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MediaPostResponseModelListResponseModel>) => r.body as MediaPostResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostProcessMediaMediaPostIdPut
   */
  static readonly InlivewithPostProcessMediaMediaPostIdPutPath = '/inlivewith/Post/ProcessMedia/{mediaPostId}';

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostProcessMediaMediaPostIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPut$Plain$Response(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostProcessMediaMediaPostIdPutPath, 'put');
    if (params) {
      rb.path('mediaPostId', params.mediaPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostProcessMediaMediaPostIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPut$Plain(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostProcessMediaMediaPostIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostProcessMediaMediaPostIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPut$Json$Response(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostProcessMediaMediaPostIdPutPath, 'put');
    if (params) {
      rb.path('mediaPostId', params.mediaPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostProcessMediaMediaPostIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPut$Json(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostProcessMediaMediaPostIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostProcessMediaMediaPostIdPost
   */
  static readonly InlivewithPostProcessMediaMediaPostIdPostPath = '/inlivewith/Post/ProcessMedia/{mediaPostId}';

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostProcessMediaMediaPostIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPost$Plain$Response(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostProcessMediaMediaPostIdPostPath, 'post');
    if (params) {
      rb.path('mediaPostId', params.mediaPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostProcessMediaMediaPostIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPost$Plain(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostProcessMediaMediaPostIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostProcessMediaMediaPostIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPost$Json$Response(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostProcessMediaMediaPostIdPostPath, 'post');
    if (params) {
      rb.path('mediaPostId', params.mediaPostId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostProcessMediaMediaPostIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostProcessMediaMediaPostIdPost$Json(params: {
    mediaPostId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostProcessMediaMediaPostIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetByClubClubIdAccountRequestIdTypeGet
   */
  static readonly InlivewithPostGetByClubClubIdAccountRequestIdTypeGetPath = '/inlivewith/Post/GetByClub/{clubId}/{accountRequestId}/{type}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetByClubClubIdAccountRequestIdTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByClubClubIdAccountRequestIdTypeGet$Plain$Response(params: {
    clubId: string;
    type: string;
    page?: number;
    limit?: number;
    orderBy?: string;
    accountRequestId: string;
  }): Observable<StrictHttpResponse<PostClubResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetByClubClubIdAccountRequestIdTypeGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('type', params.type, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('orderBy', params.orderBy, {});
      rb.path('accountRequestId', params.accountRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostClubResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetByClubClubIdAccountRequestIdTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByClubClubIdAccountRequestIdTypeGet$Plain(params: {
    clubId: string;
    type: string;
    page?: number;
    limit?: number;
    orderBy?: string;
    accountRequestId: string;
  }): Observable<PostClubResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetByClubClubIdAccountRequestIdTypeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostClubResponseModelPaginationModelResponseModel>) => r.body as PostClubResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetByClubClubIdAccountRequestIdTypeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByClubClubIdAccountRequestIdTypeGet$Json$Response(params: {
    clubId: string;
    type: string;
    page?: number;
    limit?: number;
    orderBy?: string;
    accountRequestId: string;
  }): Observable<StrictHttpResponse<PostClubResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetByClubClubIdAccountRequestIdTypeGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('type', params.type, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('orderBy', params.orderBy, {});
      rb.path('accountRequestId', params.accountRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostClubResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetByClubClubIdAccountRequestIdTypeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetByClubClubIdAccountRequestIdTypeGet$Json(params: {
    clubId: string;
    type: string;
    page?: number;
    limit?: number;
    orderBy?: string;
    accountRequestId: string;
  }): Observable<PostClubResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetByClubClubIdAccountRequestIdTypeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostClubResponseModelPaginationModelResponseModel>) => r.body as PostClubResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetCommentsOnPostPostIdGet
   */
  static readonly InlivewithPostGetCommentsOnPostPostIdGetPath = '/inlivewith/Post/GetCommentsOnPost/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCommentsOnPostPostIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsOnPostPostIdGet$Plain$Response(params: {
    stageId?: string;
    postId: string;
    isDiscusstion?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCommentsOnPostPostIdGetPath, 'get');
    if (params) {
      rb.query('stageId', params.stageId, {});
      rb.path('postId', params.postId, {});
      rb.query('isDiscusstion', params.isDiscusstion, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCommentsOnPostPostIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsOnPostPostIdGet$Plain(params: {
    stageId?: string;
    postId: string;
    isDiscusstion?: boolean;
    page?: number;
    limit?: number;
  }): Observable<CommentPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetCommentsOnPostPostIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>) => r.body as CommentPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCommentsOnPostPostIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsOnPostPostIdGet$Json$Response(params: {
    stageId?: string;
    postId: string;
    isDiscusstion?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCommentsOnPostPostIdGetPath, 'get');
    if (params) {
      rb.query('stageId', params.stageId, {});
      rb.path('postId', params.postId, {});
      rb.query('isDiscusstion', params.isDiscusstion, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCommentsOnPostPostIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsOnPostPostIdGet$Json(params: {
    stageId?: string;
    postId: string;
    isDiscusstion?: boolean;
    page?: number;
    limit?: number;
  }): Observable<CommentPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetCommentsOnPostPostIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>) => r.body as CommentPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetCommentsUserInClubAccountIdClubIdGet
   */
  static readonly InlivewithPostGetCommentsUserInClubAccountIdClubIdGetPath = '/inlivewith/Post/GetCommentsUserInClub/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCommentsUserInClubAccountIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsUserInClubAccountIdClubIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCommentsUserInClubAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCommentsUserInClubAccountIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsUserInClubAccountIdClubIdGet$Plain(params: {
    accountId: string;
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<CommentPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetCommentsUserInClubAccountIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>) => r.body as CommentPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetCommentsUserInClubAccountIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsUserInClubAccountIdClubIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetCommentsUserInClubAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetCommentsUserInClubAccountIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetCommentsUserInClubAccountIdClubIdGet$Json(params: {
    accountId: string;
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<CommentPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetCommentsUserInClubAccountIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentPostResponseModelPaginationModelResponseModel>) => r.body as CommentPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostDeletePostDelete
   */
  static readonly InlivewithPostDeletePostDeletePath = '/inlivewith/Post/DeletePost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostDeletePostDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeletePostDelete$Plain$Response(params?: {
    postId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostDeletePostDeletePath, 'delete');
    if (params) {
      rb.query('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostDeletePostDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeletePostDelete$Plain(params?: {
    postId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostDeletePostDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostDeletePostDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeletePostDelete$Json$Response(params?: {
    postId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostDeletePostDeletePath, 'delete');
    if (params) {
      rb.query('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostDeletePostDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeletePostDelete$Json(params?: {
    postId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostDeletePostDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetReactOnPostPostIdGet
   */
  static readonly InlivewithPostGetReactOnPostPostIdGetPath = '/inlivewith/Post/GetReactOnPost/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetReactOnPostPostIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnPostPostIdGet$Plain$Response(params: {
    postId: string;
    stageId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactionPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetReactOnPostPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('stageId', params.stageId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactionPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetReactOnPostPostIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnPostPostIdGet$Plain(params: {
    postId: string;
    stageId?: string;
    page?: number;
    limit?: number;
  }): Observable<ReactionPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetReactOnPostPostIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReactionPostResponseModelPaginationModelResponseModel>) => r.body as ReactionPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetReactOnPostPostIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnPostPostIdGet$Json$Response(params: {
    postId: string;
    stageId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactionPostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetReactOnPostPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('stageId', params.stageId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactionPostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetReactOnPostPostIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetReactOnPostPostIdGet$Json(params: {
    postId: string;
    stageId?: string;
    page?: number;
    limit?: number;
  }): Observable<ReactionPostResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetReactOnPostPostIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReactionPostResponseModelPaginationModelResponseModel>) => r.body as ReactionPostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostEndPostDiscusionPostIdPut
   */
  static readonly InlivewithPostEndPostDiscusionPostIdPutPath = '/inlivewith/Post/EndPostDiscusion/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostEndPostDiscusionPostIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostEndPostDiscusionPostIdPut$Plain$Response(params: {
    postId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostEndPostDiscusionPostIdPutPath, 'put');
    if (params) {
      rb.path('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostEndPostDiscusionPostIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostEndPostDiscusionPostIdPut$Plain(params: {
    postId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostEndPostDiscusionPostIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostEndPostDiscusionPostIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostEndPostDiscusionPostIdPut$Json$Response(params: {
    postId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostEndPostDiscusionPostIdPutPath, 'put');
    if (params) {
      rb.path('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostEndPostDiscusionPostIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostEndPostDiscusionPostIdPut$Json(params: {
    postId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostEndPostDiscusionPostIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostDeleteCommentDelete
   */
  static readonly InlivewithPostDeleteCommentDeletePath = '/inlivewith/Post/DeleteComment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostDeleteCommentDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeleteCommentDelete$Plain$Response(params?: {
    commentPostId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostDeleteCommentDeletePath, 'delete');
    if (params) {
      rb.query('commentPostId', params.commentPostId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostDeleteCommentDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeleteCommentDelete$Plain(params?: {
    commentPostId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostDeleteCommentDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostDeleteCommentDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeleteCommentDelete$Json$Response(params?: {
    commentPostId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostDeleteCommentDeletePath, 'delete');
    if (params) {
      rb.query('commentPostId', params.commentPostId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostDeleteCommentDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeleteCommentDelete$Json(params?: {
    commentPostId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostDeleteCommentDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostMediaPostDeleteMediaPostDelete
   */
  static readonly InlivewithPostMediaPostDeleteMediaPostDeletePath = '/inlivewith/Post/MediaPost/DeleteMediaPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostMediaPostDeleteMediaPostDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostDeleteMediaPostDelete$Plain$Response(params?: {
    body?: MediaPostDeleteModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostMediaPostDeleteMediaPostDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostMediaPostDeleteMediaPostDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostDeleteMediaPostDelete$Plain(params?: {
    body?: MediaPostDeleteModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostMediaPostDeleteMediaPostDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostMediaPostDeleteMediaPostDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostDeleteMediaPostDelete$Json$Response(params?: {
    body?: MediaPostDeleteModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostMediaPostDeleteMediaPostDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostMediaPostDeleteMediaPostDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithPostMediaPostDeleteMediaPostDelete$Json(params?: {
    body?: MediaPostDeleteModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostMediaPostDeleteMediaPostDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetAdminPostGet
   */
  static readonly InlivewithPostGetAdminPostGetPath = '/inlivewith/Post/GetAdminPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetAdminPostGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetAdminPostGet$Plain$Response(params?: {
    isDelete?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostAdminResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetAdminPostGetPath, 'get');
    if (params) {
      rb.query('isDelete', params.isDelete, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostAdminResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetAdminPostGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetAdminPostGet$Plain(params?: {
    isDelete?: boolean;
    page?: number;
    limit?: number;
  }): Observable<PostAdminResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetAdminPostGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostAdminResponseModelPaginationModelResponseModel>) => r.body as PostAdminResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetAdminPostGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetAdminPostGet$Json$Response(params?: {
    isDelete?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostAdminResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetAdminPostGetPath, 'get');
    if (params) {
      rb.query('isDelete', params.isDelete, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostAdminResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetAdminPostGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetAdminPostGet$Json(params?: {
    isDelete?: boolean;
    page?: number;
    limit?: number;
  }): Observable<PostAdminResponseModelPaginationModelResponseModel> {

    return this.inlivewithPostGetAdminPostGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostAdminResponseModelPaginationModelResponseModel>) => r.body as PostAdminResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetAdminPostByIdGet
   */
  static readonly InlivewithPostGetAdminPostByIdGetPath = '/inlivewith/Post/GetAdminPostById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetAdminPostByIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetAdminPostByIdGet$Plain$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<PostAdminResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetAdminPostByIdGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostAdminResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetAdminPostByIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetAdminPostByIdGet$Plain(params?: {
    id?: string;
  }): Observable<PostAdminResponseModelResponseModel> {

    return this.inlivewithPostGetAdminPostByIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostAdminResponseModelResponseModel>) => r.body as PostAdminResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetAdminPostByIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetAdminPostByIdGet$Json$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<PostAdminResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetAdminPostByIdGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostAdminResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetAdminPostByIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetAdminPostByIdGet$Json(params?: {
    id?: string;
  }): Observable<PostAdminResponseModelResponseModel> {

    return this.inlivewithPostGetAdminPostByIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostAdminResponseModelResponseModel>) => r.body as PostAdminResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostDeleteAllCommentInPostDelete
   */
  static readonly InlivewithPostDeleteAllCommentInPostDeletePath = '/inlivewith/Post/DeleteAllCommentInPost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostDeleteAllCommentInPostDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeleteAllCommentInPostDelete$Plain$Response(params?: {
    postId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostDeleteAllCommentInPostDeletePath, 'delete');
    if (params) {
      rb.query('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostDeleteAllCommentInPostDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeleteAllCommentInPostDelete$Plain(params?: {
    postId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostDeleteAllCommentInPostDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostDeleteAllCommentInPostDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeleteAllCommentInPostDelete$Json$Response(params?: {
    postId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostDeleteAllCommentInPostDeletePath, 'delete');
    if (params) {
      rb.query('postId', params.postId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostDeleteAllCommentInPostDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostDeleteAllCommentInPostDelete$Json(params?: {
    postId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithPostDeleteAllCommentInPostDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithPostGetAuthorCommentPostDiscussionPostIdGet
   */
  static readonly InlivewithPostGetAuthorCommentPostDiscussionPostIdGetPath = '/inlivewith/Post/GetAuthorCommentPostDiscussion/{postId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Plain$Response(params: {
    postId: string;
    stageId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseInPostPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetAuthorCommentPostDiscussionPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('stageId', params.stageId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseInPostPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Plain(params: {
    postId: string;
    stageId?: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseInPostPaginationModelResponseModel> {

    return this.inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseInPostPaginationModelResponseModel>) => r.body as AccountResponseInPostPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Json$Response(params: {
    postId: string;
    stageId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseInPostPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PostService.InlivewithPostGetAuthorCommentPostDiscussionPostIdGetPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('stageId', params.stageId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseInPostPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Json(params: {
    postId: string;
    stageId?: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseInPostPaginationModelResponseModel> {

    return this.inlivewithPostGetAuthorCommentPostDiscussionPostIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseInPostPaginationModelResponseModel>) => r.body as AccountResponseInPostPaginationModelResponseModel)
    );
  }

}
