import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { AccountResponseGeneral, AccountResponseWithRoleInCLubModel, CommentPostReportResponseModel, MediaPostResponseModel, ReportModelAllWithDetail, ReportResponseModel } from 'src/app/core/api/be/models';
import { BackOfficeService } from 'src/app/core/api/be/services';
import { TYPE_STATUS_REPORT, defaultAvatar } from 'src/app/core/constant';
import { LimitStringPipe } from 'src/app/core/pipe/limit-string.pipe';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-report-account',
  templateUrl: './report-account.component.html',
  styleUrls: ['./report-account.component.scss']
})
export class ReportAccountComponent implements OnInit {

  @Input() account!: AccountResponseGeneral

  listReports!: ReportModelAllWithDetail[];
  listMember: AccountResponseWithRoleInCLubModel[] = [];

  defaultAvatar = defaultAvatar

  completeText: boolean = false;
  totalReports: number = 0

  constructor(
    private _backOfficeService: BackOfficeService
  ) { }

  ngOnInit(): void {
    this.getListReports();
  }

  getListReports() {
    this._backOfficeService.inlivewithBackOfficeGetAllReportOfAccountIdIdentityUserIdPost$Json({
      identityUserId: this.account.identityUserId!,
      body: [TYPE_STATUS_REPORT.STATUS_ACCEPTED, TYPE_STATUS_REPORT.STATUS_WARN, TYPE_STATUS_REPORT.STATUS_IGNORE],
      limit: 1000
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.listReports = res.data?.page!
          this.listReports.forEach(x => this.totalReports += x.numberReports!)
        }
      })
    ).subscribe();
  }

  timeAgo = (date: string) => {
    let currentLang =
      LocalStorageService.GetLocalStorage('language').toLowerCase();
    let text = moment
      .utc(date)
      .locale(currentLang ? currentLang : 'fr')
      .fromNow();

    text = text.includes('il y a ') ? text.replace('il y a ', '').replace('moi', 'mois').replace('une', '1').replace('jour', 'j').replace('minute', 'm')
      .replace('heure', 'h').replace('s', '').replace('quelquesecondes', '1s').replace('un', '1') : text;

    return text;
  };

  getRole(comment: CommentPostReportResponseModel) {
    if (comment.authorId === comment.clubAuthorId) {
      return 'CREATER'
    } else return 'ADMIN';
  }

  urlify(text: any) {
    var translatePipe = new LimitStringPipe();

    let mentionRegex = /\B@\w+/g;

    text = text?.replace(mentionRegex, (username: any) => {
      return this.detachMention(username);
    });

    let hashtagRegex = /\B#\w+/g;

    text = text?.replace(hashtagRegex, (hashtag: any) => {
      return `<a href="research?searchText=${hashtag.replace(
        '#',
        ''
      )}" target="_blank">${hashtag}</a>`;
    });

    var urlRegex =
      /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    return text?.replace(urlRegex, (url: any) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  }

  detachMention(username: string) {
    let uname = username!.replace('@', '');
    let user = this.listMember?.filter(
      (user) => user!.nickName?.replaceAll(' ', '_') == uname
    )[0];

    if (user) {
      return `<a href="account-info?accountId=${user.id}" target="_blank">${username}</a>`;
    } else {
      return `<a href="research?searchText=${username
        .replace('@', '')
        .replaceAll('_', ' ')}" target="_blank">${username}</a>`;
    }
  }

  checkTypeMedia(asset: MediaPostResponseModel): string {

    if (!asset.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return 'VIDEO';
      } else {
        if (
          asset?.url!.endsWith('.aac') ||
          asset?.url!.endsWith('.wav')
        ) {
          return 'AUDIO';
        }
        return 'IMAGE'
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    if (asset?.url!.split('?')[1].startsWith('a')) {
      return 'AUDIO';
    }

    return 'UNKNOWN';
  }

  getUrl(asset: MediaPostResponseModel) {
    if (!asset?.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return asset?.url!;
        }
      }
    } else {
      if (asset?.url!.split('?')[1].startsWith('i')) {
        return asset?.url!.replace('/file.', '/thumb.')
      }

      if (asset?.url!.split('?')[1].startsWith('v')) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      }

    }
    return asset?.url!;
  }


}
