<div class="mt-5 mb-5">
  {{totalItems}} <span> {{'RESULTS' | translate}}</span>
</div>

<p-table [value]="listClub" [paginator]="true" [lazy]="true" [rows]="10" [totalRecords]="totalItems"
  (onLazyLoad)="paginate($event)" [rowSelectable]="isRowSelectable" (onRowSelect)="onRowSelect($event)"
  (onRowUnselect)="onRowUnselect($event)" [tableStyle]="{'width': '100%'}" styleClass="p-datatable-sm"
  [rowHover]="true">
  <ng-template pTemplate="header">
    <tr>
      <th style="min-width:200px">{{'LIST_CLUB_NAME' | translate}}</th>
      <th style="min-width:150px;">{{'LIST_CLUB_TYPE' | translate}}</th>
      <th style="min-width:150px">{{'LIST_CLUB_CATEGORY' | translate}}</th>
      <th style="min-width:150px">{{'LIST_CLUB_LINKED' | translate}}</th>
      <th style="min-width:150px">{{'LIST_CLUB_AUTHOR' | translate}}</th>
      <th style="min-width:150px">{{'CLUB_MEMBERS' | translate}}</th>
      <th style="min-width:150px">{{'CLUB_BADGES' | translate}}</th>
      <th style="min-width:150px">{{'LIST_CLUB_SIGNALS' | translate}}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-club>
    <tr>
      <td>
        <div class="flex align-items-center cursor-pointer" (click)="gotoDetail(club.id!)">
          <div class="zone-avatar mr-1">
            <img style="border-radius: 10px;" class="fit-size" [src]="club.avatar">
          </div>
          <span style="text-overflow: ellipsis;
          /* white-space: break-spaces; */
          overflow: hidden;" class="fs-14">{{club.name}}</span>
        </div>
      </td>
      <td>{{getType(club.type)}}</td>
      <td>
        <div class="flex align-items-center">
          <img class="mr-2" [src]="getCategoryImage(club.category)">
          <span style="text-overflow: ellipsis;
          /* white-space: break-spaces; */
          overflow: hidden;" class="fs-14">{{club.category | translate}}</span>
        </div>
      </td>
      <td>{{club.celebrityAliases}}</td>
      <td style="text-overflow: ellipsis;
      /* white-space: break-spaces; */
      overflow: hidden;">{{club.author.nickName}}</td>
      <td>{{club.totalMember}}</td>
      <td>{{club.badges}}</td>
      <td>{{club.signal}}</td>
    </tr>
  </ng-template>
</p-table>
