<div *ngIf="listClubs">
  <div class="container">
    <div class="col-12 flex flex-column">
      <div class="header">
        <div class="flex justify-content-between mt-5">
          <span class="fs-14">{{totalRecords}} {{'RESULTS' | translate}}</span>
          <div class="flex align-items-center">
            <button pButton type="button" label="{{'FILTERS' | translate}}" (click)="sidebarVisible = true"
              class="p-button-outlined p-button-secondary mr-2"></button>
          </div>
        </div>
      </div>
      <div class="content-table">
        <p-table [value]="listClubs" [paginator]="true" [lazy]="true" [rows]="10" (onLazyLoad)="paginate($event)"
          [totalRecords]="totalRecords" [rowSelectable]="isRowSelectable"
          (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" [tableStyle]="{'width': '100%'}"
          styleClass="p-datatable-sm" [rowHover]="true">
          <ng-template pTemplate="header">
            <tr>
              <th style="width:20rem">{{'LIST_CLUB_NAME' | translate}}</th>
              <th style="min-width:150px;">{{'LIST_CLUB_TYPE' | translate}}</th>
              <th style="min-width:150px">{{'LIST_CLUB_CATEGORY' | translate}}</th>
              <th style="min-width:150px">{{'LIST_CLUB_LINKED' | translate}}</th>
              <th style="min-width:150px">{{'LIST_CLUB_AUTHOR' | translate}}</th>
              <th style="min-width:150px">{{'CLUB_MEMBERS' | translate}}</th>
              <th style="min-width:150px">{{'CLUB_BADGES' | translate}}</th>
              <th style="min-width:150px">{{'LIST_CLUB_SIGNALS' | translate}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-club>
            <tr>
              <td>
                <div class="flex align-items-center cursor-pointer" (click)="gotoDetail(club)">
                  <div class="zone-avatar mr-1">
                    <img class="fit-size" [src]="club.club.avatar">
                  </div>
                  <span class="text fs-14">{{club.club.name}}</span>
                </div>
              </td>
              <td>{{getType(club.club.type)}}</td>
              <td>
                <div class="flex align-items-center">
                  <img class="mr-2" [src]="getCategoryImage(club.club.category)">
                  <span class="text fs-14">{{club.club.category | translate}}</span>
                </div>
              </td>
              <td>{{club.club.celebrityAliases}}</td>
              <td class="text">{{club.club.author.nickName}}</td>
              <td>{{club.club.totalMember}}</td>
              <td>{{club.club.badges}}</td>
              <td>{{club.club.signal}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>


<ng-container *ngIf="sidebarVisible">
  <p-sidebar [(visible)]="sidebarVisible" position="right">
    <!-- <ng-template pTemplate="header">FILTRER</ng-template> -->
    <div class="text-bold mt-2">
      FILTRER PAR
    </div>

    <div class="flex flex-column mt-4">
      <span class="text-bold mb-2 mt-2">
        {{'CATEGORIES' | translate}}
      </span>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox>
        {{'CLUB_CATEGORY_MUSIC' | translate}}
      </div>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox>
        {{'CLUB_CATEGORY_DANCE' | translate}}
      </div>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox>
        {{'CLUB_CATEGORY_HUMOR' | translate}}
      </div>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox>
        {{'CLUB_CATEGORY_MEDIA' | translate}}
      </div>
    </div>

    <div class="line mt-2 mb-2"></div>

    <div class="flex flex-column mt-2">
      <span class="text-bold mb-2">
        {{'TYPES' | translate}}
      </span>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox>
        {{'TALENT' | translate}}
      </div>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox>
        {{'FAN' | translate}}
      </div>
    </div>

    <div class="line mt-2 mb-2"></div>

    <div class="flex flex-column mt-2">
      <span class="text-bold mb-2 mt-2">
        {{'CLUB_BADGES' | translate}}
      </span>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox> Bronze</div>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox> Argent</div>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox> Or</div>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox> Platine</div>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox> Diamant</div>
    </div>
  </p-sidebar>
</ng-container>
