<div class="zone-comment-asset">
  <div class="list-comment">
    <div class="comments">
      <ng-container *ngFor="let item of listCommentsPost">
        <div class="item">
          <div class="avatar">
            <img class="fit-size cursor-pointer" [src]="item?.author?.avatar! ? item.author?.avatar! : defaultAvatar"
              alt="">
          </div>
          <div class="detail">
            <div class="flex align-items-center justify-content-between w-100">
              <span class="name">
                {{item?.author?.nickName! | limitString:false:20}}
              </span>
              <div class="zone-action-comment" *ngIf="commentPostDiscussion">
                <!-- <i class="pi pi-flag ml-2 cursor-pointer" *ngIf="item.authorId !== authId" style="color: red;"
                  (click)="reportComment(item)"></i> -->
                <i class="pi pi-trash cursor-pointer" (click)="pickCommentSelected(item)"></i>
              </div>
            </div>
            <div class="content" *ngIf="commentFromPostInliveteam else commentPostClub">
              {{item.club!.name!}}
            </div>
            <ng-template #commentPostClub>
              <span class="content">
                {{item.dateCreated | date: 'dd/MM/yyyy HH:mm'}}
              </span>
            </ng-template>
            <div class="content" [innerText]="item.message!">
              <!-- {{item!.message!}} -->
            </div>
            <div style="position: relative" *ngIf="item.mediaCommentPosts!.length! > 0">

              <!-- <div class="flex justify-content-between zone-arrow text-white" *ngIf="checkOverflow(item?.id!)">
                <i class="pi pi-arrow-left arrow cursor-pointer" (click)="scrollTo(item.id!)"></i>
                <i class="pi pi-arrow-right arrow cursor-pointer" (click)="scrollTo(item.id!, false)"></i>
              </div> -->

              <div class="w-100 flex flex-wrap justify-content-between" *ngIf="item.mediaCommentPosts!.length! > 0">
                <ng-container *ngFor="let asset of item.mediaCommentPosts">
                  <div class="flex justify-content-center align-items-center m-2"
                    *ngIf="checkTypeMedia(asset) == 'UNKNOWN'" style="height: 50px">
                    <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                  </div>
                </ng-container>
              </div>

              <div class="w-100 flex flex-wrap" *ngIf="item.mediaCommentPosts!.length! > 0">
                <ng-container *ngFor="let asset of item.mediaCommentPosts">
                  <div class="w-100 mb-2"
                    *ngIf="checkTypeMedia(asset) == 'AUDIO' && checkTypeMedia(asset) !== 'UNKNOWN'">
                    <app-wave-form-audio [assetUrl]="asset.url!"></app-wave-form-audio>
                  </div>
                </ng-container>
              </div>


              <div class="w-100 flex flex-wrap" *ngIf="item.mediaCommentPosts!.length! > 0">
                <ng-container *ngFor="let asset of item.mediaCommentPosts">
                  <div class="media" *ngIf="checkTypeMedia(asset) !== 'UNKNOWN' && checkTypeMedia(asset) !== 'AUDIO'">
                    <div class="w-100 h-100" *ngIf="(checkTypeMedia(asset) == 'IMAGE')">
                      <p-image [preview]="true" [src]="asset.url!"></p-image>
                    </div>
                    <div *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                      <app-video-layer *ngIf="asset?.url!" [layout]="'full'" [url]="asset?.url!"
                        [poster]="getUrl(asset)">
                      </app-video-layer>
                    </div>
                  </div>
                </ng-container>
              </div>

            </div>
          </div>
        </div>
        <div style="margin-left: 3.5rem;
      border-left: 8px solid #D1DEFB;" *ngIf="item && item?.inverseParent?.length! > 0">
          <div *ngFor="let itemChild of item?.inverseParent">
            <div class="item-child">
              <div class="avatar">
                <img class="fit-size cursor-pointer"
                  [src]="itemChild?.author?.avatar! ? itemChild?.author?.avatar! : defaultAvatar" alt="">
              </div>
              <div class="detail">
                <div class="name">
                  {{itemChild?.author?.nickName!}}
                </div>
                <div class="content" [innerHTML]="itemChild.message!"></div>
                <div *ngIf="itemChild?.mediaCommentPosts!.length! > 0" style="position: relative">
                  <!-- <div class="flex justify-content-between zone-arrow" *ngIf="checkOverflow(itemChild?.id!)">
                    <i class="pi pi-arrow-left arrow cursor-pointer" (click)="scrollTo(itemChild.id!)"></i>
                    <i class="pi pi-arrow-right arrow cursor-pointer" (click)="scrollTo(itemChild.id!, false)"></i>
                  </div> -->

                  <div class="flex flex-wrap list-media" *ngIf="itemChild?.mediaCommentPosts!.length! > 0"
                    [id]="itemChild.id">
                    <div class="w-100" *ngFor="let asset of itemChild?.mediaCommentPosts!">

                      <div class="flex justify-content-center align-items-center ml-5"
                        *ngIf="(checkTypeMedia(asset) == 'UNKNOWN')" style="height: 70px">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                      </div>

                      <div class="flex mr-4" *ngIf="(checkTypeMedia(asset) == 'IMAGE')" style="height: 100px">
                        <p-image [preview]="true" [src]="getUrl(asset)" alt=""></p-image>
                      </div>

                      <div class="flex mr-4" *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                        <app-video-layer *ngIf="asset?.url!" [layout]="'full'" [url]="asset?.url!"
                          [poster]="getUrl(asset)">
                        </app-video-layer>
                      </div>

                      <div class="flex w-100" *ngIf="checkTypeMedia(asset) == 'AUDIO'">
                        <app-wave-form-audio [assetUrl]="asset?.url!"></app-wave-form-audio>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-container *ngIf="showConfirmDeleteComment">
  <app-card-confirm [(isShowPopup)]="showConfirmDeleteComment" [title]="'DELETE_DISCUSSION' | translate"
    [content]="'CONFIRM_DELETE_COMMENT' | translate:{value: commentSelected.author?.nickName!}"
    [firstButtonTitle]="'CANCEL' | translate" [secondButtonTitle]="'DELETE' | translate"
    (doAction)="deleteComment()"></app-card-confirm>
</ng-container>
