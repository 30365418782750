<div class="w-100 flex align-items-start position-relative">
  <div class="post-zone cursor-pointer" *ngIf="post" [ngClass]="{'post-in-deletion': fadePost}"
    [ngClass]="{'post-selected': selectedPost && checkClicked}"
    [ngClass]="{'post-celeb': post.author?.accessLevelInClub == 'ACCESSLEVEL_CELEBRITY'}" (click)="selectedPostEvent()">
    <div class="flex flex-row justify-content-between" style="box-sizing: border-box;">
      <div class="flex align-items-center">
        <div class="zone-avatar">
          <img [src]="post.author?.avatar! ? post.author?.avatar : defaultAvatar" class="fit-size cursor-pointer">
        </div>
        <div class="flex flex-column ml-2 text-black">
          <div class="flex align-items-center">
            <span class="mr-2 name-author cursor-pointer" (click)="openAccountInfo()">{{post.author?.nickName!}} </span>
            <span class="ml-2 label-role-creator" *ngIf="getRoleName() == 'ADMIN'">
              {{'ADMIN' | translate }}
            </span>
            <span class="ml-2 label-role-celeb" *ngIf="getRoleName() == 'MEMBER'">
              {{'MEMBER' | translate }}
            </span>
            <span class="ml-2 label-role-creator" *ngIf="getRoleName() == 'CREATOR'">
              {{'CREATOR' | translate }}
            </span>
            <span class="ml-2 label-role-celeb" *ngIf="getRoleName() == 'CELEB'">
              {{'CELEBRITY' | translate }}
            </span>
            <span class="ml-2 label-role-admin-ilw" *ngIf="getRoleName() == 'INLIVETEAM'">
              {{'INLIVETEAM' | translate }}
            </span>
          </div>
          <span class="text-disabled fs-11 mt-2">{{timeAgo(post.dateCreated!)}}</span>
        </div>
      </div>
      <div>
        <span class="btn-delete-post" (click)="deletePost($event)">
          {{'DELETE' | translate}}
        </span>
      </div>
    </div>
    <div class="mb-3 mt-3">
      <span class="stage">
        {{post.stage?.name!}}
      </span>
    </div>
    <div class="mt-3 mb-2" [class.show]="show">
      <div id="content" class="show text-black overflow-wrap-breakword" style="white-space: break-spaces;"
        [innerHTML]="urlify(post!.message! | limitString:completeText)"></div>
    </div>

    <div class="text-right cursor-pointer" *ngIf="post?.message! && post?.message!.length! > 400">
      <span style="color:#3b82f6" (click)="handleText()">{{(show ? 'SHOW_LESS' : 'SEE_MORE') | translate}}</span>
    </div>

    <div class="flex justify-content-center w-100" *ngIf="post.author?.isCelebrity!">
      <app-layout-media-post [id]="post!.id!" [post]="post" [isAdmin]="true">
      </app-layout-media-post>
    </div>

    <!-- <div class="flex flex-column mt-4" *ngIf="post?.author?.isCelebrity! && postCeleb?.totalAnalyze?.totalClubs! > 0">
      <div class="fs-12" [innerHTML]="'PUBLISH_ON_CLUBS' | translate:{value: postCeleb.totalAnalyze?.totalClubs!}"></div>
      <div class="flex flex-column">
        <div class="col-12 flex align-items-center" *ngFor="let club of postCeleb.clubs">
          <div class="col-4 flex align-items-center">
            <div class="zone-avatar" style="border-radius: 10px !important;">
              <img class="fit-size" [src]="club.avatar">
            </div>
            <span class="ml-3" [ngStyle]="currentClubClicked! == club.id && selectedPost ? {'font-weight': 'bold'} : {}">
              {{club.name}}
            </span>
          </div>
          <div class="col-4 flex align-items-center">
            <div class="flex align-items-center" style="width: 72px">
              <i class="pi pi-heart-fill cursor-pointer" style="font-size: 24px; color: #D1DEFB;"></i>
              <span class="fs-12 ml-2 cursor-pointer">{{club!.totalReact!}}</span>
            </div>
            <div class="flex ml-5 cursor-pointer align-items-center" style="height: 23px;">
              <img src="../../../assets/images/svgs/comment-fill.svg">
              <span class="fs-12 ml-2">{{club!.totalComment!}}</span>
            </div>
          </div>
          <div class="col-4 flex justify-content-end align-items-center">
            <span class="mr-5" (click)="setCurrentClubClicked(club, $event)" [ngStyle]="selectedPost ? {'color': 'black'} : {'color': '#707070'}">
              {{'SEE' | translate}}
            </span>
            <span class="text-red ml-5">
              {{'DELETE' | translate}}
            </span>
          </div>
        </div>
      </div>
    </div> -->

    <div class="flex justify-content-center w-100" *ngIf="!post.author?.isCelebrity!">
      <app-layout-media-post [id]="post!.id!" [post]="post" [isAdmin]="true">
      </app-layout-media-post>
    </div>


    <div class="flex justify-content-between align-items-center mt-5 mb-3 text-black">
      <div class="flex">
        <div class="flex" style="width: 72px">
          <i class="pi pi-heart-fill cursor-pointer" style="font-size: 24px; color: #D1DEFB;"></i>
          <span class="fs-12 ml-2 cursor-pointer">{{post!.totalReact!}}</span>
        </div>
        <div class="flex ml-5 cursor-pointer align-items-center" style="height: 23px;">
          <img src="../../../assets/images/svgs/comment-fill.svg">
          <span class="fs-12 ml-2">{{post!.totalComment!}}</span>
        </div>
      </div>
      <div class="fs-12" style="color: #0029FF;" *ngIf="_router.url.startsWith('/clubs/detail-club')">
        {{'ENGAGEMENT' | translate}} {{(post?.engagement! > 0 ? (post.engagement | number:'1.2-2') : 0) + '%'}}
      </div>
    </div>
  </div>
</div>
