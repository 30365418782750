import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AccountResponseWithRoleInCLubModel, ReportModelAllWithDetail } from 'src/app/core/api/be/models';
import { BackOfficeService } from 'src/app/core/api/be/services';
import { AppEnumService } from 'src/app/core/api/be/services';
import { REPORT_TYPE, TYPE_STATUS_REPORT, defaultAvatar } from 'src/app/core/constant';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-card-handle-report',
  templateUrl: './card-handle-report.component.html',
  styleUrls: ['./card-handle-report.component.scss']
})
export class CardHandleReportComponent implements OnInit {

  @Input() report!: ReportModelAllWithDetail;
  @Input() handleType!: string;
  @Input() fromContenu = false;
  @Input() fromOtherPage = false;
  @Input() openPopup = false;
  @Input() actionType!: string;

  @Output() reportSelectedChange = new EventEmitter();
  @Output() refreshListReport = new EventEmitter();
  @Output() openPopupChange = new EventEmitter();


  listReasonReports: any[] = [];
  selectedReason!: any;

  constructor(
    private _backOfficeSerivce: BackOfficeService,
    private _appEnum: AppEnumService
  ) { }

  ngOnInit(): void {

  }

  onHide() {
    this.openPopupChange.emit(false);
  }

  getAppEnum() {
    if (this.handleType === 'INLIVER') {
      this._appEnum.inlivewithAppEnumReportAccountReasonsGet$Json({
        limit: 100
      }).pipe(
        tap(res => {
          if (res.res == 0) {
            this.listReasonReports = res.data?.page!
          }
        })
      ).subscribe();
    } else {
      this._appEnum.inlivewithAppEnumReportReasonsGet$Json({
        limit: 100
      }).pipe(
        tap(res => {
          if (res.res == 0) {
            this.listReasonReports = res.data?.page!
          }
        })
      ).subscribe();
    }
  }

  handleReport(report: ReportModelAllWithDetail) {
    LoadingService.$(true);
    let ids = report.reports?.map(x => x?.id!);

    if (this.handleType == 'CLUB') {
      this.handleReportClub(ids!);
    } else if (this.handleType == 'INLIVER') {
      this.handleReportInliver(ids!);
    } else if (this.handleType == 'CONTENT') {
      this.handleReportContent(ids!, report);
    }
  }

  handleReportClub(ids: string[]) {
    this._backOfficeSerivce.inlivewithBackOfficeHandleReportClubPut$Json$Response({
      body: {
        reportIds: ids,
        status: this.actionType,
        reportResult: this.actionType === TYPE_STATUS_REPORT.STATUS_IGNORE ? "" : this.selectedReason?.value! ?? ''
      }
    }).subscribe(() => {
      LoadingService.$(false);
      this.refreshListReport.emit()
      this.openPopup = false;
    })
  }

  handleReportInliver(ids: string[]) {
    this._backOfficeSerivce.inlivewithBackOfficeHandleReportAccountPut$Json({
      body: {
        reportIds: ids,
        status: this.actionType,
        reportResult: this.actionType === TYPE_STATUS_REPORT.STATUS_IGNORE ? "" : this.selectedReason?.value ?? ''
      }
    }).subscribe(() => {
      LoadingService.$(false);
      this.refreshListReport.emit()
      this.openPopup = false;
    })
  }

  handleReportContent(ids: string[], report: ReportModelAllWithDetail) {
    let contentId = report.commentPost?.id! || report.commentAlbumAsset?.id!;
    this._backOfficeSerivce.inlivewithBackOfficeHandleReportContentPut$Json({
      body: {
        reportIds: ids,
        postId: report.type == REPORT_TYPE.REPORT_POST ? report.post?.id! : null,
        commentPostId: contentId ?? null,
        status: this.actionType,
        reportResult: this.actionType === TYPE_STATUS_REPORT.STATUS_IGNORE ? "" : this.selectedReason?.value!
      }
    }).subscribe(() => {
      LoadingService.$(false);
      this.refreshListReport.emit()
      this.openPopup = false;
    })
  }

}
