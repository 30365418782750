import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import {
  AccountResponseGeneral,
  AccountWithRelationshipResponseModel,
  ClubResponseWithTotalMemberAndRoleModel,
} from 'src/app/core/api/be/models';
import {
  ClubService,
  FriendRelationService,
} from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-list-friend',
  templateUrl: './list-friend.component.html',
  styleUrls: ['./list-friend.component.scss'],
})
export class ListFriendComponent implements OnInit {
  @Input() accountId!: string;

  totalFriend: any;
  inlivers: AccountWithRelationshipResponseModel[] = [];
  inliverSelected!: AccountWithRelationshipResponseModel;

  currentPage: number = 1;
  defaultAvatar = defaultAvatar;
  clubCommon: ClubResponseWithTotalMemberAndRoleModel[] = [];

  constructor(
    private _router: Router,
    private _friendSerivce: FriendRelationService,
    private _activeRoute: ActivatedRoute,
    private _clubService: ClubService
  ) { }

  ngOnInit(): void {
    this.getFriendsList();
  }

  getFriendsList() {
    this._friendSerivce
      .inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Json({
        accountRelatingId: this.accountId!,
        limit: 10000,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.inlivers = res.data?.page!;
            this.totalFriend = res.data?.totalItems!;
          }
        }),
        tap(() => this.checkParams())
      )
      .subscribe();
  }

  selectInliver(inliver: AccountResponseGeneral) {
    this.inliverSelected = inliver;
    this._router.navigate([], { queryParams: { id: inliver.id } }).then(() => {
      this.getCommonClub();
    });
  }

  checkParams() {
    this._activeRoute.queryParams
      .pipe(
        tap((p: any) => {
          if (p.id) {
            this.inliverSelected = this.inlivers.filter(
              (data) => data.id == p.id
            )[0];
          } else {
            this.inliverSelected = this.inlivers[0];
          }
          this.selectInliver(this.inliverSelected);
        })
      )
      .subscribe();
  }

  getCommonClub() {
    let body = {
      accountRequestId: this.accountId!,
      accountId: this.inliverSelected.id!,
      isBothJoin: true,
      body: {
        isAdmin: true,
        isOrderByCreateDate: true,
        isSortByName: true,
        isUser: true,
        likeName: '',
      },
    };

    this._clubService
      .inlivewithClubsSearchAccountIdPost$Json(body)
      .pipe(
        tap((res) => {
          this.clubCommon = res.data?.page!;
          this.inliverSelected.totalCommondClub = res.data?.totalItems!
        })
      )
      .subscribe();
  }
}
