<div class="col-12">
  <div class="flex justify-content-between align-items-center">
    <div class="flex align-items-center">
      <i class="pi pi-chevron-left fs-20 mr-2 cursor-pointer" (click)="goBack()"
        style="border: 1px solid #A1A7C4; padding: 5px; border-radius: 10px;"></i>
      <span class="fs-20" style="font-weight: bold;">
        {{event.name}}
      </span>
      <img *ngIf="!event.isPublic" class="ml-3" src="../../../../assets/images/svgs/inliver-plus.svg" alt="" srcset="">
    </div>
    <div class="flex w-4 align-items-center justify-content-end">
      <div class="fs-14">
        <button pButton label="{{isEdit ? ('CANCEL' | translate) : 'DELETE_EVENT' | translate}}" (click)="deleteEvent()"
          class="p-button-outlined p-button-secondary mr-2" [ngClass]="isEdit ? 'white' : 'red'"></button>
        <button pButton label="{{isEdit ? ('REGISTER' | translate) : 'EDIT_EVENT' | translate}}" (click)="editEvent()"
          class="p-button-outlined blue p-button-secondary ml-2"></button>
      </div>
    </div>
  </div>
  <div class="flex">
    <div class="zone-content col-8">
      <div class="flex align-items-start w-100 p-4" *ngIf="!isEdit">
        <div class="flex flex-column w-100">
          <div class="w-100" style="height: 288px;">
            <img class="fit-size" [src]="event.poster!">
          </div>
          <div class="fs-12 mt-2">
            {{'CREATED_AT' | translate}} {{event.dateCreated | date:'dd/MM/yyyy'}} {{'BY' | translate}}
            @{{event.author!.nickName!}}
          </div>
          <div class="mt-2 flex flex-column">
            <span class="text-blue text-bold fs-14">
              {{ event.dateStart + 'Z' | date:'dd/MM/yy HH:mm' }}
            </span>
            <span class="text-blue text-bold mt-1 fs-14">
              {{ event.place!}}
            </span>
            <span class="mt-2 text-gray fs-12">
              {{event.description!}}
            </span>
          </div>
        </div>
      </div>
      <div class="w-100 p-4" *ngIf="isEdit">
        <div class="flex align-items-start w-100">
          <div class="flex flex-column w-100">
            <div class="w-100" style="height: 288px;">
              <img class="fit-size" [src]="event.poster!">
            </div>
            <div class="flex align-items-center mt-3">
              <span class="text-bold" style="width: 150px;">
                {{'DATE_AND_TIME' | translate}}
              </span>
              <div class="fs-14 ml-3" style="width: 60%">
                <input type="text" style="width: 100%;" pInputText [(ngModel)]="eventName"
                  [placeholder]="event.dateEnd" />
              </div>
            </div>
            <div class="flex align-items-center mt-3">
              <span class="text-bold" style="width: 150px;">
                {{'LOCATION' | translate}}
              </span>
              <div class="fs-14 ml-3" style="width: 60%">
                <input type="text" style="width: 100%" pInputText [(ngModel)]="eventPlaceName"
                  [placeholder]="event.place!" />
              </div>
            </div>
            <div class="flex align-items-center mt-4">
              <span class="text-bold" style="width: 150px;">
                {{'DETAIL_CLUB_PRIVACY' | translate}}
              </span>
              <div class="flex align-items-center ml-3 fs-12">
                <p-radioButton class="mr-5" name="groupname" label="{{'PRIVATE' | translate}}" [value]="false"
                  [(ngModel)]="isPublic"></p-radioButton>
                <p-radioButton class="ml-5" name="groupname" label="{{'PUBLIC' | translate}}" [value]="true"
                  [(ngModel)]="isPublic"></p-radioButton>
              </div>
            </div>
            <div class="flex flex-column mt-3">
              <span class="text-bold">
                {{'DESCRIPTION' | translate}}
              </span>
              <div class="fs-14 mt-2">
                <textarea [rows]="3" style="resize: none; width: 100%;" type="text" pInputText
                  [(ngModel)]="eventDescription" [placeholder]="event.description!"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-participant flex-column col-4 ml-3 p-4">
      <span class="text-blue text-bold">
        {{'PARTICIPANTS' | translate | titlecase }} ({{event.numberAccountJoinEvent!}})
      </span>
      <div *ngIf="event.numberAccountJoinEvent! > 0">
        <div *ngFor="let participant of event.accountJoinEvents">
          <div class="flex align-items-center justify-content-between mt-3">
            <div class="flex align-items-center">
              <div class="zone-avatar">
                <img class="fit-size" [src]="participant.avatar ?? defaultAvatar">
              </div>
              <div class="ml-3">
                <span class="text-bold text-gray">{{participant.nickName!}}</span>
              </div>
            </div>
            <div *ngIf="isEdit">
              <i class="pi pi-trash cursor-pointer"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
