<div class="flex w-100">
  <div class="w-70">
    <div class="card">
      <div class="flex justify-content-between">
        <div>
          <span class="text-bold">Profil Inliver</span>
        </div>
        <div>
          <span class="cursor-pointer text-gray" *ngIf="!account.isBlocked && !editInliverInfo" (click)="edit()">
            {{'EDIT' | translate}}
          </span>
        </div>
      </div>

      <div class="mt-4 mb-4">
        <div class="zone-avt">
          <img class="fit-size" [src]="account.avatar ?? defaultAvatar" alt="">
        </div>
      </div>

      <div class="flex w-100">
        <div class="flex flex-column w-100">
          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-30 text-bold align-items-center">Pseudo</div>
            <div class="flex mt-2 mb-2 w-70 align-items-center">
              <span>
                {{account.nickName! | limitString:false:40}}
              </span>
              <!-- <input *ngIf="editInliverInfo" class="w-100" pInputText id="username" [(ngModel)]="nickName"
                [placeholder]="account.nickName" [value]="account.nickName" /> -->
            </div>
          </div>

          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-30 text-bold align-items-center">E-mail</div>
            <div class="flex mt-2 mb-2 w-70 align-items-center">
              <span>
                {{account.email}}
              </span>
              <!-- <input *ngIf="editInliverInfo" class="w-100" pInputText id="username" disabled [value]="account.email" /> -->
            </div>
          </div>

          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-30 text-bold align-items-center">Nom</div>
            <div class="flex mt-2 mb-2 w-70 align-items-center">
              <span>
                {{account.lastName}}
              </span>
              <!-- <input *ngIf="editInliverInfo" class="w-100" pInputText id="username" [(ngModel)]="lastName"
                [placeholder]="account.lastName" [value]="account.lastName" /> -->
            </div>
          </div>

          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-30 text-bold align-items-center">Prénom</div>
            <div class="flex mt-2 mb-2 w-70 align-items-center">
              <span>
                {{account.firstName}}
              </span>
              <!-- <input *ngIf="editInliverInfo" class="w-100" pInputText id="username" [(ngModel)]="firstName"
                [placeholder]="account.firstName" [value]="account.firstName" /> -->
            </div>
          </div>

          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-30 text-bold align-items-center">Date de naissance</div>
            <div class="flex mt-2 mb-2 w-70 align-items-center">
              <span>
                {{account.birthday+'Z' | date:'dd/MM/YYYY'}}
              </span>
              <!-- <input *ngIf="editInliverInfo" class="w-100" pInputText id="username" [(ngModel)]="birthday"
                [placeholder]="account.birthday+'Z' | date:'dd/MM/yyyy'" [value]="account.birthday" /> -->
            </div>
          </div>

          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-30 text-bold align-items-center">Mot de passe</div>
            <div class="flex mt-2 mb-2 w-70 align-items-center">
              *************
              <!-- <span class="ml-5 text-resend-link cursor-pointer align-items-center">
                Envoyer un lien de réinitialisation
              </span> -->
            </div>
          </div>

          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-30 text-bold align-items-center">Numéro de téléphone</div>
            <div class="flex mt-2 mb-2 w-70 align-items-center">
              <span>
                {{account.phoneNumber}}
              </span>
              <!-- <input *ngIf="editInliverInfo" class="w-100" pInputText id="username" [(ngModel)]="phoneNumber"
                [placeholder]="account.phoneNumber" [value]="account.phoneNumber" /> -->
            </div>
          </div>
          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-30 text-bold align-items-center">Genre</div>
            <div class="flex mt-2 mb-2 w-70 align-items-center">
              <span>
                {{account.gender! | translate}}
              </span>
              <!-- <input *ngIf="editInliverInfo" class="w-100" pInputText id="username" [(ngModel)]="gender"
                [placeholder]="account.gender! | translate" [value]="account.gender!" /> -->
            </div>
          </div>

          <!-- <div class="mt-5" *ngIf="!account.isBlocked">
            <div class="text-bold text-delete-account cursor-pointer">
              Supprimer le compte
            </div>
          </div> -->

          <!-- <div class="flex w-100 justify-content-end">
            <button pButton type="button" label="{{'CANCEL' | translate}}"
              class="p-button-outlined p-button-secondary mr-2" (click)="editInliverInfo = false"></button>
            <button pButton type="button" label="{{'REGISTER' | translate}}"
              class="p-button-outlined p-button-secondary mr-2 btn-save" (click)="updateAccountInfo()"></button>
          </div> -->
        </div>

      </div>
    </div>
  </div>

  <div class="w-30">
    <div class="card-1">
      <div class="flex justify-content-center">
        <div class="hexagon-avatar">
          <div class="hexagon">
            <img class="fit-size" [src]="account.avatar ?? defaultAvatar">
          </div>
        </div>
      </div>

      <div class="flex flex-column justify-content-center align-items-center mt-3">
        <div class="text-bold">
          {{account.nickName! | limitString:false:40}}
        </div>
        <div>
          <span class="text-bold">
            {{'BECOME_INLIVER_SINCE' | translate}}
          </span>
          {{ account.dateCreated | date:'dd/MM/YYYY'}}
        </div>
      </div>

      <div class="flex flex-column" *ngIf="!account.isBlocked">
        <!-- <div class="flex mt-3 fs-14">
          <button pButton label="{{'BOOST_INLIVER' | translate}}"
            class="w-100 p-button-outlined blue mb-1 p-button-secondary mr-2"></button>

          <div class="flex justify-content-center align-items-center btn-email blue cursor-pointer">
            <i class="pi pi-envelope"></i>
          </div>

        </div> -->
        <div class="fs-14">
          <button pButton label="{{'BAN_THIS_INLIVER' | translate}}" [disabled]="isAdminBO" (click)="openPopupBanInliver = true"
            class="w-100 p-button-outlined red mt-1 p-button-secondary mr-2"></button>
        </div>
      </div>
    </div>

    <div class="card-2" *ngIf="!account.isBlocked">
      <div class="flex justify-content-between">
        <div>
          <span class="text-bold">
            {{'WARN_REPORT' | translate}}
          </span>
        </div>
      </div>

      <div class="mt-3">
        <span *ngIf="account.totalWarn == 0">
          {{'NO_WARNING' | translate}}
        </span>
        <span *ngIf="account.totalWarn != 0">
          {{account.totalWarn + ' ' + ('WARNINGS' | translate | lowercase)}}
        </span>
      </div>

      <div class="mt-3">
        <button pButton label="{{'SEND_WARNING' | translate}}" (click)="openPopupWarn = true"
          class="w-100 p-button-outlined red mt-1 p-button-secondary mr-2"></button>
      </div>
    </div>

    <div class="card-2" *ngIf="!account.isBlocked">
      <div class="flex justify-content-between">
        <div>
          <span class="text-bold">
            {{'DETAIL_CLUB_PRIVACY' | translate}}
          </span>
        </div>
        <div>
          <span class="cursor-pointer text-gray" (click)="openPopupEditPrivacy = true">
            {{'EDIT' | translate}}
          </span>
        </div>
      </div>

      <div class="mt-5">
        <span *ngIf="account.isPrivate">
          {{'INLIVER_PRIVATE' | translate}}
        </span>
        <span *ngIf="!account.isPrivate">
          {{'INLIVER_PUBLIC' | translate}}
        </span>
      </div>
    </div>

    <div class="card-2">
      <div class="flex justify-content-between">
        <div>
          <span class="text-bold">Centres d'intêret</span>
        </div>
        <div>
          <span class="cursor-pointer text-gray" (click)="openEditHashtag = true">
            {{'EDIT' | translate}}
          </span>
        </div>
      </div>

      <div class="mt-5 flex flex-wrap">
        <div class="flex text-white" *ngFor="let item of hashtag">
          <div class="flex hashtag"> {{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="openPopupBanInliver">
  <p-dialog #mydialog [(visible)]="openPopupBanInliver" [draggable]="false" [closable]="false" [resizable]="false"
    [modal]="true" (onHide)="onHide()" [header]="('BAN_INLIVER') | translate">
    <div class="flex flex-column justify-content-center" style="width: 350px">
      <div class="flex justify-content-center text-center"
        [innerHTML]="'BAN_INLIVER_SUBTITLE' | translate:{value: account.nickName!}">
      </div>

      <div class="flex justify-content-center mt-5 align-items-center">
        <button pButton type="button" label="{{'CANCEL' | translate}}" (click)="onHide()"
          class="p-button-outlined btn-white p-button-secondary ml-2 mr-2"></button>
        <button pButton type="button" label="{{'CONFIRM' | translate}}" (click)="banInliver()"
          class="p-button-outlined btn-blue p-button-secondary ml-2"></button>
      </div>
    </div>
  </p-dialog>
</ng-container>

<ng-container *ngIf="openPopupEditPrivacy">
  <p-dialog #mydialog [(visible)]="openPopupEditPrivacy" [draggable]="false" [closable]="false" [resizable]="false"
    [modal]="true" (onHide)="onHide()" [header]="('DETAIL_CLUB_PRIVACY') | translate">
    <div class="flex flex-column justify-content-center" style="width: 350px">
      <div class="flex justify-content-center align-items-center mt-3">
        <div class="flex align-items-center mr-3"><p-radioButton class="mr-1" name="privacy" [value]="false"
            [(ngModel)]="isPrivate"></p-radioButton>
          {{'INLIVER_PUBLIC' | translate}}
        </div>
        <div class="flex align-items-center ml-3"><p-radioButton class="mr-1" name="privacy" [value]="true"
            [(ngModel)]="isPrivate"></p-radioButton>
          {{'INLIVER_PRIVATE' | translate}}
        </div>
      </div>

      <div class="flex justify-content-center mt-5 align-items-center">
        <button pButton type="button" label="{{'CANCEL' | translate}}" (click)="openPopupEditPrivacy = false"
          class="p-button-outlined btn-white p-button-secondary ml-2 mr-2"></button>
        <button pButton type="button" label="{{'CONFIRM' | translate}}" (click)="editPrivacy()"
          class="p-button-outlined btn-blue p-button-secondary ml-2"></button>
      </div>
    </div>
  </p-dialog>
</ng-container>

<ng-container *ngIf="openPopupWarn">
  <p-dialog #mydialog [(visible)]="openPopupWarn" [draggable]="false" [closable]="false" [resizable]="false"
    [modal]="true" (onHide)="onHide()" (onShow)="getAppEnum()" [header]="('WARN_REPORT') | translate">
    <div class="flex flex-column justify-content-center" style="width: 350px">
      <div class="flex justify-content-center text-center" [innerHTML]="'PICK_A_REASON' | translate"></div>

      <div class="w-100 mt-3">
        <p-dropdown [options]="listReasonReports" optionLabel="value" [(ngModel)]="selectedReason" [appendTo]="mydialog"
          placeholder="{{'SELECT_REASON' | translate}}">
          <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="selectedReason">
              <div>{{ selectedReason.value | translate }}</div>
            </div>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>{{ item.value | translate }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="flex justify-content-center mt-5 align-items-center">
        <button pButton type="button" label="{{'CANCEL' | translate}}" (click)="openPopupWarn = false"
          class="p-button-outlined blue p-button-secondary ml-2 mr-2"></button>
        <button pButton type="button" label="{{'CONFIRM' | translate}}" (click)="createReportWarnInliver()"
          class="p-button-outlined btn-blue p-button-secondary ml-2"></button>
      </div>
    </div>
  </p-dialog>
</ng-container>

<ng-container *ngIf="openEditHashtag">
  <app-edit-hashtags-inliver [(openEditHashtag)]="openEditHashtag" [hashtag]="hashtag" [account]="account"></app-edit-hashtags-inliver>
</ng-container>
