import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { finalize, tap } from 'rxjs/operators';
import { ExportMemberInCelebrityDetail, MemberInCelebrityDetail } from 'src/app/core/api/be/models';
import { CelebrityService } from 'src/app/core/api/be/services';
import { base64ToBlob } from 'src/app/core/constant';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-celeb-members',
  templateUrl: './celeb-members.component.html',
  styleUrls: ['./celeb-members.component.scss']
})
export class CelebMembersComponent implements OnInit {

  @Input() celebId!: string;

  listMembers: MemberInCelebrityDetail[] = [];

  sidebarVisible = false;

  currentPage: number = 1;
  totalRecords!: number

  constructor(
    private _celebService: CelebrityService,
    private _translateService: TranslateService
  ) { }

  ngOnInit(): void { }

  getListMembersByCeleb(currentPage: number) {
    LoadingService.$(true);
    this._celebService.inlivewithCelebrityGetListMembersInCelebrityDetailCelebrityIdGet$Json({
      celebrityId: this.celebId,
      limit: 10,
      page: currentPage
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.listMembers = res.data?.page!
          this.totalRecords = res.data?.totalItems!
        }
      }),
      finalize(() => LoadingService.$(false))
    ).subscribe();
  }

  getType(type: string) {
    if (type == 'CLUB_TYPE_FAN') {
      return this._translateService.instant('FAN');
    }
    return this._translateService.instant('TALENT');
  }

  gotoDetail(club: MemberInCelebrityDetail) {

  }

  onRowSelect(event: any) {
    console.log('nudes', event.data);
    // this._router.navigate(['clubs/detail-club'], {
    //   queryParams: { clubId: event.data.id },
    // });
  }

  onRowUnselect(event: any) {
    console.log('nudes');
  }

  isRowSelectable(event: any) {
    return !this.isOutOfStock(event.data);
  }

  isOutOfStock(data: any) {
    return data.inventoryStatus === 'OUTOFSTOCK';
  }

  paginate(event: any) {
    this.currentPage = event.first / 10 + 1;
    this.getListMembersByCeleb(this.currentPage);
  }

  exportCSV() {
    let title = [
      'NAME',
      'EMAIL',
      'TOTAL PUBLICATIONS',
      'TOTAL COMMENTS',
      'TOTAL REACTS',
      'SINCE JOINED'
    ];
    let body: ExportMemberInCelebrityDetail = {
      limit: 1000000000,
      page: 1,
      celebrityId: this.celebId,
      title,
    };

    this._celebService
      .inlivewithCelebrityExportListMembersInCelebrityDetailPost$Json({ body })
      .subscribe((res) => {
        this.downloadCSV(res.data);
      });
  }

  downloadCSV(data: any) {
    let blob = base64ToBlob('data:image/png;base64,' + data);
    const url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = 'Report' + '.csv';
    a.click();
  }


}
