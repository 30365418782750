import { Component, Input, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationStart,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { AccountWithRelationshipResponseModel } from 'src/app/core/api/be/models';
import { defaultAvatar } from 'src/app/core/constant';

@Component({
  selector: 'app-card-inliver',
  templateUrl: './card-inliver.component.html',
  styleUrls: ['./card-inliver.component.scss'],
})
export class CardInliverComponent implements OnInit {
  @Input() inliver!: AccountWithRelationshipResponseModel;

  defaultAvatar = defaultAvatar;
  accountId!: string;

  constructor(private _activeRoute: ActivatedRoute, private _router: Router) {
    this._activeRoute.queryParams
      .pipe(
        tap((p: any) => {
          this.accountId = p.id!;
        })
      )
      .subscribe((res) => {});
  }

  ngOnInit(): void {}
}
