<div *ngIf="listMembers">
  <div class="container">
    <div class="col-12 flex flex-column">
      <div class="header">
        <div class="flex justify-content-between mt-5">
          <span class="fs-14">{{totalRecords}} {{'RESULTS' | translate}}</span>
          <div class="flex align-items-center">
            <button pButton type="button" label="{{'EXPORT' | translate}}" (click)="exportCSV()"
              class="p-button-outlined p-button-secondary mr-2"></button>
          </div>
        </div>
      </div>
      <div class="content-table">
        <p-table [value]="listMembers" [paginator]="true" [lazy]="true" [rows]="10" (onLazyLoad)="paginate($event)"
          [totalRecords]="totalRecords" [rowSelectable]="isRowSelectable" (onRowSelect)="onRowSelect($event)"
          (onRowUnselect)="onRowUnselect($event)" [tableStyle]="{'width': '100%'}" styleClass="p-datatable-sm"
          [rowHover]="true">
          <ng-template pTemplate="header">
            <tr>
              <th style="width:20rem">{{'LIST_INLIVER_NICKNAME' | translate}}</th>
              <th style="min-width:150px;">{{'EMAIL' | translate}}</th>
              <th style="min-width:150px">{{'CLUB_PUBLICATIONS' | translate}}</th>
              <th style="min-width:150px">{{'COMMENTS' | translate}}</th>
              <th style="min-width:150px">{{'LIST_MEMBER_LIKE' | translate}}</th>
              <th style="min-width:150px">{{'INLIVER_SINCE' | translate}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-member>
            <tr>
              <td>
                <div class="flex align-items-center cursor-pointer" (click)="gotoDetail(member)">
                  <span class="text fs-14">{{member.nickName | limitString:false:20}}</span>
                </div>
              </td>
              <td>{{member.email | limitString:false:20}}</td>
              <td>{{member.totalPublications}}</td>
              <td>{{member.totalComments}}</td>
              <td>{{member.totalReacts}}</td>
              <td>{{member.sinceJoined | date:'dd/MM/yyyy'}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>


<ng-container *ngIf="sidebarVisible">
  <p-sidebar [(visible)]="sidebarVisible" position="right">
    <!-- <ng-template pTemplate="header">FILTRER</ng-template> -->
    <div class="text-bold mt-2">
      FILTRER PAR
    </div>

    <div class="flex flex-column mt-4">
      <span class="text-bold mb-2 mt-2">
        {{'CATEGORIES' | translate}}
      </span>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox>
        {{'CLUB_CATEGORY_MUSIC' | translate}}
      </div>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox>
        {{'CLUB_CATEGORY_DANCE' | translate}}
      </div>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox>
        {{'CLUB_CATEGORY_HUMOR' | translate}}
      </div>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox>
        {{'CLUB_CATEGORY_MEDIA' | translate}}
      </div>
    </div>

    <div class="line mt-2 mb-2"></div>

    <div class="flex flex-column mt-2">
      <span class="text-bold mb-2">
        {{'TYPES' | translate}}
      </span>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox>
        {{'TALENT' | translate}}
      </div>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox>
        {{'FAN' | translate}}
      </div>
    </div>

    <div class="line mt-2 mb-2"></div>

    <div class="flex flex-column mt-2">
      <span class="text-bold mb-2 mt-2">
        {{'CLUB_BADGES' | translate}}
      </span>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox> Bronze</div>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox> Argent</div>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox> Or</div>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox> Platine</div>
      <div class="flex align-items-center mt-2"><p-checkbox class="mr-3"></p-checkbox> Diamant</div>
    </div>
  </p-sidebar>
</ng-container>
