import { tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AccountService } from "../api/services";
import { STORAGE_KEY } from "../constant";
import { Account } from "../api/models";

@Injectable({
  providedIn: "root",
})
export abstract class AuthService {
  public static SetAccountInfo(account: Account): void {
    localStorage.setItem(STORAGE_KEY.ACCOUNT, JSON.stringify(account));
  }

  public static GetAccountInfo(): Account {
    let account: Account = JSON.parse(
      localStorage.getItem(STORAGE_KEY.ACCOUNT) || "null"
    );
    return account;
  }
}
