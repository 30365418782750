import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AccountResponseWithRoleInCLubModel, ClubResponseWithTotalMemberAndGoInliveModel, MediaPostResponseModel, ReportModelAllWithDetail } from 'src/app/core/api/be/models';
import { BackOfficeService, ClubService, PostService, ReportService, AppEnumService } from 'src/app/core/api/be/services';
import { REPORT_TYPE, TYPE_STATUS_REPORT, defaultAvatar } from 'src/app/core/constant';
import { LimitStringPipe } from 'src/app/core/pipe/limit-string.pipe';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-card-report',
  templateUrl: './card-report.component.html',
  styleUrls: ['./card-report.component.scss']
})
export class CardReportComponent implements OnInit {

  @Input() report!: ReportModelAllWithDetail;
  @Input() reportSelected!: ReportModelAllWithDetail;
  @Input() checkedClicked!: boolean;
  @Input() handleType!: string;
  @Input() fromContenu = false;
  @Input() action = true;
  @Input() fromOtherPage = false;

  @Output() viewDetail = new EventEmitter();
  @Output() reportSelectedChange = new EventEmitter();
  @Output() refreshListReport = new EventEmitter();

  clubId!: string;
  listMember: AccountResponseWithRoleInCLubModel[] = [];

  defaultAvatar = defaultAvatar;
  openPopup = false;
  actionType!: string;
  completeText: boolean = false;

  listReasonReports: any[] = [];
  selectedReason!: any;

  constructor(
    private _postService: PostService,
    private _translateService: TranslateService,
    private _reportService: ReportService,
    private _clubService: ClubService,
    private _activeRoute: ActivatedRoute,
    private _appEnum: AppEnumService,
    private _backOfficeSerivce: BackOfficeService
  ) {
    this._activeRoute.queryParams.subscribe(res => {
      this.clubId = res?.clubId!;
    })
  }

  ngOnInit(): void {
    // this.getAppEnum();
  }

  timeAgo = (date: string) => {
    let currentLang = LocalStorageService.GetLocalStorage('language').toLowerCase();
    return moment.utc(date).locale(currentLang ? currentLang : 'fr').fromNow();
  };

  // deleteAndBanReport(report: ReportModelWithCommentDetail) {
  //   let listIds: string[] = [];
  //   report.reports?.forEach(x => {
  //     listIds.push(x.id!);
  //   });

  //   return forkJoin([this._reportService.inlivewithReportChangeRepostStatusReportStatusPut$Json({
  //     reportStatus: 'STATUS_ACCEPTED',
  //     body: listIds
  //   }), this.bannedFromClub()]).pipe(
  //     tap(([res1, res2]) => {
  //       if (res1.res == 0 && res2.res == 0) {
  //         window.location.reload();
  //       }
  //     })
  //   ).subscribe();
  // }

  bannedFromClub() {
    return this._clubService.inlivewithClubCreateAccountClubBannedPost$Json({
      body: {
        accountBannedId: this.getAccountBannedId(this.report),
        authorId: AuthService.GetAccountInfo().id!,
        clubId: this.clubId,
        type: "ADMIN_BANNED_MEMBER"
      }
    })
  }

  getAccountBannedId(report: ReportModelAllWithDetail) {
    if (report.type === "REPORT_POST") {
      return report.post?.authorId!
    }
    if (report.type === "REPORT_COMMENT_POST") {
      return report.commentPost?.authorId!
    }
    if (report.type === "REPORT_COMMENT_ALBUM") {
      return report.commentAlbumAsset?.authorId!
    }
    else return report.albumAsset?.authorId!
  }


  changeStatus(report: ReportModelAllWithDetail, status: string) {

    let listIds: string[] = [];
    report.reports?.forEach(x => {
      listIds.push(x.id!);
    });

    this._reportService.inlivewithReportChangeRepostStatusReportStatusPut$Json({
      reportStatus: status,
      body: listIds
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          window.location.reload();
        }
      })
    ).subscribe();
  }


  checkTypeMedia(asset: MediaPostResponseModel): string {

    if (!asset.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return 'VIDEO';
      } else {
        if (
          asset?.url!.endsWith('.aac') ||
          asset?.url!.endsWith('.wav')
        ) {
          return 'AUDIO';
        }
        return 'IMAGE'
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    if (asset?.url!.split('?')[1].startsWith('a')) {
      return 'AUDIO';
    }

    return 'UNKNOWN';
  }

  getUrl(asset: MediaPostResponseModel) {
    if (!asset?.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return asset?.url!;
        }
      }
    } else {
      if (asset?.url!.split('?')[1].startsWith('i')) {
        return asset?.url!.replace('/file.', '/thumb.')
      }

      if (asset?.url!.split('?')[1].startsWith('v')) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      }

    }
    return asset?.url!;
  }


  urlify(text: any) {
    var translatePipe = new LimitStringPipe();

    let mentionRegex = /\B@\w+/g;

    text = text?.replace(mentionRegex, (username: any) => {
      return this.detachMention(username);
    });

    let hashtagRegex = /\B#\w+/g;

    text = text?.replace(hashtagRegex, (hashtag: any) => {
      return `<a href="research?searchText=${hashtag.replace(
        '#',
        ''
      )}" target="_blank">${hashtag}</a>`;
    });

    var urlRegex =
      /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    return text?.replace(urlRegex, (url: any) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  }

  detachMention(username: string) {
    let uname = username!.replace('@', '');
    let user = this.listMember?.filter(
      (user) => user!.nickName?.replaceAll(' ', '_') == uname
    )[0];

    if (user) {
      return `<a href="account-info?accountId=${user.id}" target="_blank">${username}</a>`;
    } else {
      return `<a href="research?searchText=${username
        .replace('@', '')
        .replaceAll('_', ' ')}" target="_blank">${username}</a>`;
    }
  }

  setReportSelected() {
    this.reportSelectedChange.emit(this.report)
  }

  setStatusReport(num: number) {
    if (num == 1) {
      this.actionType = TYPE_STATUS_REPORT.STATUS_IGNORE
    }

    if (num == 2) {
      this.actionType = TYPE_STATUS_REPORT.STATUS_ACCEPTED
    }

    if (num == 3) {
      this.actionType = TYPE_STATUS_REPORT.STATUS_WARN
    }

    this.openPopup = true;
  }

  onHide() {
    this.openPopup = false;
  }

  // handleReport(report: ReportModelAllWithDetail) {
  //   LoadingService.$(true);
  //   let ids = report.reports?.map(x => x?.id!);

  //   if (this.handleType == 'CLUB') {
  //     this._backOfficeSerivce.inlivewithBackOfficeHandleReportClubPut$Json$Response({
  //       body: {
  //         reportIds: ids,
  //         status: this.actionType,
  //         reportResult: this.actionType === TYPE_STATUS_REPORT.STATUS_IGNORE ? "" : this.selectedReason?.value! ?? ''
  //       }
  //     }).subscribe(() => {
  //       console.log("?????");

  //       LoadingService.$(false);
  //       this.refreshListReport.emit();
  //       this.openPopup = false;
  //     })
  //   } else if (this.handleType == 'INLIVER') {
  //     this._backOfficeSerivce.inlivewithBackOfficeHandleReportAccountPut$Json({
  //       body: {
  //         reportIds: ids,
  //         status: this.actionType,
  //         reportResult: this.actionType === TYPE_STATUS_REPORT.STATUS_IGNORE ? "" : this.selectedReason?.value ?? ''
  //       }
  //     }).subscribe(() => {
  //       LoadingService.$(false);
  //       this.refreshListReport.emit();
  //       this.openPopup = false;
  //     })
  //   } else if (this.handleType == 'CONTENT') {
  //     let contentId = report.commentPost?.id! || report.commentAlbumAsset?.id!;

  //     this._backOfficeSerivce.inlivewithBackOfficeHandleReportContentPut$Json({
  //       body: {
  //         reportIds: ids,
  //         postId: report.type == REPORT_TYPE.REPORT_POST ? report.post?.id! : null,
  //         commentPostId: contentId ?? null,
  //         status: this.actionType,
  //         reportResult: this.actionType === TYPE_STATUS_REPORT.STATUS_IGNORE ? "" : this.selectedReason?.value!
  //       }
  //     }).subscribe(() => {
  //       LoadingService.$(false);
  //       this.refreshListReport.emit();
  //       this.openPopup = false;
  //     })
  //   }
  // }
}
