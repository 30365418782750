import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { AccountResponseWithRoleInCLubModel, CelebrityPostResponeModel, CommentPostResponseModel, MediaPostResponseModel, Post, PostResponseModel, ReactionPostResponseModel } from 'src/app/core/api/be/models';
import { PostService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { LimitStringPipe } from 'src/app/core/pipe/limit-string.pipe';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-card-post',
  templateUrl: './card-post.component.html',
  styleUrls: ['./card-post.component.scss']
})
export class CardPostComponent implements OnInit {

  @Input() post!: any;
  @Input() postCeleb!: CelebrityPostResponeModel;
  @Input() listMember: AccountResponseWithRoleInCLubModel[] = [];
  @Input() selectedPost!: any
  @Input() checkClicked: boolean = false;

  @Output() postDeleted = new EventEmitter<string>();
  @Output() selectedPostChange = new EventEmitter();
  @Output() listCommentsPost = new EventEmitter<CommentPostResponseModel[]>();
  @Output() listReactsPost = new EventEmitter<ReactionPostResponseModel[]>();

  roleOfPostAuthor!: string;

  defaultAvatar = defaultAvatar;

  created: boolean = false;
  isOverflow!: boolean;
  isHidden!: boolean;
  isAcceptToDelete!: boolean;
  showConfirmDeletePost: boolean = false;
  show: boolean = false;
  openLikesZone: boolean = false;
  openComentsZone: boolean = false;
  completeText!: boolean;
  fadePost: boolean = false;

  listIsLandscape: boolean[] = [];

  tribute!: any;
  currentControl: any
  currentClubClicked: any

  constructor(
    private _postService: PostService,
    public _router: Router,
  ) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isOverflow = this.checkOverflow(this.post.id!);
    }, 1000);
  }

  ngOnInit(): void {
    this.getRoleName()
  }

  getRoleName() {
    if (this.post?.isAdminPost!) {
      return 'INLIVETEAM'
    } else {

      if (this.post?.author?.isCelebrity!) {
        return 'CELEB'
      } else {

        if (this.post.club?.roleOfPostAuthor! == 'ROLE_ADMIN') {
          if (this.post.author?.id! === this.post.club?.authorId!) {
            return 'CREATOR'
          }
          return 'ADMIN'
        }
      }
    }

    return 'MEMBER'
  }

  checkOverflow(id: string): boolean {
    const elem = document.getElementById(id);
    if (elem) {
      return elem.offsetWidth < elem.scrollWidth;
    } else {
      return false;
    }
  }

  timeAgo = (date: string) => {
    let currentLang =
      LocalStorageService.GetLocalStorage('language').toLowerCase();
    let text = moment
      .utc(date)
      .locale(currentLang ? currentLang : 'fr')
      .fromNow();

    text = text.replace('il y a ', '');

    return text;

  };

  deletePost(event: Event) {
    event.stopPropagation();
    // this.fadePost = true;
    this.selectedPostChange.emit(this.post!);
    this.postDeleted.emit(this.post.id);
  }


  detachMention(username: string) {
    let uname = username!.replace('@', '');
    let user = this.listMember?.filter(
      (user) => user!.nickName?.replace(' ', '_') == uname
    )[0];

    if (user) {
      return `<a href="account-info?accountId=${user.id}" target="_blank">${username}</a>`;
    } else {
      return `<a href="research?searchText=${username
        .replace('@', '')
        .replace('_', ' ')}" target="_blank">${username}</a>`;
    }
  }

  urlify(text: any) {
    var translatePipe = new LimitStringPipe();

    let mentionRegex = /\B@\w+/g;

    text = text?.replace(mentionRegex, (username: any) => {
      return this.detachMention(username);
    });

    let hashtagRegex = /\B#\w+/g;

    text = text?.replace(hashtagRegex, (hashtag: any) => {
      return `<a href="research?searchText=${hashtag.replace(
        '#',
        ''
      )}" target="_blank">${hashtag}</a>`;
    });

    var urlRegex =
      /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    return text?.replace(urlRegex, (url: any) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  }

  openAccountInfo() {
    if (AuthService.GetAccountInfo().id! !== this.post.author?.id!) {
      this._router.navigate(['account-info'], {
        queryParams: { accountId: this.post.author?.id! },
      });
    }
  }

  handleText() {
    this.show = !this.show;
    if (this.show) {
      this.completeText = true;
    } else {
      this.completeText = false;
    }
  }

  selectedPostEvent() {
    this.selectedPostChange.emit(this.post!);

    if (this.post.author?.isCelebrity!) {
      // this.currentClubClicked = this.postCeleb?.clubs![0].id!;
      // this.getListComments(this.postCeleb?.clubs![0].clubAuthorId!, this.postCeleb?.clubs![0].postId!)
      // this.getListReacts(this.postCeleb?.clubs![0].clubAuthorId!, this.postCeleb?.clubs![0].postId!);

      this.currentClubClicked = this.post?.club?.id!;
      this.getListComments(this.post?.authorId!, this.post.id!)
      this.getListReacts(this.post?.authorId!, this.post.id!);
    } else {
      this.getListComments(this.post?.authorId!, this.post.id!)
      this.getListReacts(this.post?.authorId!, this.post.id!);
    }
  }

  getListComments(accountId: string, postId: string) {
    this._postService.inlivewithPostGetCommentsOnPostPostIdGet$Json({
      stageId: this.post.stage?.id!,
      postId: postId,
      limit: 10000
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.listCommentsPost.emit(res.data?.page!)
        }
      })
    ).subscribe();
  }

  getListReacts(accountId: string, postId: string) {
    this._postService.inlivewithPostGetReactOnPostPostIdGet$Json({
      stageId: this.post.stage!.id!,
      postId: postId,
      limit: 10000
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.listReactsPost.emit(res.data?.page!)
        }
      })
    ).subscribe();
  }

  setCurrentClubClicked(club: any, event: any) {
    event.stopPropagation();

    this.currentClubClicked = club?.id!
    this.getListComments(club?.clubAuthorId!, club?.postId!)
    this.getListReacts(club?.clubAuthorId!, club?.postId!);
  }
}
