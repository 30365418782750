import { Component, Input, OnInit } from '@angular/core';
import { ReactionPostResponseModel } from 'src/app/core/api/be/models';
import { defaultAvatar } from 'src/app/core/constant';

@Component({
  selector: 'app-list-likes',
  templateUrl: './list-likes.component.html',
  styleUrls: ['./list-likes.component.scss']
})
export class ListLikesComponent implements OnInit {

  @Input() listReactsPost: ReactionPostResponseModel[] = []
  @Input() commentFromPostInliveteam: boolean = false;

  defaultAvatar = defaultAvatar

  constructor() { }

  ngOnInit(): void {
  }

}
