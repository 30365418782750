import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { AccountCelebrityProfileModel, ApplicationUserClaim, ApplicationUserClaimViewModel, PlaceUpdateModel } from 'src/app/core/api/be/models';
import { AccountService } from 'src/app/core/api/be/services';
import { AddressResultModel, PLACE_LABEL, PLACE_TYPE, defaultAvatar } from 'src/app/core/constant';
import { LoadingService } from 'src/app/core/services/loading.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-celeb-info',
  templateUrl: './celeb-info.component.html',
  styleUrls: ['./celeb-info.component.scss'],
})
export class CelebInfoComponent implements OnInit {
  @Input() celeb!: AccountCelebrityProfileModel;
  @Input() isPendingFromNewCeleb: boolean = false;
  @Input() showErrorAddCeleb = false;
  @Input() errorAfterLinked = false;

  @Output() selectedCelebEmit = new EventEmitter();
  @Output() showErrorAddCelebChange = new EventEmitter();
  @Output() errorAfterLinkedChange = new EventEmitter();
  @Output() reloadCeleb = new EventEmitter();

  editCelebInfo = false;
  editPlatform = false;
  openPopupCeleb = false;
  isPicked = false;
  showErrorDisplayName = false;
  selectedCeleb: string = this._translateService.instant('SELECTION_ONE_CELEB');

  firstName: any;
  lastName: any;
  displayName: any;
  phoneNumber: any;
  companyName: any;
  siret: any;
  iBan: any;
  vat: any;
  addressComp: any;
  celebILWId!: string;
  platform: string[] = [];
  address!: AddressResultModel

  defaultAvatar = defaultAvatar;
  platformActvied: ApplicationUserClaim[] = [];

  constructor(
    private _translateService: TranslateService,
    private _messageService: MessageService,
    private _accountService: AccountService,
    private _activeRoute: ActivatedRoute,
  ) {
    this._activeRoute.queryParams
      .pipe(
        tap((p: any) => {
          this.celebILWId = p.celebId!;
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.getPlatform();
    if (this.celeb!.alias!.length > 0) {
      this.showErrorAddCelebChange.emit(false);
      this.selectedCelebEmit.emit(true);
    }
    this.siret = this.celeb.siret!
    this.iBan = this.celeb.iban!
    this.vat = this.celeb.vat!
    this.phoneNumber = this.celeb.phoneNumber!
    this.firstName = this.celeb.firstName!
    this.lastName = this.celeb.lastName!
    this.displayName = this.celeb.displayName!
    this.companyName = this.celeb.companyName!
  }

  selectedCelebChange(event: any) {
    this.selectedCeleb = event.selectedCelebs![0]?.name! ?? "";
    this.isPicked = event.isPicked
    if (this.isPicked) {
      this.celeb.alias![0] = this.selectedCeleb
    }
    this.showErrorAddCelebChange.emit(false);
    this.selectedCelebEmit.emit(event);
  }

  editCeleb() {
    this._accountService.inlivewithAccountUpdateByAdministratorsIdPut$Json({
      id: this.celebILWId,
      body: {
        firstName: this.firstName ?? this.celeb.firstName,
        lastName: this.lastName ?? this.celeb.lastName,
        displayName: this.displayName ?? this.celeb.displayName,
        phoneNumber: this.phoneNumber ?? this.celeb.phoneNumber,
        companyName: this.companyName ?? this.celeb.companyName,
        siret: this.siret ?? this.celeb.siret,
        iBan: this.iBan ?? this.celeb.iban,
        vat: this.vat ?? this.celeb.vat,
        //addressComp: this.addressComp ?? this.celeb.addressComp
      }
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.editCelebInfo = false;
          this.showErrorDisplayName = false;
          this.address ? this.updateAddress() : console.log("none");
          this.isPendingFromNewCeleb ? window.location.reload() : this.reloadCeleb.emit();
        }
        if (res.error?.includes("DISPLAYNAME_EXIST")) {
          this.showErrorDisplayName = true
        }
      })
    ).subscribe();
  }

  editPlatformFunc() {
    let body: ApplicationUserClaimViewModel[] = []
    this.platform.forEach(x => {
      body = body.concat({
        type: 'REQUEST_APPLICATION',
        value: x
      })
    });
    this._accountService.inlivewithAccountUpdateUserClaimAsyncIdPut$Json({
      id: this.celebILWId,
      body: body
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.editPlatform = false;
          this.getPlatform();
        }
      })
    ).subscribe();
  }

  updateAddress() {
    let body: PlaceUpdateModel = {
      address: this.address.address,
      addressDetails: this.address.addressDetails,
      city: this.address.city,
      country: this.address.country,
      department: this.address.department,
      district: this.address.district,
      email: this.celeb.email,
      gmplaceId: this.address.gmPlaceId,
      gmurl: this.address.gmUrl,
      normalizedEmail: this.celeb.email?.toUpperCase(),
      normalizedAddress: this.address.address.toUpperCase(),
      lat: this.address.lat,
      lng: this.address.lng,
      ward: this.address.ward,
      zipCode: this.address.zipCode,
      type: PLACE_TYPE.OTHER,
      label: PLACE_LABEL.WORK,
      addressFull: this.address.addressFull,
      administrativeArea1: this.address.administrativeArea1,
      administrativeArea2: this.address.administrativeArea2,
      administrativeArea3: this.address.administrativeArea3,
      administrativeArea4: this.address.administrativeArea4,
      administrativeArea5: this.address.administrativeArea5,
      administrativeArea6: this.address.administrativeArea6,
      administrativeArea7: this.address.administrativeArea7,
      street: this.address.street,
      countryCode: this.address.countryCode,
      streetAddress: this.address.streetAddress,
      streetNumber: this.address.streetNumber
    };

    this._accountService.inlivewithAccountUpdateAdressIdPlaceIdPut$Json({
      id: this.celebILWId!,
      placeId: this.celeb.placeId!,
      body: body
    }).subscribe();
  }

  getPlatform() {
    this._accountService.inlivewithAccountGetUserClaimAsyncIdGet$Json({
      id: this.celebILWId
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.platformActvied = res.data!
          this.platformActvied.forEach(x => {
            this.platform = this.platform.concat(x?.claimValue!)
          })
        }
      })
    ).subscribe();
  }

  openMap() {
    const url = window.origin + '/map';
    window.open(environment.ADDRESS_WEB_URL + `?callback=${url}`, 'mapWindow', `toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1250, height=640`);
    this.handleReceipeDataFromAddress();

  }

  handleReceipeDataFromAddress() {
    window.addEventListener('message', (event) => {
      let data: {
        addressFull: string;
        address: string;
        addressDetails?: any;
        street: string;
        streetNumber: string;
        streetAddress?: any;
        ward?: any;
        district?: any;
        zipCode: string;
        city: string;
        department?: any;
        country: string;
        countryCode: string;
        lat: number;
        lng: number;
        gmUrl: string;
        gmPlaceId: string;
        administrativeArea1: string;
        administrativeArea2: string;
        administrativeArea3: string;
        administrativeArea4?: any;
        administrativeArea5?: any;
        administrativeArea6?: any;
        administrativeArea7?: any;
      } = event.data;

      if (data && data.addressFull) {
        this.addressComp = data.addressFull;
        this.address = data;

        window.removeEventListener('message', () => { });
      }
    });
  }

}
