import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [LayoutComponent, SidebarComponent],
  imports: [CommonModule, RouterModule, SharedModule],
  exports: [LayoutComponent, SidebarComponent],
})
export class LayoutModule {}
