import { Component, Input, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { NgWaveformComponent } from 'ng-waveform';
import { AccountService } from 'src/app/core/api/be/services';
import { DomSanitizer } from '@angular/platform-browser';
import { map, tap } from 'rxjs/operators';
import { base64ToBlob } from 'src/app/core/constant';
import { EEvent, EventService } from 'src/app/core/services/event.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wave-form-audio',
  templateUrl: './wave-form-audio.component.html',
  styleUrls: ['./wave-form-audio.component.scss']
})
export class WaveFormAudioComponent implements OnInit {

  @ViewChild('waveform', { static: false }) waveform!: NgWaveformComponent;
  @Input() assetUrl!: string;

  mp3Resource$!: Observable<any>;
  isDoneResourceMp3 = false;

  constructor(
    private _accountService: AccountService,
    private sanitizer: DomSanitizer,
    private eventService: EventService,
    private _router: Router
  ) { }

  isPlayingAudio: boolean = false;
  timeOffAudio!: number;

  ngOnInit(): void {
    this.mp3Resource$ = this.handleBase64();
  }

  onPlayButtonClick() {
    this.isPlayingAudio ? this.waveform.pause() : this.waveform.play();
    this.isPlayingAudio = !this.isPlayingAudio;

    if (this.isPlayingAudio == true) {
      let currentId = this.assetUrl;

      this.eventService.push({
        action: EEvent.PLAY_AUDIO,
        payload: currentId,
      });
    }
  }

  onTrackLoaded(event: any) { }

  onTrackRendered(event: any) { }

  onTimeUpdate(event: any) {
    this.timeOffAudio = event.time;
    if (event.progress >= 100) {
      this.isPlayingAudio = false;
    }
  }

  onDurationChange(event: any) {
    this.timeOffAudio = event;
  }

  onRegionChange(event: any) { }

  onPaused() { }

  downloadFile(url: string) {
    return this._accountService.inlivewithAccountDownloadFilePost$Json({
      body: `"${url}"`,
    });
  }

  handleBase64(): Observable<any> {
    return this.downloadFile(this.assetUrl).pipe(
      map((res) => base64ToBlob('data:image/jpeg;base64,' + res!.data)),
      map((blob) =>
        this.sanitizer.sanitize(
          SecurityContext.URL,
          this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))
        )
      ),
      tap(() => {
        this.isDoneResourceMp3 = true;
      })
    );
  }

}
