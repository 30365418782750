/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountService } from './services/account.service';
import { AlbumService } from './services/album.service';
import { ApiInfosService } from './services/api-infos.service';
import { AppEnumService } from './services/app-enum.service';
import { BackOfficeService } from './services/back-office.service';
import { CelebrityService } from './services/celebrity.service';
import { ClubService } from './services/club.service';
import { ConversationService } from './services/conversation.service';
import { EventService } from './services/event.service';
import { FriendRelationService } from './services/friend-relation.service';
import { GoInliveService } from './services/go-inlive.service';
import { HashtagService } from './services/hashtag.service';
import { HealthcheckService } from './services/healthcheck.service';
import { PostService } from './services/post.service';
import { ReportService } from './services/report.service';
import { StageService } from './services/stage.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountService,
    AlbumService,
    ApiInfosService,
    AppEnumService,
    BackOfficeService,
    CelebrityService,
    ClubService,
    ConversationService,
    EventService,
    FriendRelationService,
    GoInliveService,
    HashtagService,
    HealthcheckService,
    PostService,
    ReportService,
    StageService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
