import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AccountGuidInputModel, AccountResponseGeneral, Hashtag, HashtagResponseModel } from 'src/app/core/api/be/models';
import { AppEnumStateService, IAppEnumStateModel } from 'src/app/core/states/app-enum-state.service';
import { ClubCategoriesEnumE } from '../account-info/account-info.component';
import { map, tap } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/services/loading.service';
import { AccountService, HashtagService } from 'src/app/core/api/be/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-hashtags-inliver',
  templateUrl: './edit-hashtags-inliver.component.html',
  styleUrls: ['./edit-hashtags-inliver.component.scss']
})
export class EditHashtagsInliverComponent implements OnInit {

  @Input() openEditHashtag: boolean = false;
  @Input() hashtag!: Hashtag[];
  @Input() account!: AccountResponseGeneral;

  @Output() openEditHashtagChange = new EventEmitter();

  hashtags: HashtagResponseModel[] = [];
  hashtagByCategory!: HashtagResponseModel[];

  categorySelected!: string;

  @Select(AppEnumStateService) _appEnum$!: Observable<IAppEnumStateModel>;
  category$!: Observable<ClubCategoriesEnumE[]>;
  listCategory!: Observable<ClubCategoriesEnumE[]>;
  listBackgroundCategory = [
    {
      posterImage: '../../../../../assets/images/category-music.png',
      value: 'MUSIC',
    },
    {
      posterImage: '../../../../../assets/images/category-humor.png',
      value: 'HUMOR',
    },
    {
      posterImage: '../../../../../assets/images/category-dance.png',
      value: 'DANCE',
    },
    {
      posterImage: '../../../../../assets/images/category-media.png',
      value: 'MEDIA',
    },
  ];
  constructor(
    private _accountService: AccountService,
    private _hashtagService: HashtagService,
    private _translateService: TranslateService
  ) {
    this.category$ = this._appEnum$.pipe(
      map((res) => {
        return Object.assign(res.appEnum.clubCategories || []);
      })
    );

    this.listCategory = this.category$;
  }

  ngOnInit(): void {
    this.getAllHashtags()
  }

  onHide() {
    this.openEditHashtagChange.emit(false);
  }

  selectCategory(name: string) {
    this.categorySelected = name;
    this.hashtagByCategory = this.hashtags.filter((x) => x.category === name);
  }

  getPosterByCategory(categoryName: string) {
    return this.listBackgroundCategory.filter(
      (l) => l.value == categoryName
    )[0]!;
  }

  getAllHashtags() {
    LoadingService.$(true);
    this._hashtagService
      .inlivewithHashtagsPageGet$Json({ page: 1, limit: 10000 })
      .subscribe((res) => {
        if (res.res == 0) {
          res.data?.page!.map((y) => this.hashtags.push(y));
          LoadingService.$(false);
          this.selectCategory('MUSIC');
        }
      });
  }

  ngAfterViewChecked(): void {
    this.hashtag.map((x) => this.setStyleHashtag(x, true));
  }

  setStyleHashtag(item: any, clicked: any) {
    let a = document.getElementById(item?.name!) as HTMLElement;

    if (!a) {
      return;
    }

    if (clicked) {
      a.style.backgroundColor = '#D5D7E3';
      a.style.color = 'white';
    } else {
      a.style.backgroundColor = 'transparent';
      a.style.color = 'black';
    }
  }

  checkDisable(item: any): boolean {
    // var res: any = [];
    // this.hashtagSelecteds.forEach(function (v, i) {
    //   res[v.category] = (res[v.category] || 0) + 1;
    // });

    return (
      this.hashtag.length >= 9 &&
      this.hashtag.findIndex((x) => x.name == item.name) < 0
    );
  }

  suivant() {
    const body: AccountGuidInputModel = {
      accountId: this.account.id!,
      values: this.hashtag.filter((h) => h?.id!).map((h) => h?.id!),
    };

    LoadingService.$(true);
    this._accountService
      .inlivewithAccountHashtagsAccountIdPut$Json({
        accountId: body?.accountId!,
        body,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            LoadingService.$(false);
          }
        })
      )
      .subscribe(
        (res) => {
          window.location.reload();
        },
        () => { }
      );
  }

  selectHashtag(item: any) {
    let clicked;
    let temp = this.hashtag.filter((h) => h.name == item.name);

    if (temp.length > 0) {
      clicked = false;
      this.hashtag = this.hashtag.filter(
        (h) => h.name !== item.name
      );
    } else {
      this.hashtag = this.hashtag.concat(item);
      clicked = true;
    }

    this.setStyleHashtag(item, clicked);
  }

  deleteHashtag(h?: Hashtag) {
    this.hashtag = this.hashtag.filter(
      (x) => x?.normalizedName != h?.normalizedName
    );

    this.setStyleHashtag(h, false);
  }
}
