import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NotificationResponse } from 'src/app/core/api/be/models';
import { BackOfficeService } from 'src/app/core/api/be/services';
import { EEvent, EventService, IEvent } from 'src/app/core/services/event.service';
import { NotificationServicee } from 'src/app/core/services/notification.service';

export interface ISideBarMenuItem {
  icon?: string;
  content: string;
  plus: boolean;
  subMenu?: ISideBarMenuItem[];
  badge?: number;
  id: number;
  parentId?: number;
  router?: string[];
  plusRouter?: string[];
}

export interface IMenu {
  label?: string;
  menu?: ISideBarMenuItem[];
}

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private _menuItems: IMenu[];

  notificationsNotRead: NotificationResponse[] = [];

  isCountNotiNotRead$ = this._notificationService.isCountNotiNotRead$$.pipe(
    tap((res) => {
      if (res) {
        this.getNotiUnread().subscribe();
      }
    })
  )

  constructor(
    public _translateService: TranslateService,
    private _backOfficeService: BackOfficeService,
    private _eventService: EventService,
    private _notificationService: NotificationServicee
  ) {
    this.isCountNotiNotRead$.subscribe()
    this._menuItems = this._InitListMenuItem();
    this._eventService
      .receive()
      .pipe()
      .subscribe((data: IEvent) => {
        if (data.action == EEvent.UPDATE_NOTI) {
          this.notificationsNotRead = data.payload
          this._menuItems![0]!.menu![1].badge = this.notificationsNotRead.length!
          this._eventService.push({ action: EEvent.GET_NOTI, payload: this.notificationsNotRead })
        }
      });
  }

  private _InitListMenuItem(): IMenu[] {
    let list: IMenu[] = [
      {
        menu: [
          {
            id: 0,
            // icon: 'menu-dashboard.svg',
            icon: 'home',
            content: this._translateService.instant('MENU_DASHBOARD'),
            plus: false,
            router: ['dashboard'],
          },
          {
            id: 1,
            // icon: 'menu-notifications.svg',
            icon: 'bell',
            content: this._translateService.instant('MENU_NOTIFICATIONS'),
            plus: true,
            router: ['notifications'],
            plusRouter: ['', ''],
            badge: this.notificationsNotRead.length!
          },
          {
            id: 2,
            // icon: 'menu-messages.svg',
            icon: 'comment',
            content: this._translateService.instant('MENU_MESSAGES'),
            plus: false,
            router: ['messages'],
            subMenu: [
              // {
              //   id: 0,
              //   content: '',
              //   plus: false,
              //   parentId: 5,
              //   router: ['', ''],
              // },
            ],
          },
        ],
      },

      {
        label: 'Contenus',
        menu: [
          {
            id: 10,
            // icon: 'menu-dashboard.svg',
            icon: 'bell',
            content: this._translateService.instant('CLUB_PUBLICATIONS'),
            plus: false,
            router: ['content/publications'],
          },
          {
            id: 11,
            // icon: 'menu-dashboard.svg',
            icon: 'bell',
            content: this._translateService.instant('CLUB_DISCUSSIONS'),
            plus: false,
            router: ['content/discussions'],
          },
          {
            id: 12,
            // icon: 'menu-dashboard.svg',
            icon: 'bell',
            content: this._translateService.instant('CLUB_EVENTS'),
            plus: false,
            router: ['content/events'],
          },
          {
            id: 13,
            // icon: 'menu-dashboard.svg',
            icon: 'bell',
            content: this._translateService.instant('CLUB_ALBUMS'),
            plus: false,
            router: ['content/albums'],
          },
        ],
      },

      {
        label: 'Profils',
        menu: [
          {
            id: 3,
            // icon: 'menu-dashboard.svg',
            icon: 'users',
            content: this._translateService.instant('MENU_INLIVERS'),
            plus: false,
            router: ['inlivers'],
          },
          {
            id: 4,
            // icon: 'menu-dashboard.svg',
            icon: 'users',
            content: this._translateService.instant('MENU_CLUBS'),
            plus: false,
            router: ['clubs'],
          },
          {
            id: 5,
            // icon: 'menu-dashboard.svg',
            icon: 'users',
            content: this._translateService.instant('MENU_CELEBRITIES'),
            plus: false,
            router: ['celebrities'],
          },
        ],
      },

      {
        label: 'Visibilités',
        menu: [
          {
            id: 6,
            icon: 'bell',
            content: this._translateService.instant('MENU_BADGES'),
            plus: false,
            router: ['badges'],
          },
          {
            id: 7,
            icon: 'bell',
            content: this._translateService.instant('MENU_HASHTAGS'),
            plus: false,
            router: ['hashtags'],
          }
        ]
      },

      {
        label: 'Alertes',
        menu: [
          {
            id: 8,
            icon: 'th-large',
            content: this._translateService.instant('MENU_SIGNALEMENTS'),
            plus: false,
            router: ['signalements']
          }
        ]
      },

      {
        label: 'Modération',
        menu: [
          {
            id: 9,
            icon: 'users',
            content: this._translateService.instant('MENU_ROLES'),
            plus: false,
            router: ['roles'],
          }
        ]
      }

    ];
    return list;
  }

  public GetListMenuItem(): IMenu[] {
    return this._menuItems;
  }

  getNotiUnread() {
    return this._backOfficeService.inlivewithBackOfficeGetNotificationForBoGet$Json({
      isRead: false,
      limit: 1000
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this._menuItems![0]!.menu![1].badge! = res.data?.totalItems!
          this._eventService.push({ action: EEvent.GET_NOTI, payload: res.data?.page! })
        }
      })
    )
  }
}
