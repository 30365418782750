<div class="mt-5 mb-5">
  {{totalFriend}} <span> {{'RESULTS' | translate}}</span>
</div>

<div class="flex raw">
  <div class="col-9">
    <div class="flex flex-wrap">
      <div class="flex mt-5" *ngFor="let item of inlivers">
        <app-card-inliver [inliver]="item" (click)="selectInliver(item)"></app-card-inliver>
      </div>
    </div>
  </div>

  <div class="col-3" *ngIf="inliverSelected">
    <div class="zone-detail">
      <div class="flex align-items-center mt-4">
        <div class="zone-avatar">
          <img class="fit-size" [src]="inliverSelected.avatar ?? defaultAvatar">
        </div>

        <div class="flex flex-column justify-content-center ml-3 fs-14">
          <div class="text-bold">{{inliverSelected.nickName! | limitString:false:20}}</div>
          <!-- <div class="mt-3">Membre depuis : {{inliverSelected.dateCreated | date:'dd/mm/yyy'}}</div> -->
          <div class="mt-1">Dernière visite : </div>
        </div>
      </div>

      <div class="flex flex-column">
        <div class="flex mt-3 fs-14">
          <button pButton label="{{'Fiche Inliver' | translate}}"
            class="w-100 p-button-outlined blue mb-1 p-button-secondary mr-2"></button>
          <div class="flex justify-content-center align-items-center btn-email blue cursor-pointer">
            <i class="pi pi-envelope"></i>
          </div>
        </div>
        <div class="fs-14">
          <button pButton label="{{'Supprimer des amis' | translate}}"
            class="w-100 p-button-outlined red mt-1 p-button-secondary mr-2"></button>
        </div>
      </div>
    </div>


    <div class="zone-detail mt-3">
      <span class="title">
        Clubs commun ({{inliverSelected.totalCommondClub}})
      </span>

      <div class="flex flex-column mt-3 common-club">
        <div class="flex align-items-center mt-2" *ngFor="let item of clubCommon">
          <div class="zone-avatar">
            <img class="fit-size" [src]="item.avatar?? defaultAvatar" alt="" srcset="">
          </div>
          <div class="ml-2 club-name">
            {{item.name! | limitString:false:20}}
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
