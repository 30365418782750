<div class="side-bar">

  <div class="side-bar-header">
    <div class="flex" *ngIf="currentUser">
      <div class="flex justify-content-center align-items-center m-2 zone-avt">
        <img [src]="currentUser.avatar" width="48" height="48" alt="" />
      </div>
      <div class="flex flex-column justify-content-center ml-1">
        <div><b>{{currentUser.nickName}}</b></div>
        <div>
          <p-button (click)="logout()" class="">Deconnexion</p-button>
        </div>
      </div>
      <div>

      </div>
    </div>
    <div class="flex" *ngIf="!currentUser">
      <div class="flex justify-content-center align-items-center m-2">
        <img src="../../../assets/images/svgs/avatar-default.svg" width="48" height="48" alt="" />
      </div>
      <div class="flex flex-column justify-content-center ml-1">
        <div><b>Current user</b></div>
        <div>Role</div>
      </div>
    </div>

    <div class="flex ml-2">
      <small *ngIf="!environment.production">Version {{environment.VERSION}}</small>
    </div>
  </div>

  <div class="side-bar-menu">
    <div class="flex flex-column">
      <ng-container *ngFor="let menu of menuItems">

        <span class="m-3 text-gray">{{menu.label}}</span>

        <ng-container *ngFor="let menuItem of menu.menu">
          <span class="text-gray" [routerLink]="menuItem.router!" routerLinkActive="active">
            <div class="side-bar-menu-item">
              <div class="icon">
                <!-- <img [src]="'../../../assets/images/svgs/' + menuItem.icon" width="24" height="24"> -->
                <i [class]="'pi pi-' + menuItem.icon"></i>
              </div>
              <div class="content">
                <span>{{ menuItem.content }}</span>
                <div class="badge" *ngIf="menuItem?.badge! > 0">
                  <span class="text-center">
                    {{menuItem.badge}}
                  </span>
                </div>
              </div>
              <div class="plus" *ngIf="menuItem.plus">
                <a [routerLink]="menuItem.plusRouter!" (click)="$event.stopPropagation()">
                  <img [src]="'../../../assets/images/svgs/menu-icon/plus.svg'" alt="" />
                </a>
              </div>
            </div>
            <ng-container *ngFor="let subMenuItem of menuItem.subMenu">
              <a [routerLink]="subMenuItem.router!" routerLinkActive="active-child" (click)="$event.stopPropagation()">
                <div class="side-bar-sub-menu-item">
                  <div class="icon" style="opacity: 0">
                    <img [src]="'../../../assets/images/svgs/menu-icon/plus.svg'" alt="" />
                  </div>
                  <div class="content">
                    <span>
                      {{ subMenuItem.content }}
                    </span>
                  </div>
                </div>
              </a>
            </ng-container>
          </span>
        </ng-container>
      </ng-container>
    </div>


    <div class="flex flex-column">
      <div class="flex ml-3 cursor-pointer" (click)="login()">
        <img src="../../../assets/images/svgs/menu-setting.svg" width="24" height="24">
        <span class="ml-3">
          Inlive With
        </span>
      </div>
    </div>
  </div>
</div>
