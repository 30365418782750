import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AlbumResponseWithTotalAssetModel } from 'src/app/core/api/be/models';
import { AccountService } from 'src/app/core/api/be/services';

@Component({
  selector: 'app-card-album',
  templateUrl: './card-album.component.html',
  styleUrls: ['./card-album.component.scss']
})
export class CardAlbumComponent implements OnInit {

  @Input() album!: AlbumResponseWithTotalAssetModel;
  @Output() showDetail = new EventEmitter();

  constructor(
    private _accountService: AccountService
  ) { }

  ngOnInit(): void {

  }

  openDetailAlbum(){
    this.showDetail.emit(this.album);
  }




}
