/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountCelebrityProfileModelResponseModel } from '../models/account-celebrity-profile-model-response-model';
import { AccountCelebrityRequestResponeModelPaginationModelResponseModel } from '../models/account-celebrity-request-respone-model-pagination-model-response-model';
import { AccountClubListResponseModel } from '../models/account-club-list-response-model';
import { AccountResponseForHashtagModelPaginationModelResponseModel } from '../models/account-response-for-hashtag-model-pagination-model-response-model';
import { AccountResponseForMentionPaginationModelResponseModel } from '../models/account-response-for-mention-pagination-model-response-model';
import { AccountResponseGeneralPaginationModelResponseModel } from '../models/account-response-general-pagination-model-response-model';
import { AccountResponseGeneralResponseModel } from '../models/account-response-general-response-model';
import { AccountResponseWithRoleAndTotalAnalyzePaginationModelResponseModel } from '../models/account-response-with-role-and-total-analyze-pagination-model-response-model';
import { AccountWithRoleAndTotalAnalyzeFilter } from '../models/account-with-role-and-total-analyze-filter';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { CategoryWithClubListResponseModel } from '../models/category-with-club-list-response-model';
import { CelebrityAliasClubsResponseModelPaginationModelResponseModel } from '../models/celebrity-alias-clubs-response-model-pagination-model-response-model';
import { CelebrityAliasCreateModel } from '../models/celebrity-alias-create-model';
import { CelebrityAliasFilter } from '../models/celebrity-alias-filter';
import { CelebrityAliasResponseModelPaginationModelResponseModel } from '../models/celebrity-alias-response-model-pagination-model-response-model';
import { CelebrityAliasResponseModelResponseModel } from '../models/celebrity-alias-response-model-response-model';
import { CelebrityAliasResponsePaginationModelResponseModel } from '../models/celebrity-alias-response-pagination-model-response-model';
import { CelebrityAliasResponseWithClubModelPaginationModelResponseModel } from '../models/celebrity-alias-response-with-club-model-pagination-model-response-model';
import { ClubFilter } from '../models/club-filter';
import { ClubFilterForCreatePostResponsePaginationModelResponseModel } from '../models/club-filter-for-create-post-response-pagination-model-response-model';
import { ClubFilterModelResponsePaginationModelResponseModel } from '../models/club-filter-model-response-pagination-model-response-model';
import { ClubResponseWithTotalMemberModelPaginationModelResponseModel } from '../models/club-response-with-total-member-model-pagination-model-response-model';
import { DashBoardInfomationResponseResponseModel } from '../models/dash-board-infomation-response-response-model';
import { DateFilter } from '../models/date-filter';
import { ExportCelebrityAliasFilter } from '../models/export-celebrity-alias-filter';
import { ExportClubWithFilter } from '../models/export-club-with-filter';
import { ExportMemberClub } from '../models/export-member-club';
import { GrantAccessLevelCelbrity } from '../models/grant-access-level-celbrity';
import { HandleReportAccountAndClubInputModel } from '../models/handle-report-account-and-club-input-model';
import { HandleReportContentInputModel } from '../models/handle-report-content-input-model';
import { HashtagInFormationResponePaginationModelResponseModel } from '../models/hashtag-in-formation-respone-pagination-model-response-model';
import { HashtagResponseForBackOfficeModelPaginationModelResponseModel } from '../models/hashtag-response-for-back-office-model-pagination-model-response-model';
import { HashtagResponseModelListResponseModel } from '../models/hashtag-response-model-list-response-model';
import { Int32ResponseModel } from '../models/int-32-response-model';
import { NotificationResponseListResponseModel } from '../models/notification-response-list-response-model';
import { NotificationResponsePaginationModelResponseModel } from '../models/notification-response-pagination-model-response-model';
import { PostResponseModelPaginationModelResponseModel } from '../models/post-response-model-pagination-model-response-model';
import { ReportModelAllWithDetailPaginationModelResponseModel } from '../models/report-model-all-with-detail-pagination-model-response-model';
import { ReportReponseWithDateTimeAndReasonResultResponseModel } from '../models/report-reponse-with-date-time-and-reason-result-response-model';
import { ReportRequestModel } from '../models/report-request-model';
import { ReportResponseModelListResponseModel } from '../models/report-response-model-list-response-model';
import { ReportResponseModelResponseModel } from '../models/report-response-model-response-model';
import { ReportWarnAccountCreateModel } from '../models/report-warn-account-create-model';
import { ReportWarnClubCreateModel } from '../models/report-warn-club-create-model';
import { RevokeAccessLevelCelbrity } from '../models/revoke-access-level-celbrity';
import { StringResponseModel } from '../models/string-response-model';
import { TopCelebrityOnDashboardResponeModelPaginationModelResponseModel } from '../models/top-celebrity-on-dashboard-respone-model-pagination-model-response-model';
import { TopClubForDashboardResponsePaginationModelResponseModel } from '../models/top-club-for-dashboard-response-pagination-model-response-model';
import { TopInliversOnDashboardPaginationModelResponseModel } from '../models/top-inlivers-on-dashboard-pagination-model-response-model';
import { TypeClubInFormationResponeResponseModel } from '../models/type-club-in-formation-respone-response-model';
import { UpdateHashtagInputModel } from '../models/update-hashtag-input-model';

@Injectable({
  providedIn: 'root',
})
export class BackOfficeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithBackOfficeGetCelebrityAliasPagePost
   */
  static readonly InlivewithBackOfficeGetCelebrityAliasPagePostPath = '/inlivewith/BackOffice/GetCelebrityAliasPage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetCelebrityAliasPagePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetCelebrityAliasPagePost$Plain$Response(params?: {
    body?: CelebrityAliasFilter
  }): Observable<StrictHttpResponse<CelebrityAliasResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetCelebrityAliasPagePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetCelebrityAliasPagePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetCelebrityAliasPagePost$Plain(params?: {
    body?: CelebrityAliasFilter
  }): Observable<CelebrityAliasResponsePaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetCelebrityAliasPagePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponsePaginationModelResponseModel>) => r.body as CelebrityAliasResponsePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetCelebrityAliasPagePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetCelebrityAliasPagePost$Json$Response(params?: {
    body?: CelebrityAliasFilter
  }): Observable<StrictHttpResponse<CelebrityAliasResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetCelebrityAliasPagePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetCelebrityAliasPagePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetCelebrityAliasPagePost$Json(params?: {
    body?: CelebrityAliasFilter
  }): Observable<CelebrityAliasResponsePaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetCelebrityAliasPagePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponsePaginationModelResponseModel>) => r.body as CelebrityAliasResponsePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetListCelebrityByStatusGet
   */
  static readonly InlivewithBackOfficeGetListCelebrityByStatusGetPath = '/inlivewith/BackOffice/GetListCelebrityByStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetListCelebrityByStatusGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListCelebrityByStatusGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountCelebrityRequestResponeModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetListCelebrityByStatusGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountCelebrityRequestResponeModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetListCelebrityByStatusGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListCelebrityByStatusGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<AccountCelebrityRequestResponeModelPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetListCelebrityByStatusGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountCelebrityRequestResponeModelPaginationModelResponseModel>) => r.body as AccountCelebrityRequestResponeModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetListCelebrityByStatusGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListCelebrityByStatusGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountCelebrityRequestResponeModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetListCelebrityByStatusGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountCelebrityRequestResponeModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetListCelebrityByStatusGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListCelebrityByStatusGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<AccountCelebrityRequestResponeModelPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetListCelebrityByStatusGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountCelebrityRequestResponeModelPaginationModelResponseModel>) => r.body as AccountCelebrityRequestResponeModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetAccountCelebrityByAccountIdAccountIdGet
   */
  static readonly InlivewithBackOfficeGetAccountCelebrityByAccountIdAccountIdGetPath = '/inlivewith/BackOffice/GetAccountCelebrityByAccountId/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAccountCelebrityByAccountIdAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetAccountCelebrityByAccountIdAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<AccountCelebrityProfileModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAccountCelebrityByAccountIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountCelebrityProfileModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAccountCelebrityByAccountIdAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetAccountCelebrityByAccountIdAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<AccountCelebrityProfileModelResponseModel> {

    return this.inlivewithBackOfficeGetAccountCelebrityByAccountIdAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountCelebrityProfileModelResponseModel>) => r.body as AccountCelebrityProfileModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAccountCelebrityByAccountIdAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetAccountCelebrityByAccountIdAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<AccountCelebrityProfileModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAccountCelebrityByAccountIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountCelebrityProfileModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAccountCelebrityByAccountIdAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetAccountCelebrityByAccountIdAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<AccountCelebrityProfileModelResponseModel> {

    return this.inlivewithBackOfficeGetAccountCelebrityByAccountIdAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountCelebrityProfileModelResponseModel>) => r.body as AccountCelebrityProfileModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetAccountByIdAccountIdGet
   */
  static readonly InlivewithBackOfficeGetAccountByIdAccountIdGetPath = '/inlivewith/BackOffice/GetAccountById/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAccountByIdAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetAccountByIdAccountIdGet$Plain$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAccountByIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAccountByIdAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetAccountByIdAccountIdGet$Plain(params: {
    accountId: string;
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithBackOfficeGetAccountByIdAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAccountByIdAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetAccountByIdAccountIdGet$Json$Response(params: {
    accountId: string;
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAccountByIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAccountByIdAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetAccountByIdAccountIdGet$Json(params: {
    accountId: string;
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithBackOfficeGetAccountByIdAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGrantAccessLevelCelebrityPost
   */
  static readonly InlivewithBackOfficeGrantAccessLevelCelebrityPostPath = '/inlivewith/BackOffice/GrantAccessLevelCelebrity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGrantAccessLevelCelebrityPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGrantAccessLevelCelebrityPost$Plain$Response(params?: {
    body?: GrantAccessLevelCelbrity
  }): Observable<StrictHttpResponse<AccountClubListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGrantAccessLevelCelebrityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGrantAccessLevelCelebrityPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGrantAccessLevelCelebrityPost$Plain(params?: {
    body?: GrantAccessLevelCelbrity
  }): Observable<AccountClubListResponseModel> {

    return this.inlivewithBackOfficeGrantAccessLevelCelebrityPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubListResponseModel>) => r.body as AccountClubListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGrantAccessLevelCelebrityPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGrantAccessLevelCelebrityPost$Json$Response(params?: {
    body?: GrantAccessLevelCelbrity
  }): Observable<StrictHttpResponse<AccountClubListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGrantAccessLevelCelebrityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGrantAccessLevelCelebrityPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGrantAccessLevelCelebrityPost$Json(params?: {
    body?: GrantAccessLevelCelbrity
  }): Observable<AccountClubListResponseModel> {

    return this.inlivewithBackOfficeGrantAccessLevelCelebrityPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubListResponseModel>) => r.body as AccountClubListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeRevokeAccessLevelCelebrityDelete
   */
  static readonly InlivewithBackOfficeRevokeAccessLevelCelebrityDeletePath = '/inlivewith/BackOffice/RevokeAccessLevelCelebrity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Plain$Response(params?: {
    body?: RevokeAccessLevelCelbrity
  }): Observable<StrictHttpResponse<AccountClubListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeRevokeAccessLevelCelebrityDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Plain(params?: {
    body?: RevokeAccessLevelCelbrity
  }): Observable<AccountClubListResponseModel> {

    return this.inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubListResponseModel>) => r.body as AccountClubListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Json$Response(params?: {
    body?: RevokeAccessLevelCelbrity
  }): Observable<StrictHttpResponse<AccountClubListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeRevokeAccessLevelCelebrityDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Json(params?: {
    body?: RevokeAccessLevelCelbrity
  }): Observable<AccountClubListResponseModel> {

    return this.inlivewithBackOfficeRevokeAccessLevelCelebrityDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubListResponseModel>) => r.body as AccountClubListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeBannedAccountPut
   */
  static readonly InlivewithBackOfficeBannedAccountPutPath = '/inlivewith/BackOffice/BannedAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeBannedAccountPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeBannedAccountPut$Plain$Response(params?: {
    accountId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeBannedAccountPutPath, 'put');
    if (params) {
      rb.query('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeBannedAccountPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeBannedAccountPut$Plain(params?: {
    accountId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeBannedAccountPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeBannedAccountPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeBannedAccountPut$Json$Response(params?: {
    accountId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeBannedAccountPutPath, 'put');
    if (params) {
      rb.query('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeBannedAccountPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeBannedAccountPut$Json(params?: {
    accountId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeBannedAccountPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeBannedClubPut
   */
  static readonly InlivewithBackOfficeBannedClubPutPath = '/inlivewith/BackOffice/BannedClub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeBannedClubPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeBannedClubPut$Plain$Response(params?: {
    clubId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeBannedClubPutPath, 'put');
    if (params) {
      rb.query('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeBannedClubPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeBannedClubPut$Plain(params?: {
    clubId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeBannedClubPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeBannedClubPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeBannedClubPut$Json$Response(params?: {
    clubId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeBannedClubPutPath, 'put');
    if (params) {
      rb.query('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeBannedClubPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeBannedClubPut$Json(params?: {
    clubId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeBannedClubPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetListCelebrityGet
   */
  static readonly InlivewithBackOfficeGetListCelebrityGetPath = '/inlivewith/BackOffice/GetListCelebrity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetListCelebrityGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListCelebrityGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetListCelebrityGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetListCelebrityGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListCelebrityGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetListCelebrityGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetListCelebrityGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListCelebrityGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetListCelebrityGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetListCelebrityGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListCelebrityGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetListCelebrityGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeHandleAccountCelebrityRequestAccountIdIsAcceptPut
   */
  static readonly InlivewithBackOfficeHandleAccountCelebrityRequestAccountIdIsAcceptPutPath = '/inlivewith/BackOffice/HandleAccountCelebrityRequest/{accountId}/{isAccept}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeHandleAccountCelebrityRequestAccountIdIsAcceptPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeHandleAccountCelebrityRequestAccountIdIsAcceptPut$Plain$Response(params: {
    accountId: string;
    isAccept: boolean;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeHandleAccountCelebrityRequestAccountIdIsAcceptPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('isAccept', params.isAccept, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeHandleAccountCelebrityRequestAccountIdIsAcceptPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeHandleAccountCelebrityRequestAccountIdIsAcceptPut$Plain(params: {
    accountId: string;
    isAccept: boolean;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeHandleAccountCelebrityRequestAccountIdIsAcceptPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeHandleAccountCelebrityRequestAccountIdIsAcceptPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeHandleAccountCelebrityRequestAccountIdIsAcceptPut$Json$Response(params: {
    accountId: string;
    isAccept: boolean;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeHandleAccountCelebrityRequestAccountIdIsAcceptPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('isAccept', params.isAccept, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeHandleAccountCelebrityRequestAccountIdIsAcceptPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeHandleAccountCelebrityRequestAccountIdIsAcceptPut$Json(params: {
    accountId: string;
    isAccept: boolean;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeHandleAccountCelebrityRequestAccountIdIsAcceptPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetAllReportByAccountIdIdentityUserIdPost
   */
  static readonly InlivewithBackOfficeGetAllReportByAccountIdIdentityUserIdPostPath = '/inlivewith/BackOffice/GetAllReportByAccountId/{identityUserId}';

  /**
   * Get all report that accountId had been reported by other Inliver.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllReportByAccountIdIdentityUserIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByAccountIdIdentityUserIdPost$Plain$Response(params: {
    identityUserId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllReportByAccountIdIdentityUserIdPostPath, 'post');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * Get all report that accountId had been reported by other Inliver.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllReportByAccountIdIdentityUserIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByAccountIdIdentityUserIdPost$Plain(params: {
    identityUserId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllReportByAccountIdIdentityUserIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

  /**
   * Get all report that accountId had been reported by other Inliver.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllReportByAccountIdIdentityUserIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByAccountIdIdentityUserIdPost$Json$Response(params: {
    identityUserId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllReportByAccountIdIdentityUserIdPostPath, 'post');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * Get all report that accountId had been reported by other Inliver.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllReportByAccountIdIdentityUserIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByAccountIdIdentityUserIdPost$Json(params: {
    identityUserId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllReportByAccountIdIdentityUserIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetAllReportByCelebrityIdIdentityUserIdPost
   */
  static readonly InlivewithBackOfficeGetAllReportByCelebrityIdIdentityUserIdPostPath = '/inlivewith/BackOffice/GetAllReportByCelebrityId/{identityUserId}';

  /**
   * Get all report that accountId had been reported by other Inliver.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllReportByCelebrityIdIdentityUserIdPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetAllReportByCelebrityIdIdentityUserIdPost$Plain$Response(params: {
    identityUserId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllReportByCelebrityIdIdentityUserIdPostPath, 'post');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * Get all report that accountId had been reported by other Inliver.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllReportByCelebrityIdIdentityUserIdPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetAllReportByCelebrityIdIdentityUserIdPost$Plain(params: {
    identityUserId: string;
    page?: number;
    limit?: number;
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllReportByCelebrityIdIdentityUserIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

  /**
   * Get all report that accountId had been reported by other Inliver.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllReportByCelebrityIdIdentityUserIdPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetAllReportByCelebrityIdIdentityUserIdPost$Json$Response(params: {
    identityUserId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllReportByCelebrityIdIdentityUserIdPostPath, 'post');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * Get all report that accountId had been reported by other Inliver.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllReportByCelebrityIdIdentityUserIdPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetAllReportByCelebrityIdIdentityUserIdPost$Json(params: {
    identityUserId: string;
    page?: number;
    limit?: number;
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllReportByCelebrityIdIdentityUserIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetAllReportOfAccountIdIdentityUserIdPost
   */
  static readonly InlivewithBackOfficeGetAllReportOfAccountIdIdentityUserIdPostPath = '/inlivewith/BackOffice/GetAllReportOfAccountId/{identityUserId}';

  /**
   * Get all report that accountId had reported other Inliver.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllReportOfAccountIdIdentityUserIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportOfAccountIdIdentityUserIdPost$Plain$Response(params: {
    identityUserId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllReportOfAccountIdIdentityUserIdPostPath, 'post');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * Get all report that accountId had reported other Inliver.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllReportOfAccountIdIdentityUserIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportOfAccountIdIdentityUserIdPost$Plain(params: {
    identityUserId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllReportOfAccountIdIdentityUserIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

  /**
   * Get all report that accountId had reported other Inliver.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllReportOfAccountIdIdentityUserIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportOfAccountIdIdentityUserIdPost$Json$Response(params: {
    identityUserId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllReportOfAccountIdIdentityUserIdPostPath, 'post');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * Get all report that accountId had reported other Inliver.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllReportOfAccountIdIdentityUserIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportOfAccountIdIdentityUserIdPost$Json(params: {
    identityUserId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllReportOfAccountIdIdentityUserIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetAllReportByTypeAndFilterPost
   */
  static readonly InlivewithBackOfficeGetAllReportByTypeAndFilterPostPath = '/inlivewith/BackOffice/GetAllReportByTypeAndFilter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllReportByTypeAndFilterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByTypeAndFilterPost$Plain$Response(params?: {
    page?: number;
    limit?: number;
    body?: ReportRequestModel
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllReportByTypeAndFilterPostPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllReportByTypeAndFilterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByTypeAndFilterPost$Plain(params?: {
    page?: number;
    limit?: number;
    body?: ReportRequestModel
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllReportByTypeAndFilterPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllReportByTypeAndFilterPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByTypeAndFilterPost$Json$Response(params?: {
    page?: number;
    limit?: number;
    body?: ReportRequestModel
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllReportByTypeAndFilterPostPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllReportByTypeAndFilterPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByTypeAndFilterPost$Json(params?: {
    page?: number;
    limit?: number;
    body?: ReportRequestModel
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllReportByTypeAndFilterPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetAllReportContentOfClubClubIdPost
   */
  static readonly InlivewithBackOfficeGetAllReportContentOfClubClubIdPostPath = '/inlivewith/BackOffice/GetAllReportContentOfClub/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllReportContentOfClubClubIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportContentOfClubClubIdPost$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    body?: ReportRequestModel
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllReportContentOfClubClubIdPostPath, 'post');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllReportContentOfClubClubIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportContentOfClubClubIdPost$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
    body?: ReportRequestModel
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllReportContentOfClubClubIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllReportContentOfClubClubIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportContentOfClubClubIdPost$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    body?: ReportRequestModel
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllReportContentOfClubClubIdPostPath, 'post');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllReportContentOfClubClubIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportContentOfClubClubIdPost$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
    body?: ReportRequestModel
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllReportContentOfClubClubIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetAllReportByClubIdClubIdPost
   */
  static readonly InlivewithBackOfficeGetAllReportByClubIdClubIdPostPath = '/inlivewith/BackOffice/GetAllReportByClubId/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllReportByClubIdClubIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByClubIdClubIdPost$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllReportByClubIdClubIdPostPath, 'post');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllReportByClubIdClubIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByClubIdClubIdPost$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllReportByClubIdClubIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllReportByClubIdClubIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByClubIdClubIdPost$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllReportByClubIdClubIdPostPath, 'post');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllReportByClubIdClubIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllReportByClubIdClubIdPost$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<ReportModelAllWithDetailPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllReportByClubIdClubIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportModelAllWithDetailPaginationModelResponseModel>) => r.body as ReportModelAllWithDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeHandleReportAccountPut
   */
  static readonly InlivewithBackOfficeHandleReportAccountPutPath = '/inlivewith/BackOffice/HandleReportAccount';

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeHandleReportAccountPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeHandleReportAccountPut$Plain$Response(params?: {
    body?: HandleReportAccountAndClubInputModel
  }): Observable<StrictHttpResponse<ReportResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeHandleReportAccountPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelListResponseModel>;
      })
    );
  }

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeHandleReportAccountPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeHandleReportAccountPut$Plain(params?: {
    body?: HandleReportAccountAndClubInputModel
  }): Observable<ReportResponseModelListResponseModel> {

    return this.inlivewithBackOfficeHandleReportAccountPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelListResponseModel>) => r.body as ReportResponseModelListResponseModel)
    );
  }

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeHandleReportAccountPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeHandleReportAccountPut$Json$Response(params?: {
    body?: HandleReportAccountAndClubInputModel
  }): Observable<StrictHttpResponse<ReportResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeHandleReportAccountPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelListResponseModel>;
      })
    );
  }

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeHandleReportAccountPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeHandleReportAccountPut$Json(params?: {
    body?: HandleReportAccountAndClubInputModel
  }): Observable<ReportResponseModelListResponseModel> {

    return this.inlivewithBackOfficeHandleReportAccountPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelListResponseModel>) => r.body as ReportResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeHandleReportClubPut
   */
  static readonly InlivewithBackOfficeHandleReportClubPutPath = '/inlivewith/BackOffice/HandleReportClub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeHandleReportClubPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeHandleReportClubPut$Plain$Response(params?: {
    body?: HandleReportAccountAndClubInputModel
  }): Observable<StrictHttpResponse<ReportResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeHandleReportClubPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeHandleReportClubPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeHandleReportClubPut$Plain(params?: {
    body?: HandleReportAccountAndClubInputModel
  }): Observable<ReportResponseModelListResponseModel> {

    return this.inlivewithBackOfficeHandleReportClubPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelListResponseModel>) => r.body as ReportResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeHandleReportClubPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeHandleReportClubPut$Json$Response(params?: {
    body?: HandleReportAccountAndClubInputModel
  }): Observable<StrictHttpResponse<ReportResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeHandleReportClubPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeHandleReportClubPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeHandleReportClubPut$Json(params?: {
    body?: HandleReportAccountAndClubInputModel
  }): Observable<ReportResponseModelListResponseModel> {

    return this.inlivewithBackOfficeHandleReportClubPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelListResponseModel>) => r.body as ReportResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeHandleReportContentPut
   */
  static readonly InlivewithBackOfficeHandleReportContentPutPath = '/inlivewith/BackOffice/HandleReportContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeHandleReportContentPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeHandleReportContentPut$Plain$Response(params?: {
    body?: HandleReportContentInputModel
  }): Observable<StrictHttpResponse<ReportResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeHandleReportContentPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeHandleReportContentPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeHandleReportContentPut$Plain(params?: {
    body?: HandleReportContentInputModel
  }): Observable<ReportResponseModelListResponseModel> {

    return this.inlivewithBackOfficeHandleReportContentPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelListResponseModel>) => r.body as ReportResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeHandleReportContentPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeHandleReportContentPut$Json$Response(params?: {
    body?: HandleReportContentInputModel
  }): Observable<StrictHttpResponse<ReportResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeHandleReportContentPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeHandleReportContentPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeHandleReportContentPut$Json(params?: {
    body?: HandleReportContentInputModel
  }): Observable<ReportResponseModelListResponseModel> {

    return this.inlivewithBackOfficeHandleReportContentPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelListResponseModel>) => r.body as ReportResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeAddCelebrityAliasPost
   */
  static readonly InlivewithBackOfficeAddCelebrityAliasPostPath = '/inlivewith/BackOffice/AddCelebrityAlias';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeAddCelebrityAliasPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeAddCelebrityAliasPost$Plain$Response(params?: {
    body?: CelebrityAliasCreateModel
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeAddCelebrityAliasPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeAddCelebrityAliasPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeAddCelebrityAliasPost$Plain(params?: {
    body?: CelebrityAliasCreateModel
  }): Observable<CelebrityAliasResponseModelResponseModel> {

    return this.inlivewithBackOfficeAddCelebrityAliasPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelResponseModel>) => r.body as CelebrityAliasResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeAddCelebrityAliasPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeAddCelebrityAliasPost$Json$Response(params?: {
    body?: CelebrityAliasCreateModel
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeAddCelebrityAliasPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeAddCelebrityAliasPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeAddCelebrityAliasPost$Json(params?: {
    body?: CelebrityAliasCreateModel
  }): Observable<CelebrityAliasResponseModelResponseModel> {

    return this.inlivewithBackOfficeAddCelebrityAliasPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelResponseModel>) => r.body as CelebrityAliasResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetListCelebrityAliasByStatusGet
   */
  static readonly InlivewithBackOfficeGetListCelebrityAliasByStatusGetPath = '/inlivewith/BackOffice/GetListCelebrityAliasByStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetListCelebrityAliasByStatusGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListCelebrityAliasByStatusGet$Plain$Response(params?: {
    status?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasResponseWithClubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetListCelebrityAliasByStatusGetPath, 'get');
    if (params) {
      rb.query('status', params.status, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseWithClubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetListCelebrityAliasByStatusGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListCelebrityAliasByStatusGet$Plain(params?: {
    status?: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasResponseWithClubModelPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetListCelebrityAliasByStatusGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseWithClubModelPaginationModelResponseModel>) => r.body as CelebrityAliasResponseWithClubModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetListCelebrityAliasByStatusGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListCelebrityAliasByStatusGet$Json$Response(params?: {
    status?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasResponseWithClubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetListCelebrityAliasByStatusGetPath, 'get');
    if (params) {
      rb.query('status', params.status, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseWithClubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetListCelebrityAliasByStatusGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListCelebrityAliasByStatusGet$Json(params?: {
    status?: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasResponseWithClubModelPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetListCelebrityAliasByStatusGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseWithClubModelPaginationModelResponseModel>) => r.body as CelebrityAliasResponseWithClubModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetListClubsThatCelebrityHasBeenLinkedGet
   */
  static readonly InlivewithBackOfficeGetListClubsThatCelebrityHasBeenLinkedGetPath = '/inlivewith/BackOffice/GetListClubsThatCelebrityHasBeenLinked';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetListClubsThatCelebrityHasBeenLinkedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListClubsThatCelebrityHasBeenLinkedGet$Plain$Response(params?: {
    name?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasClubsResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetListClubsThatCelebrityHasBeenLinkedGetPath, 'get');
    if (params) {
      rb.query('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasClubsResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetListClubsThatCelebrityHasBeenLinkedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListClubsThatCelebrityHasBeenLinkedGet$Plain(params?: {
    name?: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasClubsResponseModelPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetListClubsThatCelebrityHasBeenLinkedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasClubsResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasClubsResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetListClubsThatCelebrityHasBeenLinkedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListClubsThatCelebrityHasBeenLinkedGet$Json$Response(params?: {
    name?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasClubsResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetListClubsThatCelebrityHasBeenLinkedGetPath, 'get');
    if (params) {
      rb.query('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasClubsResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetListClubsThatCelebrityHasBeenLinkedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListClubsThatCelebrityHasBeenLinkedGet$Json(params?: {
    name?: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasClubsResponseModelPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetListClubsThatCelebrityHasBeenLinkedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasClubsResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasClubsResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetListHashtagByStatusGet
   */
  static readonly InlivewithBackOfficeGetListHashtagByStatusGetPath = '/inlivewith/BackOffice/GetListHashtagByStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetListHashtagByStatusGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListHashtagByStatusGet$Plain$Response(params?: {
    status?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<HashtagResponseForBackOfficeModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetListHashtagByStatusGetPath, 'get');
    if (params) {
      rb.query('status', params.status, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseForBackOfficeModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetListHashtagByStatusGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListHashtagByStatusGet$Plain(params?: {
    status?: string;
    page?: number;
    limit?: number;
  }): Observable<HashtagResponseForBackOfficeModelPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetListHashtagByStatusGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseForBackOfficeModelPaginationModelResponseModel>) => r.body as HashtagResponseForBackOfficeModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetListHashtagByStatusGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListHashtagByStatusGet$Json$Response(params?: {
    status?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<HashtagResponseForBackOfficeModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetListHashtagByStatusGetPath, 'get');
    if (params) {
      rb.query('status', params.status, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseForBackOfficeModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetListHashtagByStatusGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListHashtagByStatusGet$Json(params?: {
    status?: string;
    page?: number;
    limit?: number;
  }): Observable<HashtagResponseForBackOfficeModelPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetListHashtagByStatusGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseForBackOfficeModelPaginationModelResponseModel>) => r.body as HashtagResponseForBackOfficeModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeUpdateHashtagStatusByIdsPut
   */
  static readonly InlivewithBackOfficeUpdateHashtagStatusByIdsPutPath = '/inlivewith/BackOffice/UpdateHashtagStatusByIds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeUpdateHashtagStatusByIdsPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeUpdateHashtagStatusByIdsPut$Plain$Response(params?: {
    body?: UpdateHashtagInputModel
  }): Observable<StrictHttpResponse<HashtagResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeUpdateHashtagStatusByIdsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeUpdateHashtagStatusByIdsPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeUpdateHashtagStatusByIdsPut$Plain(params?: {
    body?: UpdateHashtagInputModel
  }): Observable<HashtagResponseModelListResponseModel> {

    return this.inlivewithBackOfficeUpdateHashtagStatusByIdsPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelListResponseModel>) => r.body as HashtagResponseModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeUpdateHashtagStatusByIdsPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeUpdateHashtagStatusByIdsPut$Json$Response(params?: {
    body?: UpdateHashtagInputModel
  }): Observable<StrictHttpResponse<HashtagResponseModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeUpdateHashtagStatusByIdsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeUpdateHashtagStatusByIdsPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeUpdateHashtagStatusByIdsPut$Json(params?: {
    body?: UpdateHashtagInputModel
  }): Observable<HashtagResponseModelListResponseModel> {

    return this.inlivewithBackOfficeUpdateHashtagStatusByIdsPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelListResponseModel>) => r.body as HashtagResponseModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeSetIsReadNotificationByIdsPut
   */
  static readonly InlivewithBackOfficeSetIsReadNotificationByIdsPutPath = '/inlivewith/BackOffice/SetIsReadNotificationByIds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeSetIsReadNotificationByIdsPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeSetIsReadNotificationByIdsPut$Plain$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<NotificationResponseListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeSetIsReadNotificationByIdsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponseListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeSetIsReadNotificationByIdsPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeSetIsReadNotificationByIdsPut$Plain(params?: {
    body?: Array<string>
  }): Observable<NotificationResponseListResponseModel> {

    return this.inlivewithBackOfficeSetIsReadNotificationByIdsPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponseListResponseModel>) => r.body as NotificationResponseListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeSetIsReadNotificationByIdsPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeSetIsReadNotificationByIdsPut$Json$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<NotificationResponseListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeSetIsReadNotificationByIdsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponseListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeSetIsReadNotificationByIdsPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeSetIsReadNotificationByIdsPut$Json(params?: {
    body?: Array<string>
  }): Observable<NotificationResponseListResponseModel> {

    return this.inlivewithBackOfficeSetIsReadNotificationByIdsPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponseListResponseModel>) => r.body as NotificationResponseListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetListClubByHashtagIdGet
   */
  static readonly InlivewithBackOfficeGetListClubByHashtagIdGetPath = '/inlivewith/BackOffice/GetListClubByHashtagId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetListClubByHashtagIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListClubByHashtagIdGet$Plain$Response(params?: {
    hashtagId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetListClubByHashtagIdGetPath, 'get');
    if (params) {
      rb.query('hashtagId', params.hashtagId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetListClubByHashtagIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListClubByHashtagIdGet$Plain(params?: {
    hashtagId?: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetListClubByHashtagIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetListClubByHashtagIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListClubByHashtagIdGet$Json$Response(params?: {
    hashtagId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetListClubByHashtagIdGetPath, 'get');
    if (params) {
      rb.query('hashtagId', params.hashtagId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetListClubByHashtagIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListClubByHashtagIdGet$Json(params?: {
    hashtagId?: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetListClubByHashtagIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetListAccountByHashtagIdGet
   */
  static readonly InlivewithBackOfficeGetListAccountByHashtagIdGetPath = '/inlivewith/BackOffice/GetListAccountByHashtagId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetListAccountByHashtagIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListAccountByHashtagIdGet$Plain$Response(params?: {
    hashtagId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseForHashtagModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetListAccountByHashtagIdGetPath, 'get');
    if (params) {
      rb.query('hashtagId', params.hashtagId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseForHashtagModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetListAccountByHashtagIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListAccountByHashtagIdGet$Plain(params?: {
    hashtagId?: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseForHashtagModelPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetListAccountByHashtagIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseForHashtagModelPaginationModelResponseModel>) => r.body as AccountResponseForHashtagModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetListAccountByHashtagIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListAccountByHashtagIdGet$Json$Response(params?: {
    hashtagId?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseForHashtagModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetListAccountByHashtagIdGetPath, 'get');
    if (params) {
      rb.query('hashtagId', params.hashtagId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseForHashtagModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetListAccountByHashtagIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetListAccountByHashtagIdGet$Json(params?: {
    hashtagId?: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseForHashtagModelPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetListAccountByHashtagIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseForHashtagModelPaginationModelResponseModel>) => r.body as AccountResponseForHashtagModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetNotificationForBoGet
   */
  static readonly InlivewithBackOfficeGetNotificationForBoGetPath = '/inlivewith/BackOffice/GetNotificationForBO';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetNotificationForBoGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetNotificationForBoGet$Plain$Response(params?: {
    isRead?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<NotificationResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetNotificationForBoGetPath, 'get');
    if (params) {
      rb.query('isRead', params.isRead, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetNotificationForBoGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetNotificationForBoGet$Plain(params?: {
    isRead?: boolean;
    page?: number;
    limit?: number;
  }): Observable<NotificationResponsePaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetNotificationForBoGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponsePaginationModelResponseModel>) => r.body as NotificationResponsePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetNotificationForBoGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetNotificationForBoGet$Json$Response(params?: {
    isRead?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<NotificationResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetNotificationForBoGetPath, 'get');
    if (params) {
      rb.query('isRead', params.isRead, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetNotificationForBoGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetNotificationForBoGet$Json(params?: {
    isRead?: boolean;
    page?: number;
    limit?: number;
  }): Observable<NotificationResponsePaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetNotificationForBoGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationResponsePaginationModelResponseModel>) => r.body as NotificationResponsePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetPostByAccountIdAndClubIdGet
   */
  static readonly InlivewithBackOfficeGetPostByAccountIdAndClubIdGetPath = '/inlivewith/BackOffice/GetPostByAccountIdAndClubId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetPostByAccountIdAndClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetPostByAccountIdAndClubIdGet$Plain$Response(params?: {
    accountId?: string;
    clubId?: string;
    type?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetPostByAccountIdAndClubIdGetPath, 'get');
    if (params) {
      rb.query('accountId', params.accountId, {});
      rb.query('clubId', params.clubId, {});
      rb.query('type', params.type, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetPostByAccountIdAndClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetPostByAccountIdAndClubIdGet$Plain(params?: {
    accountId?: string;
    clubId?: string;
    type?: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetPostByAccountIdAndClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetPostByAccountIdAndClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetPostByAccountIdAndClubIdGet$Json$Response(params?: {
    accountId?: string;
    clubId?: string;
    type?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<PostResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetPostByAccountIdAndClubIdGetPath, 'get');
    if (params) {
      rb.query('accountId', params.accountId, {});
      rb.query('clubId', params.clubId, {});
      rb.query('type', params.type, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetPostByAccountIdAndClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetPostByAccountIdAndClubIdGet$Json(params?: {
    accountId?: string;
    clubId?: string;
    type?: string;
    page?: number;
    limit?: number;
  }): Observable<PostResponseModelPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetPostByAccountIdAndClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PostResponseModelPaginationModelResponseModel>) => r.body as PostResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetAllMemberWithAnalyzeByClubIdPost
   */
  static readonly InlivewithBackOfficeGetAllMemberWithAnalyzeByClubIdPostPath = '/inlivewith/BackOffice/GetAllMemberWithAnalyzeByClubId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllMemberWithAnalyzeByClubIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllMemberWithAnalyzeByClubIdPost$Plain$Response(params?: {
    body?: AccountWithRoleAndTotalAnalyzeFilter
  }): Observable<StrictHttpResponse<AccountResponseWithRoleAndTotalAnalyzePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllMemberWithAnalyzeByClubIdPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleAndTotalAnalyzePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllMemberWithAnalyzeByClubIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllMemberWithAnalyzeByClubIdPost$Plain(params?: {
    body?: AccountWithRoleAndTotalAnalyzeFilter
  }): Observable<AccountResponseWithRoleAndTotalAnalyzePaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllMemberWithAnalyzeByClubIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleAndTotalAnalyzePaginationModelResponseModel>) => r.body as AccountResponseWithRoleAndTotalAnalyzePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAllMemberWithAnalyzeByClubIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllMemberWithAnalyzeByClubIdPost$Json$Response(params?: {
    body?: AccountWithRoleAndTotalAnalyzeFilter
  }): Observable<StrictHttpResponse<AccountResponseWithRoleAndTotalAnalyzePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAllMemberWithAnalyzeByClubIdPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleAndTotalAnalyzePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAllMemberWithAnalyzeByClubIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetAllMemberWithAnalyzeByClubIdPost$Json(params?: {
    body?: AccountWithRoleAndTotalAnalyzeFilter
  }): Observable<AccountResponseWithRoleAndTotalAnalyzePaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetAllMemberWithAnalyzeByClubIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleAndTotalAnalyzePaginationModelResponseModel>) => r.body as AccountResponseWithRoleAndTotalAnalyzePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeUnBlockAccountPut
   */
  static readonly InlivewithBackOfficeUnBlockAccountPutPath = '/inlivewith/BackOffice/UnBlockAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeUnBlockAccountPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeUnBlockAccountPut$Plain$Response(params?: {
    accountId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeUnBlockAccountPutPath, 'put');
    if (params) {
      rb.query('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeUnBlockAccountPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeUnBlockAccountPut$Plain(params?: {
    accountId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeUnBlockAccountPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeUnBlockAccountPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeUnBlockAccountPut$Json$Response(params?: {
    accountId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeUnBlockAccountPutPath, 'put');
    if (params) {
      rb.query('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeUnBlockAccountPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeUnBlockAccountPut$Json(params?: {
    accountId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeUnBlockAccountPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeUnBlockClubPut
   */
  static readonly InlivewithBackOfficeUnBlockClubPutPath = '/inlivewith/BackOffice/UnBlockClub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeUnBlockClubPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeUnBlockClubPut$Plain$Response(params?: {
    clubId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeUnBlockClubPutPath, 'put');
    if (params) {
      rb.query('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeUnBlockClubPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeUnBlockClubPut$Plain(params?: {
    clubId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeUnBlockClubPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeUnBlockClubPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeUnBlockClubPut$Json$Response(params?: {
    clubId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeUnBlockClubPutPath, 'put');
    if (params) {
      rb.query('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeUnBlockClubPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeUnBlockClubPut$Json(params?: {
    clubId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeUnBlockClubPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeCheckDateTimeAndReasonAccountBlockedGet
   */
  static readonly InlivewithBackOfficeCheckDateTimeAndReasonAccountBlockedGetPath = '/inlivewith/BackOffice/CheckDateTimeAndReasonAccountBlocked';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeCheckDateTimeAndReasonAccountBlockedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeCheckDateTimeAndReasonAccountBlockedGet$Plain$Response(params?: {
    accountId?: string;
  }): Observable<StrictHttpResponse<ReportReponseWithDateTimeAndReasonResultResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeCheckDateTimeAndReasonAccountBlockedGetPath, 'get');
    if (params) {
      rb.query('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportReponseWithDateTimeAndReasonResultResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeCheckDateTimeAndReasonAccountBlockedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeCheckDateTimeAndReasonAccountBlockedGet$Plain(params?: {
    accountId?: string;
  }): Observable<ReportReponseWithDateTimeAndReasonResultResponseModel> {

    return this.inlivewithBackOfficeCheckDateTimeAndReasonAccountBlockedGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportReponseWithDateTimeAndReasonResultResponseModel>) => r.body as ReportReponseWithDateTimeAndReasonResultResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeCheckDateTimeAndReasonAccountBlockedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeCheckDateTimeAndReasonAccountBlockedGet$Json$Response(params?: {
    accountId?: string;
  }): Observable<StrictHttpResponse<ReportReponseWithDateTimeAndReasonResultResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeCheckDateTimeAndReasonAccountBlockedGetPath, 'get');
    if (params) {
      rb.query('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportReponseWithDateTimeAndReasonResultResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeCheckDateTimeAndReasonAccountBlockedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeCheckDateTimeAndReasonAccountBlockedGet$Json(params?: {
    accountId?: string;
  }): Observable<ReportReponseWithDateTimeAndReasonResultResponseModel> {

    return this.inlivewithBackOfficeCheckDateTimeAndReasonAccountBlockedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportReponseWithDateTimeAndReasonResultResponseModel>) => r.body as ReportReponseWithDateTimeAndReasonResultResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeVerifyVerifyPost
   */
  static readonly InlivewithBackOfficeVerifyVerifyPostPath = '/inlivewith/BackOffice/Verify/verify';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeVerifyVerifyPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeVerifyVerifyPost$Plain$Response(params?: {
    code?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeVerifyVerifyPostPath, 'post');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeVerifyVerifyPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeVerifyVerifyPost$Plain(params?: {
    code?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeVerifyVerifyPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeVerifyVerifyPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeVerifyVerifyPost$Json$Response(params?: {
    code?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeVerifyVerifyPostPath, 'post');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeVerifyVerifyPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeVerifyVerifyPost$Json(params?: {
    code?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeVerifyVerifyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetCategoryWithClubPost
   */
  static readonly InlivewithBackOfficeGetCategoryWithClubPostPath = '/inlivewith/BackOffice/GetCategoryWithClub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetCategoryWithClubPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetCategoryWithClubPost$Plain$Response(params?: {
    body?: DateFilter
  }): Observable<StrictHttpResponse<CategoryWithClubListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetCategoryWithClubPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CategoryWithClubListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetCategoryWithClubPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetCategoryWithClubPost$Plain(params?: {
    body?: DateFilter
  }): Observable<CategoryWithClubListResponseModel> {

    return this.inlivewithBackOfficeGetCategoryWithClubPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CategoryWithClubListResponseModel>) => r.body as CategoryWithClubListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetCategoryWithClubPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetCategoryWithClubPost$Json$Response(params?: {
    body?: DateFilter
  }): Observable<StrictHttpResponse<CategoryWithClubListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetCategoryWithClubPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CategoryWithClubListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetCategoryWithClubPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetCategoryWithClubPost$Json(params?: {
    body?: DateFilter
  }): Observable<CategoryWithClubListResponseModel> {

    return this.inlivewithBackOfficeGetCategoryWithClubPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CategoryWithClubListResponseModel>) => r.body as CategoryWithClubListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetDashBoardInfomationPost
   */
  static readonly InlivewithBackOfficeGetDashBoardInfomationPostPath = '/inlivewith/BackOffice/GetDashBoardInfomation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetDashBoardInfomationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetDashBoardInfomationPost$Plain$Response(params?: {
    body?: DateFilter
  }): Observable<StrictHttpResponse<DashBoardInfomationResponseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetDashBoardInfomationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DashBoardInfomationResponseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetDashBoardInfomationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetDashBoardInfomationPost$Plain(params?: {
    body?: DateFilter
  }): Observable<DashBoardInfomationResponseResponseModel> {

    return this.inlivewithBackOfficeGetDashBoardInfomationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DashBoardInfomationResponseResponseModel>) => r.body as DashBoardInfomationResponseResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetDashBoardInfomationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetDashBoardInfomationPost$Json$Response(params?: {
    body?: DateFilter
  }): Observable<StrictHttpResponse<DashBoardInfomationResponseResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetDashBoardInfomationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DashBoardInfomationResponseResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetDashBoardInfomationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetDashBoardInfomationPost$Json(params?: {
    body?: DateFilter
  }): Observable<DashBoardInfomationResponseResponseModel> {

    return this.inlivewithBackOfficeGetDashBoardInfomationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DashBoardInfomationResponseResponseModel>) => r.body as DashBoardInfomationResponseResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetTopClubForDashboardPost
   */
  static readonly InlivewithBackOfficeGetTopClubForDashboardPostPath = '/inlivewith/BackOffice/GetTopClubForDashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetTopClubForDashboardPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetTopClubForDashboardPost$Plain$Response(params?: {
    page?: number;
    limit?: number;
    body?: DateFilter
  }): Observable<StrictHttpResponse<TopClubForDashboardResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetTopClubForDashboardPostPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TopClubForDashboardResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetTopClubForDashboardPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetTopClubForDashboardPost$Plain(params?: {
    page?: number;
    limit?: number;
    body?: DateFilter
  }): Observable<TopClubForDashboardResponsePaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetTopClubForDashboardPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TopClubForDashboardResponsePaginationModelResponseModel>) => r.body as TopClubForDashboardResponsePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetTopClubForDashboardPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetTopClubForDashboardPost$Json$Response(params?: {
    page?: number;
    limit?: number;
    body?: DateFilter
  }): Observable<StrictHttpResponse<TopClubForDashboardResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetTopClubForDashboardPostPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TopClubForDashboardResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetTopClubForDashboardPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetTopClubForDashboardPost$Json(params?: {
    page?: number;
    limit?: number;
    body?: DateFilter
  }): Observable<TopClubForDashboardResponsePaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetTopClubForDashboardPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TopClubForDashboardResponsePaginationModelResponseModel>) => r.body as TopClubForDashboardResponsePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetAccountByIdWithoutFilterAccountIdGet
   */
  static readonly InlivewithBackOfficeGetAccountByIdWithoutFilterAccountIdGetPath = '/inlivewith/BackOffice/GetAccountByIdWithoutFilter/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAccountByIdWithoutFilterAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetAccountByIdWithoutFilterAccountIdGet$Plain$Response(params: {
    accountId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAccountByIdWithoutFilterAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAccountByIdWithoutFilterAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetAccountByIdWithoutFilterAccountIdGet$Plain(params: {
    accountId: string;
    details?: boolean;
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithBackOfficeGetAccountByIdWithoutFilterAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetAccountByIdWithoutFilterAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetAccountByIdWithoutFilterAccountIdGet$Json$Response(params: {
    accountId: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetAccountByIdWithoutFilterAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetAccountByIdWithoutFilterAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetAccountByIdWithoutFilterAccountIdGet$Json(params: {
    accountId: string;
    details?: boolean;
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithBackOfficeGetAccountByIdWithoutFilterAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeLinkAccountCelebToCelebrityAliasIdentityUserIdCelebrityAliasIdPut
   */
  static readonly InlivewithBackOfficeLinkAccountCelebToCelebrityAliasIdentityUserIdCelebrityAliasIdPutPath = '/inlivewith/BackOffice/LinkAccountCelebToCelebrityAlias/{identityUserId}/{celebrityAliasId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeLinkAccountCelebToCelebrityAliasIdentityUserIdCelebrityAliasIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeLinkAccountCelebToCelebrityAliasIdentityUserIdCelebrityAliasIdPut$Plain$Response(params: {
    identityUserId: string;
    celebrityAliasId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeLinkAccountCelebToCelebrityAliasIdentityUserIdCelebrityAliasIdPutPath, 'put');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.path('celebrityAliasId', params.celebrityAliasId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeLinkAccountCelebToCelebrityAliasIdentityUserIdCelebrityAliasIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeLinkAccountCelebToCelebrityAliasIdentityUserIdCelebrityAliasIdPut$Plain(params: {
    identityUserId: string;
    celebrityAliasId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeLinkAccountCelebToCelebrityAliasIdentityUserIdCelebrityAliasIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeLinkAccountCelebToCelebrityAliasIdentityUserIdCelebrityAliasIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeLinkAccountCelebToCelebrityAliasIdentityUserIdCelebrityAliasIdPut$Json$Response(params: {
    identityUserId: string;
    celebrityAliasId: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeLinkAccountCelebToCelebrityAliasIdentityUserIdCelebrityAliasIdPutPath, 'put');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.path('celebrityAliasId', params.celebrityAliasId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeLinkAccountCelebToCelebrityAliasIdentityUserIdCelebrityAliasIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeLinkAccountCelebToCelebrityAliasIdentityUserIdCelebrityAliasIdPut$Json(params: {
    identityUserId: string;
    celebrityAliasId: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeLinkAccountCelebToCelebrityAliasIdentityUserIdCelebrityAliasIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeHandleNewCelebrityAliasCelebrityAliasIdStatusPut
   */
  static readonly InlivewithBackOfficeHandleNewCelebrityAliasCelebrityAliasIdStatusPutPath = '/inlivewith/BackOffice/HandleNewCelebrityAlias/{celebrityAliasId}/{status}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeHandleNewCelebrityAliasCelebrityAliasIdStatusPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeHandleNewCelebrityAliasCelebrityAliasIdStatusPut$Plain$Response(params: {
    celebrityAliasId: string;
    status: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeHandleNewCelebrityAliasCelebrityAliasIdStatusPutPath, 'put');
    if (params) {
      rb.path('celebrityAliasId', params.celebrityAliasId, {});
      rb.path('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeHandleNewCelebrityAliasCelebrityAliasIdStatusPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeHandleNewCelebrityAliasCelebrityAliasIdStatusPut$Plain(params: {
    celebrityAliasId: string;
    status: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeHandleNewCelebrityAliasCelebrityAliasIdStatusPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeHandleNewCelebrityAliasCelebrityAliasIdStatusPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeHandleNewCelebrityAliasCelebrityAliasIdStatusPut$Json$Response(params: {
    celebrityAliasId: string;
    status: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeHandleNewCelebrityAliasCelebrityAliasIdStatusPutPath, 'put');
    if (params) {
      rb.path('celebrityAliasId', params.celebrityAliasId, {});
      rb.path('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeHandleNewCelebrityAliasCelebrityAliasIdStatusPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeHandleNewCelebrityAliasCelebrityAliasIdStatusPut$Json(params: {
    celebrityAliasId: string;
    status: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeHandleNewCelebrityAliasCelebrityAliasIdStatusPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeHandelSuggestionCelebritiesClubClubIdPut
   */
  static readonly InlivewithBackOfficeHandelSuggestionCelebritiesClubClubIdPutPath = '/inlivewith/BackOffice/HandelSuggestionCelebritiesClub/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeHandelSuggestionCelebritiesClubClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeHandelSuggestionCelebritiesClubClubIdPut$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeHandelSuggestionCelebritiesClubClubIdPutPath, 'put');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeHandelSuggestionCelebritiesClubClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeHandelSuggestionCelebritiesClubClubIdPut$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<CelebrityAliasResponseModelPaginationModelResponseModel> {

    return this.inlivewithBackOfficeHandelSuggestionCelebritiesClubClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeHandelSuggestionCelebritiesClubClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeHandelSuggestionCelebritiesClubClubIdPut$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeHandelSuggestionCelebritiesClubClubIdPutPath, 'put');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeHandelSuggestionCelebritiesClubClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeHandelSuggestionCelebritiesClubClubIdPut$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<CelebrityAliasResponseModelPaginationModelResponseModel> {

    return this.inlivewithBackOfficeHandelSuggestionCelebritiesClubClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeEditCelebrityAliasNameNameClubIdPut
   */
  static readonly InlivewithBackOfficeEditCelebrityAliasNameNameClubIdPutPath = '/inlivewith/BackOffice/EditCelebrityAliasName/{name}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeEditCelebrityAliasNameNameClubIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeEditCelebrityAliasNameNameClubIdPut$Plain$Response(params: {
    name: string;
    clubId: string;
    celebrityAliasId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeEditCelebrityAliasNameNameClubIdPutPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.path('clubId', params.clubId, {});
      rb.query('celebrityAliasId', params.celebrityAliasId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeEditCelebrityAliasNameNameClubIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeEditCelebrityAliasNameNameClubIdPut$Plain(params: {
    name: string;
    clubId: string;
    celebrityAliasId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeEditCelebrityAliasNameNameClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeEditCelebrityAliasNameNameClubIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeEditCelebrityAliasNameNameClubIdPut$Json$Response(params: {
    name: string;
    clubId: string;
    celebrityAliasId?: string;
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeEditCelebrityAliasNameNameClubIdPutPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.path('clubId', params.clubId, {});
      rb.query('celebrityAliasId', params.celebrityAliasId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeEditCelebrityAliasNameNameClubIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeEditCelebrityAliasNameNameClubIdPut$Json(params: {
    name: string;
    clubId: string;
    celebrityAliasId?: string;
  }): Observable<BooleanResponseModel> {

    return this.inlivewithBackOfficeEditCelebrityAliasNameNameClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeUnlinkCelebrityAliasWithClubClubIdCelebrityAliasIdPut
   */
  static readonly InlivewithBackOfficeUnlinkCelebrityAliasWithClubClubIdCelebrityAliasIdPutPath = '/inlivewith/BackOffice/UnlinkCelebrityAliasWithClub/{clubId}/{celebrityAliasId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeUnlinkCelebrityAliasWithClubClubIdCelebrityAliasIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeUnlinkCelebrityAliasWithClubClubIdCelebrityAliasIdPut$Plain$Response(params: {
    clubId: string;
    celebrityAliasId: string;
  }): Observable<StrictHttpResponse<Int32ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeUnlinkCelebrityAliasWithClubClubIdCelebrityAliasIdPutPath, 'put');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('celebrityAliasId', params.celebrityAliasId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeUnlinkCelebrityAliasWithClubClubIdCelebrityAliasIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeUnlinkCelebrityAliasWithClubClubIdCelebrityAliasIdPut$Plain(params: {
    clubId: string;
    celebrityAliasId: string;
  }): Observable<Int32ResponseModel> {

    return this.inlivewithBackOfficeUnlinkCelebrityAliasWithClubClubIdCelebrityAliasIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ResponseModel>) => r.body as Int32ResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeUnlinkCelebrityAliasWithClubClubIdCelebrityAliasIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeUnlinkCelebrityAliasWithClubClubIdCelebrityAliasIdPut$Json$Response(params: {
    clubId: string;
    celebrityAliasId: string;
  }): Observable<StrictHttpResponse<Int32ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeUnlinkCelebrityAliasWithClubClubIdCelebrityAliasIdPutPath, 'put');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('celebrityAliasId', params.celebrityAliasId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeUnlinkCelebrityAliasWithClubClubIdCelebrityAliasIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeUnlinkCelebrityAliasWithClubClubIdCelebrityAliasIdPut$Json(params: {
    clubId: string;
    celebrityAliasId: string;
  }): Observable<Int32ResponseModel> {

    return this.inlivewithBackOfficeUnlinkCelebrityAliasWithClubClubIdCelebrityAliasIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ResponseModel>) => r.body as Int32ResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetClubWithFilterPost
   */
  static readonly InlivewithBackOfficeGetClubWithFilterPostPath = '/inlivewith/BackOffice/GetClubWithFilter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetClubWithFilterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetClubWithFilterPost$Plain$Response(params?: {
    body?: ClubFilter
  }): Observable<StrictHttpResponse<ClubFilterModelResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetClubWithFilterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubFilterModelResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetClubWithFilterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetClubWithFilterPost$Plain(params?: {
    body?: ClubFilter
  }): Observable<ClubFilterModelResponsePaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetClubWithFilterPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubFilterModelResponsePaginationModelResponseModel>) => r.body as ClubFilterModelResponsePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetClubWithFilterPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetClubWithFilterPost$Json$Response(params?: {
    body?: ClubFilter
  }): Observable<StrictHttpResponse<ClubFilterModelResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetClubWithFilterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubFilterModelResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetClubWithFilterPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetClubWithFilterPost$Json(params?: {
    body?: ClubFilter
  }): Observable<ClubFilterModelResponsePaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetClubWithFilterPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubFilterModelResponsePaginationModelResponseModel>) => r.body as ClubFilterModelResponsePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetClubForCreatePostPost
   */
  static readonly InlivewithBackOfficeGetClubForCreatePostPostPath = '/inlivewith/BackOffice/GetClubForCreatePost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetClubForCreatePostPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetClubForCreatePostPost$Plain$Response(params?: {
    body?: ClubFilter
  }): Observable<StrictHttpResponse<ClubFilterForCreatePostResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetClubForCreatePostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubFilterForCreatePostResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetClubForCreatePostPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetClubForCreatePostPost$Plain(params?: {
    body?: ClubFilter
  }): Observable<ClubFilterForCreatePostResponsePaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetClubForCreatePostPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubFilterForCreatePostResponsePaginationModelResponseModel>) => r.body as ClubFilterForCreatePostResponsePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetClubForCreatePostPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetClubForCreatePostPost$Json$Response(params?: {
    body?: ClubFilter
  }): Observable<StrictHttpResponse<ClubFilterForCreatePostResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetClubForCreatePostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubFilterForCreatePostResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetClubForCreatePostPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetClubForCreatePostPost$Json(params?: {
    body?: ClubFilter
  }): Observable<ClubFilterForCreatePostResponsePaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetClubForCreatePostPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubFilterForCreatePostResponsePaginationModelResponseModel>) => r.body as ClubFilterForCreatePostResponsePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeCreateReportWarnClubPost
   */
  static readonly InlivewithBackOfficeCreateReportWarnClubPostPath = '/inlivewith/BackOffice/CreateReportWarnClub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeCreateReportWarnClubPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeCreateReportWarnClubPost$Plain$Response(params?: {
    body?: ReportWarnClubCreateModel
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeCreateReportWarnClubPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeCreateReportWarnClubPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeCreateReportWarnClubPost$Plain(params?: {
    body?: ReportWarnClubCreateModel
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithBackOfficeCreateReportWarnClubPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeCreateReportWarnClubPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeCreateReportWarnClubPost$Json$Response(params?: {
    body?: ReportWarnClubCreateModel
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeCreateReportWarnClubPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeCreateReportWarnClubPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeCreateReportWarnClubPost$Json(params?: {
    body?: ReportWarnClubCreateModel
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithBackOfficeCreateReportWarnClubPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeCreateReportWarnAccountPost
   */
  static readonly InlivewithBackOfficeCreateReportWarnAccountPostPath = '/inlivewith/BackOffice/CreateReportWarnAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeCreateReportWarnAccountPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeCreateReportWarnAccountPost$Plain$Response(params?: {
    body?: ReportWarnAccountCreateModel
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeCreateReportWarnAccountPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeCreateReportWarnAccountPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeCreateReportWarnAccountPost$Plain(params?: {
    body?: ReportWarnAccountCreateModel
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithBackOfficeCreateReportWarnAccountPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeCreateReportWarnAccountPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeCreateReportWarnAccountPost$Json$Response(params?: {
    body?: ReportWarnAccountCreateModel
  }): Observable<StrictHttpResponse<ReportResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeCreateReportWarnAccountPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeCreateReportWarnAccountPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeCreateReportWarnAccountPost$Json(params?: {
    body?: ReportWarnAccountCreateModel
  }): Observable<ReportResponseModelResponseModel> {

    return this.inlivewithBackOfficeCreateReportWarnAccountPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportResponseModelResponseModel>) => r.body as ReportResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeExportClubWithFilterToCsvPost
   */
  static readonly InlivewithBackOfficeExportClubWithFilterToCsvPostPath = '/inlivewith/BackOffice/ExportClubWithFilterToCSV';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeExportClubWithFilterToCsvPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeExportClubWithFilterToCsvPost$Plain$Response(params?: {
    body?: ExportClubWithFilter
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeExportClubWithFilterToCsvPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeExportClubWithFilterToCsvPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeExportClubWithFilterToCsvPost$Plain(params?: {
    body?: ExportClubWithFilter
  }): Observable<StringResponseModel> {

    return this.inlivewithBackOfficeExportClubWithFilterToCsvPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeExportClubWithFilterToCsvPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeExportClubWithFilterToCsvPost$Json$Response(params?: {
    body?: ExportClubWithFilter
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeExportClubWithFilterToCsvPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeExportClubWithFilterToCsvPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeExportClubWithFilterToCsvPost$Json(params?: {
    body?: ExportClubWithFilter
  }): Observable<StringResponseModel> {

    return this.inlivewithBackOfficeExportClubWithFilterToCsvPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeExportMemberClubWithFilterToCsvClubIdPost
   */
  static readonly InlivewithBackOfficeExportMemberClubWithFilterToCsvClubIdPostPath = '/inlivewith/BackOffice/ExportMemberClubWithFilterToCSV/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeExportMemberClubWithFilterToCsvClubIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeExportMemberClubWithFilterToCsvClubIdPost$Plain$Response(params: {
    clubId: string;
    body?: ExportMemberClub
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeExportMemberClubWithFilterToCsvClubIdPostPath, 'post');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeExportMemberClubWithFilterToCsvClubIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeExportMemberClubWithFilterToCsvClubIdPost$Plain(params: {
    clubId: string;
    body?: ExportMemberClub
  }): Observable<StringResponseModel> {

    return this.inlivewithBackOfficeExportMemberClubWithFilterToCsvClubIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeExportMemberClubWithFilterToCsvClubIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeExportMemberClubWithFilterToCsvClubIdPost$Json$Response(params: {
    clubId: string;
    body?: ExportMemberClub
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeExportMemberClubWithFilterToCsvClubIdPostPath, 'post');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeExportMemberClubWithFilterToCsvClubIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeExportMemberClubWithFilterToCsvClubIdPost$Json(params: {
    clubId: string;
    body?: ExportMemberClub
  }): Observable<StringResponseModel> {

    return this.inlivewithBackOfficeExportMemberClubWithFilterToCsvClubIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeExportCelebrityAliasWithFilterToCsvPost
   */
  static readonly InlivewithBackOfficeExportCelebrityAliasWithFilterToCsvPostPath = '/inlivewith/BackOffice/ExportCelebrityAliasWithFilterToCSV';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeExportCelebrityAliasWithFilterToCsvPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeExportCelebrityAliasWithFilterToCsvPost$Plain$Response(params?: {
    body?: ExportCelebrityAliasFilter
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeExportCelebrityAliasWithFilterToCsvPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeExportCelebrityAliasWithFilterToCsvPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeExportCelebrityAliasWithFilterToCsvPost$Plain(params?: {
    body?: ExportCelebrityAliasFilter
  }): Observable<StringResponseModel> {

    return this.inlivewithBackOfficeExportCelebrityAliasWithFilterToCsvPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeExportCelebrityAliasWithFilterToCsvPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeExportCelebrityAliasWithFilterToCsvPost$Json$Response(params?: {
    body?: ExportCelebrityAliasFilter
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeExportCelebrityAliasWithFilterToCsvPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeExportCelebrityAliasWithFilterToCsvPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeExportCelebrityAliasWithFilterToCsvPost$Json(params?: {
    body?: ExportCelebrityAliasFilter
  }): Observable<StringResponseModel> {

    return this.inlivewithBackOfficeExportCelebrityAliasWithFilterToCsvPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetTopCelebrityAliasGet
   */
  static readonly InlivewithBackOfficeGetTopCelebrityAliasGetPath = '/inlivewith/BackOffice/GetTopCelebrityAlias';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetTopCelebrityAliasGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetTopCelebrityAliasGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<TopCelebrityOnDashboardResponeModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetTopCelebrityAliasGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TopCelebrityOnDashboardResponeModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetTopCelebrityAliasGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetTopCelebrityAliasGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<TopCelebrityOnDashboardResponeModelPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetTopCelebrityAliasGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TopCelebrityOnDashboardResponeModelPaginationModelResponseModel>) => r.body as TopCelebrityOnDashboardResponeModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetTopCelebrityAliasGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetTopCelebrityAliasGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<TopCelebrityOnDashboardResponeModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetTopCelebrityAliasGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TopCelebrityOnDashboardResponeModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetTopCelebrityAliasGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeGetTopCelebrityAliasGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<TopCelebrityOnDashboardResponeModelPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetTopCelebrityAliasGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TopCelebrityOnDashboardResponeModelPaginationModelResponseModel>) => r.body as TopCelebrityOnDashboardResponeModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeTopInliversOnDashboardGet
   */
  static readonly InlivewithBackOfficeTopInliversOnDashboardGetPath = '/inlivewith/BackOffice/TopInliversOnDashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeTopInliversOnDashboardGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeTopInliversOnDashboardGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<TopInliversOnDashboardPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeTopInliversOnDashboardGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TopInliversOnDashboardPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeTopInliversOnDashboardGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeTopInliversOnDashboardGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<TopInliversOnDashboardPaginationModelResponseModel> {

    return this.inlivewithBackOfficeTopInliversOnDashboardGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TopInliversOnDashboardPaginationModelResponseModel>) => r.body as TopInliversOnDashboardPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeTopInliversOnDashboardGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeTopInliversOnDashboardGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<TopInliversOnDashboardPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeTopInliversOnDashboardGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TopInliversOnDashboardPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeTopInliversOnDashboardGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeTopInliversOnDashboardGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<TopInliversOnDashboardPaginationModelResponseModel> {

    return this.inlivewithBackOfficeTopInliversOnDashboardGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TopInliversOnDashboardPaginationModelResponseModel>) => r.body as TopInliversOnDashboardPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeTypeClubInformationForDashboardGet
   */
  static readonly InlivewithBackOfficeTypeClubInformationForDashboardGetPath = '/inlivewith/BackOffice/TypeClubInformationForDashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeTypeClubInformationForDashboardGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeTypeClubInformationForDashboardGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<TypeClubInFormationResponeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeTypeClubInformationForDashboardGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TypeClubInFormationResponeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeTypeClubInformationForDashboardGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeTypeClubInformationForDashboardGet$Plain(params?: {
  }): Observable<TypeClubInFormationResponeResponseModel> {

    return this.inlivewithBackOfficeTypeClubInformationForDashboardGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TypeClubInFormationResponeResponseModel>) => r.body as TypeClubInFormationResponeResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeTypeClubInformationForDashboardGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeTypeClubInformationForDashboardGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<TypeClubInFormationResponeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeTypeClubInformationForDashboardGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TypeClubInFormationResponeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeTypeClubInformationForDashboardGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeTypeClubInformationForDashboardGet$Json(params?: {
  }): Observable<TypeClubInFormationResponeResponseModel> {

    return this.inlivewithBackOfficeTypeClubInformationForDashboardGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TypeClubInFormationResponeResponseModel>) => r.body as TypeClubInFormationResponeResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeHashtagInFormationForDashboardGet
   */
  static readonly InlivewithBackOfficeHashtagInFormationForDashboardGetPath = '/inlivewith/BackOffice/HashtagInFormationForDashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeHashtagInFormationForDashboardGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeHashtagInFormationForDashboardGet$Plain$Response(params?: {
    hashtagClub?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<HashtagInFormationResponePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeHashtagInFormationForDashboardGetPath, 'get');
    if (params) {
      rb.query('hashtagClub', params.hashtagClub, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagInFormationResponePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeHashtagInFormationForDashboardGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeHashtagInFormationForDashboardGet$Plain(params?: {
    hashtagClub?: boolean;
    page?: number;
    limit?: number;
  }): Observable<HashtagInFormationResponePaginationModelResponseModel> {

    return this.inlivewithBackOfficeHashtagInFormationForDashboardGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagInFormationResponePaginationModelResponseModel>) => r.body as HashtagInFormationResponePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeHashtagInFormationForDashboardGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeHashtagInFormationForDashboardGet$Json$Response(params?: {
    hashtagClub?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<HashtagInFormationResponePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeHashtagInFormationForDashboardGetPath, 'get');
    if (params) {
      rb.query('hashtagClub', params.hashtagClub, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagInFormationResponePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeHashtagInFormationForDashboardGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithBackOfficeHashtagInFormationForDashboardGet$Json(params?: {
    hashtagClub?: boolean;
    page?: number;
    limit?: number;
  }): Observable<HashtagInFormationResponePaginationModelResponseModel> {

    return this.inlivewithBackOfficeHashtagInFormationForDashboardGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagInFormationResponePaginationModelResponseModel>) => r.body as HashtagInFormationResponePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithBackOfficeGetMemberByClubPost
   */
  static readonly InlivewithBackOfficeGetMemberByClubPostPath = '/inlivewith/BackOffice/GetMemberByClub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetMemberByClubPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetMemberByClubPost$Plain$Response(params?: {
    keyword?: string;
    postId?: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AccountResponseForMentionPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetMemberByClubPostPath, 'post');
    if (params) {
      rb.query('keyword', params.keyword, {});
      rb.query('postId', params.postId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseForMentionPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetMemberByClubPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetMemberByClubPost$Plain(params?: {
    keyword?: string;
    postId?: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<AccountResponseForMentionPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetMemberByClubPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseForMentionPaginationModelResponseModel>) => r.body as AccountResponseForMentionPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithBackOfficeGetMemberByClubPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetMemberByClubPost$Json$Response(params?: {
    keyword?: string;
    postId?: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AccountResponseForMentionPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, BackOfficeService.InlivewithBackOfficeGetMemberByClubPostPath, 'post');
    if (params) {
      rb.query('keyword', params.keyword, {});
      rb.query('postId', params.postId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseForMentionPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithBackOfficeGetMemberByClubPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithBackOfficeGetMemberByClubPost$Json(params?: {
    keyword?: string;
    postId?: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<AccountResponseForMentionPaginationModelResponseModel> {

    return this.inlivewithBackOfficeGetMemberByClubPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseForMentionPaginationModelResponseModel>) => r.body as AccountResponseForMentionPaginationModelResponseModel)
    );
  }

}
