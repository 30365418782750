import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import {
  CelebrityAliasResponseModel,
  ResultSearchAccountViewModel,
} from 'src/app/core/api/be/models';
import {
  BackOfficeService,
  CelebrityService,
} from 'src/app/core/api/be/services';

@Component({
  selector: 'app-list-celebs',
  templateUrl: './list-celebs.component.html',
  styleUrls: ['./list-celebs.component.scss'],
})
export class ListCelebsComponent implements OnInit {
  @Input() openPopupListCeleb: boolean = false;
  @Input() title: string = this._translatedService.instant('LIST_CLUB_LINKED');
  @Input() titleNextAction: string =
    this._translatedService.instant('CONTINUE');
  @Input() currentCelebIsPicked!: ResultSearchAccountViewModel;
  @Input() celebAlias: string[] = []
  @Input() isPendingFromNewCeleb = false;
  @Input() isLinkedWithAccount = true;

  @Output() openPopupListCelebChange = new EventEmitter();
  @Output() selectedCelebChange = new EventEmitter();

  listCelebs: CelebrityAliasResponseModel[] = [];
  tempCelebs: CelebrityAliasResponseModel[] = [];
  @Input() selectedCelebs: CelebrityAliasResponseModel[] = [];

  searchText: string = '';
  showError = false;

  constructor(
    private _backOfficeService: BackOfficeService,
    private _translatedService: TranslateService,
    private _celebrityService: CelebrityService,
    private _router: Router
  ) { }

  onHide() {
    let payload = {
      selectedCelebs: this.tempCelebs,
      isPicked: false
    }
    this.selectedCelebChange.emit(payload);
    this.openPopupListCelebChange.emit(false);
  }

  ngOnInit(): void {
    this.getListCelebs();
    this.tempCelebs = this.selectedCelebs
  }

  getListCelebs(): void {
    if(this.searchText == ''){
      return;
    }

    this._celebrityService
      .inlivewithCelebritySearchCelebrityAliasKeywordGet$Json({
        keyword: this.searchText ?? "",
        limit: 10000,
        isNotLinkWithAccount: this.isLinkedWithAccount
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            if (this.selectedCelebs.length >= 0 && this.celebAlias.length == 0) {
              this.listCelebs = res.data!.page!.filter(data => {
                return !this.selectedCelebs.some(data2 => {
                  return data.id === data2.id;
                });
              });
            } else {
              this.listCelebs = res.data!.page!.filter(data => {
                return !this.celebAlias.some(data2 => {
                  return data.name === data2;
                });
              });
            }
          }
        })
      )
      .subscribe();
  }

  pickCeleb() {
    if (this.selectedCelebs.length == 0) {
      this.showError = true;
      return;
    }

    let currentCelebIsPicked = this
      .currentCelebIsPicked as ResultSearchAccountViewModel;

    if (this.selectedCelebs.length > 0 && !this.isPendingFromNewCeleb && currentCelebIsPicked) {
      this._backOfficeService
        .inlivewithBackOfficeLinkAccountCelebToCelebrityAliasIdentityUserIdCelebrityAliasIdPut$Json(
          {
            celebrityAliasId: this.selectedCelebs[0].id!,
            identityUserId: currentCelebIsPicked.id!,
          }
        )
        .pipe(
          tap((res) => {
            if (res.res == 0) {
              let payload = {
                selectedCelebs: this.selectedCelebs,
                isPicked: true
              }
              this.selectedCelebChange.emit(payload);
              this.openPopupListCelebChange.emit(false);
            }
          })
        )
        .subscribe();

      return;
    }

    let payload = {
      selectedCelebs: this.selectedCelebs,
      isPicked: true
    }
    this.selectedCelebChange.emit(payload);
    this.openPopupListCelebChange.emit(false);
  }

  handle(celeb: any) {
    if (this.currentCelebIsPicked) {
      if (this.selectedCelebs.length >= 1) {
        return;
      }
      this.handleCeleb(celeb);
    } else {
      if (this.selectedCelebs.length >= 10) {
        return;
      }
      this.handleCeleb(celeb);
    }
  }

  handleCeleb(celeb: any) {
    this.selectedCelebs = this.selectedCelebs.concat(celeb);
    this.showError = false;
    this.searchText = "";
    this.listCelebs = [];
  }

  removeCeleb(celeb: CelebrityAliasResponseModel) {
    this.selectedCelebs = this.selectedCelebs.filter(x => x.id !== celeb.id);
  }
}
