<p-dialog [(visible)]="openPopupListCeleb" [modal]="true" [closable]="true" [draggable]="false" [resizable]="false"
  (onHide)="onHide()">
  <div class="zone-content">
    <div class="text-center fs-20 text-bold">
      {{title | translate}}
    </div>
    <div class="mt-3">
      <span>{{'LIST_CLUB_NAME' | translate}}</span>
      <div class="flex align-items-center search position-relative">
        <div class="w-100 flex flex-column">

          <div class="w-100 flex flex-column">
            <input type="text" pInputText placeholder="Rechercher" [(ngModel)]="searchText"
              [disabled]="celebAlias.length! > 0 || (currentCelebIsPicked && selectedCelebs.length > 0)" (ngModelChange)="getListCelebs()" />
            <ng-container *ngIf="listCelebs.length > 0">
              <div class="zone-result-search">
                <div class="flex flex-column result item-result cursor-pointer">
                  <div *ngFor="let celeb of listCelebs">
                    <div class="flex align-items-center mt-2 p-2" (click)="handle(celeb)">
                      <div class="zone-avatar">
                        <img class="fit-size" src="../../../../assets/images/svgs/avatar-default.svg">
                      </div>
                      <div class="ml-3 w-100">
                        {{celeb.name!}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="showError">
            <span class="text-red mt-2">
              {{'SELECTION_CELEB_REQUIRE' | translate}}
            </span>
          </ng-container>
        </div>
      </div>
      <div class="flex flex-wrap w-100 mt-2" *ngIf="selectedCelebs.length! > 0">
        <div class="flex align-items-center p-1 text-white mr-2 mb-2"
          style="background-color: #D5D7E3; border-radius: 10px;" *ngFor="let celeb of selectedCelebs">
          <div class="zone-avatar">
            <img class="fit-size" src="../../../../assets/images/svgs/avatar-default.svg">
          </div>
          <span class="ml-1 mr-1">
            {{celeb.name}}
          </span>
          <i class="pi pi-times ml-1 cursor-pointer" (click)="removeCeleb(celeb)"></i>
        </div>
      </div>
      <div class="flex flex-wrap w-100 mt-2" *ngIf="celebAlias.length! > 0">
        <div class="flex align-items-center p-1 text-white mr-2 mb-2"
          style="background-color: #D5D7E3; border-radius: 10px;" *ngFor="let celeb of celebAlias">
          <div class="zone-avatar">
            <img class="fit-size" src="../../../../assets/images/svgs/avatar-default.svg">
          </div>
          <span class="ml-1 mr-1">
            {{celeb}}
          </span>
          <i class="pi pi-times ml-1 cursor-pointer" (click)="celebAlias = []"></i>
        </div>
      </div>
    </div>
    <div class="flex justify-content-center mt-5 align-items-center">
      <button pButton type="button" label="{{'CANCEL' | translate}}" (click)="onHide()"
        class="p-button-outlined btn-white p-button-secondary ml-2 mr-2"></button>
      <button pButton type="button" [label]="titleNextAction | translate" (click)="pickCeleb()"
        class="p-button-outlined btn-blue p-button-secondary ml-2"></button>
    </div>
  </div>
</p-dialog>
