<div class="zone-react">
  <div *ngFor="let liker of listReactsPost">
    <div class="flex mb-3 mt-1 align-items-center">
      <div class="avatar">
        <img class="fit-size" [src]="liker.account?.avatar! ? liker.account?.avatar! : defaultAvatar">
      </div>
      <div class=" ml-3 flex align-items-start flex-column">
        <div class="name">
          <span>
            {{liker.account?.nickName! | limitString:false:25}}
          </span>
        </div>
        <div class="name" *ngIf="commentFromPostInliveteam">
          {{liker.club!.name!}}
        </div>
      </div>
    </div>
  </div>
</div>
