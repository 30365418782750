import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ClubResponseWithTotalMemberAndGoInliveModel,
  HashtagResponseModel,
} from 'src/app/core/api/be/models';
import { ClubService, HashtagService } from 'src/app/core/api/be/services';
import { ILWAppEnum } from 'src/app/core/constant';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-edit-cateogry',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss'],
})
export class EditCategoryComponent implements OnInit {
  @Input() club!: ClubResponseWithTotalMemberAndGoInliveModel;
  @Input() currentHashtags: HashtagResponseModel[] = [];
  @Input() openPopupEdit!: boolean;

  @Output() openPopupEditChange = new EventEmitter<boolean>();
  @Output() reloadClub = new EventEmitter();

  celebListExist: boolean = false;
  isSubmited = false;
  listHashtagByCategory: HashtagResponseModel[] = [];
  listIdsHashTags: string[] = [];
  textErrorMax5!: boolean;

  listCategories!: {
    url: string;
    name: string;
    value: string;
    label?: string;
  }[];
  selectedCategory!: { url: string; name: string; value: string };
  listHashtagAll!: HashtagResponseModel[];

  constructor(
    private _clubService: ClubService,
    private _hashtagService: HashtagService,
    private _translateService: TranslateService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.listCategories = this.initListCategories();

    this.selectedCategory = this.listCategories.filter(
      (h) => h.value == this.club.category!
    )[0];
    console.log('this.selectedCategory:s', this.selectedCategory);

    this.getAllHashtags();
  }

  closePopup() {
    this.openPopupEditChange.emit(false);
  }

  getIcon() {
    let iconUrl = ILWAppEnum.ENUM_ILW?.clubCategories?.filter(
      (c: any) => c.value == this.club?.category
    )[0]?.url!;
    return iconUrl;
  }

  getAllHashtags() {
    LoadingService.$(true);
    this._hashtagService
      .inlivewithHashtagsPageGet$Json({ page: 1, limit: 1000 })
      .subscribe((res) => {
        if (res.res == 0) {
          this.listHashtagAll = res.data?.page!;

          this.listHashtagByCategory = this.listHashtagAll.filter((h) =>
            this.club.category?.includes(h.category!)
          );

          LoadingService.$(false);
        }
      });
  }

  checkDisable(item: any): boolean {
    return (
      this.currentHashtags.length >= 5 &&
      this.currentHashtags.findIndex((x) => x.id == item.id!) < 0
    );
  }

  selectHashtag(h: HashtagResponseModel, event: any) {
    event.preventDefault();
    if (this.checkDisable(h)) {
      this.textErrorMax5 = true;
      return;
    }

    if (this.currentHashtags.length >= 5) {
      this.textErrorMax5 = false;
      if (!this.checkDisable(h)) {
        this.currentHashtags = this.currentHashtags.filter(
          (x) => x.id !== h.id!
        );
        return;
      } else {
        this.textErrorMax5 = true;
        return;
      }
    } else {
      this.textErrorMax5 = false;
    }

    this.currentHashtags.filter((hash) => hash.id == h.id).length > 0
      ? (this.currentHashtags = this.currentHashtags.filter(
          (v) => v.id! !== h?.id!
        ))
      : (this.currentHashtags = this.currentHashtags.concat(h));
  }

  getItemsSelected(h: any) {
    return this.currentHashtags.some((x) => x.id === h.id);
  }

  edit() {
    LoadingService.$(true);
    if (this.currentHashtags.length <= 5) {
      this.isSubmited = true;
      forkJoin([this.editCategory(), this.editClubByHashTags()])
        .pipe(
          tap(([res1, res2]) => {
            if (res1.res == 0 && res2.res == 0) {
              this.reloadClub.emit();
              LoadingService.$(false);
              this.closePopup();
            }
          })
        )
        .subscribe();
    } else {
      LoadingService.$(false);
    }
  }

  editClubByHashTags() {
    this.currentHashtags.forEach((x) => {
      this.listIdsHashTags.push(x?.id!);
    });

    return this._clubService.inlivewithClubHashtagsAdminIdClubIdPut$Json({
      adminId: this.club.author?.id!,
      clubId: this.club.id!,
      body: {
        adminId: this.club.author?.id!,
        clubId: this.club.id!,
        values: this.listIdsHashTags,
      },
    });
  }

  editCategory() {
    let body = {
      adminId: this.club.author?.id!,
      clubId: this.club.id!,
      body: {
        adminId: this.club.author?.id!,
        clubId: this.club.id!,
        value: this.selectedCategory.name,
      },
    };

    return this._clubService.inlivewithClubCategoryAdminIdClubIdPut$Json(body);
  }

  selectCategogy(event: any) {
    this.selectedCategory = event.value;

    this.currentHashtags = [];

    this.listHashtagByCategory = this.listHashtagAll.filter(
      (h) => h.category == this.selectedCategory.name
    )!;
  }

  initListCategories() {
    let list: any = [];
    ILWAppEnum.ENUM_ILW.clubCategories.forEach((c: any) => {
      list.push({
        name: c.name,
        value: c.value,
        url: c.url,
        index: c.index,
        label: this._translateService.instant(c.value!),
      });
    });

    return list;
  }

  getHashtagByCategory(name: string) {
    let listHashtagss: HashtagResponseModel[] = [];

    if (name == null) {
      listHashtagss = this.currentHashtags.filter(
        (x) => x.category === 'DANCE'
      );
    } else {
      listHashtagss = this.currentHashtags.filter((x) => x.category === name);
    }

    return listHashtagss;
  }

  showNotification() {
    const promise = new Promise((resolve, reject) => {
      resolve(
        this.messageService.add({
          severity: 'success',
          summary: '',
          detail: this._translateService.instant('CHANGE_INFOR_CLUB'),
          key: 'notificationOnWeb',
          closable: false,
          data: {},
          life: 3 * 1000,
        })
      );
    });

    promise.then((res) => window.location.reload());
  }
}
