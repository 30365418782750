<div class="zone-react">
  <div *ngFor="let author of listAuthors">
    <div class="flex mb-3 mt-1 align-items-center">
      <div class="avatar">
        <img class="fit-size" [src]="author?.avatar! ? author?.avatar! : defaultAvatar">
      </div>
      <div class="ml-3 name">
        <span>
          {{author?.nickName! | limitString:false:25}}
        </span>
      </div>
    </div>
  </div>
</div>
