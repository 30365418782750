import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { CelebrityPostResponeModel, CommentPostResponseModel, ReactionPostResponseModel } from 'src/app/core/api/be/models';
import { CelebrityService } from 'src/app/core/api/be/services';

@Component({
  selector: 'app-celeb-posts',
  templateUrl: './celeb-posts.component.html',
  styleUrls: ['./celeb-posts.component.scss']
})
export class CelebPostsComponent implements OnInit {

  @Input() celebId!: string;

  listPosts: CelebrityPostResponeModel[] = []
  listCommentsPost: CommentPostResponseModel[] = []
  listReactsPost: ReactionPostResponseModel[] = []
  postSelected!: CelebrityPostResponeModel

  currentControl: any

  openLikesZone: boolean = false;
  openComentsZone: boolean = false;

  constructor(
    private _celebService: CelebrityService,
    private _translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.getListPosts();
  }

  getListPosts() {
    this._celebService.inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Json({
      celebrityId: this.celebId,
      limit: 10000
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.listPosts = res.data?.page!
        }
      })
    ).subscribe();
  }

  getListCommentsPost(event: any) {
    console.log(event);

    this.listCommentsPost = event
  }

  getListReactsPost(event: any) {
    this.listReactsPost = event
  }

  initZoneControl() {
    var zoneControl: any = [
      {
        id: 0,
        title: this._translateService.instant('LIKES'),
        total: this.listReactsPost.length!,
        command: () => {
          this.currentControl = zoneControl[0]
          this.openLikesZone = true
        }
      },
      {
        id: 1,
        title: this._translateService.instant('COMMENTS'),
        total: this.listCommentsPost?.length!,
        command: () => {
          this.currentControl = zoneControl[1]
          this.openComentsZone = true
        }
      }
    ]

    if (!this.currentControl) {
      this.currentControl = zoneControl[1]
    }

    return zoneControl;
  }

}
