import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { LoginService } from 'src/app/core/services/login.service';
import { IMenu, SidebarService } from './sidebar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  menuItems: IMenu[];
  currentMenuItem!: IMenu;
  currentSubMenuItem!: IMenu;
  hoverMenuItem!: IMenu;

  currentUser = AuthService.GetAccountInfo();

  environment = environment;

  constructor(
    private _sideBarService: SidebarService,
    private _router: Router
  ) {
    this._sideBarService.getNotiUnread().subscribe();
    this.menuItems = this._sideBarService.GetListMenuItem();
  }

  ngOnInit(): void {
    console.log('SidebarComponent');
    if (!LocalStorageService.GetLocalStorage('language')) {
      LocalStorageService.AddLocalStorage('language', 'FR');
    }
  }

  login(){
    LoginService.$(true)
  }

  async logout() {
    await LocalStorageService.ClearLocalStorage();

    location.reload()
  }
}
