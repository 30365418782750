<p-dialog [(visible)]="openPopupEdit" [modal]="true" [draggable]="false" (onHide)="closePopup()" [resizable]="false"
  (onHide)="closePopup()" [closable]="true">
  <div style="width: 480px;">
    <div class="text-center text-black text-bold">
      <span class="fs-18">
        {{'LIST_CLUB_CATEGORY' | translate}}
      </span>
    </div>
    <div class="mt-5">
      <span class="fs-14 text-gray">
        {{'LIST_CLUB_CATEGORY' | translate}}
      </span>
    </div>
    <div class="flex align-items-center mt-1 dropdown-custom">
      <p-dropdown class="w-100" [options]="listCategories" optionLabel="label" [showClear]="false"
        [(ngModel)]="selectedCategory" (onChange)="selectCategogy($event)">
        <ng-template pTemplate="selectedItem">
          <div class="flex align-items-center gap-2">
            <img [src]="selectedCategory.url" style="width: 18px" />
            <div>{{ selectedCategory.value | translate }}</div>
          </div>
        </ng-template>
        <ng-template let-category pTemplate="item">
          <div class="flex align-items-center gap-2">
            <img [src]="category.url" style="width: 18px" />
            <div>{{ category.value | translate }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="mt-5">
      <span class="fs-14 text-gray">
        {{'HOBBIES' | translate}}
      </span>
    </div>

    <div *ngIf="currentHashtags && listHashtagByCategory" class="flex flex-wrap zone-hashtag">
      <div class="flex" *ngFor="let h of listHashtagByCategory; let i = index">
        <div class="flex p-2 m-1 hashtag align-items-center justify-content-center" *ngIf="getItemsSelected(h)"
          [class.selected-hashtag]="getItemsSelected(h)">
          <div class="flex align-items-center justify-content-center ml-2">{{h.name}}</div>
          <i class="pi pi-times fs-12 mt-1 ml-2 mr-2 cursor-pointer" (click)="selectHashtag(h, $event)"></i>
        </div>
      </div>
      <div class="flex w-100">
        <br>
      </div>
      <div class="flex" *ngFor="let h of listHashtagByCategory; let i = index">
        <div class="flex m-1 cursor-pointer hashtag" (click)="selectHashtag(h, $event)" *ngIf="!getItemsSelected(h)"
          [class.selected-hashtag]="getItemsSelected(h)">
          <span class="m-2">{{h.name}}</span>
        </div>
      </div>
    </div>
    <span *ngIf="textErrorMax5" class="fs-16 text-error mt-4 ml-1">Maximum 5 centres d'intérêt</span>
    <span *ngIf="currentHashtags.length! < 3 && isSubmited" class="fs-16 text-error mt-4 ml-1">
      {{'SELECTION_MIN_HASHTAG' | translate}}
    </span>

    <div class="mt-4 flex justify-content-center">
      <button pButton label="{{'CANCEL' | translate}}" class="p-button-red p-button-secondary blue ml-2"
        (click)="closePopup()"></button>
      <button pButton label="{{'CONFIRM' | translate}}" [disabled]="currentHashtags.length! < 3"
        class="p-button-red p-button-secondary white ml-2" (click)="edit()"></button>
    </div>
  </div>
</p-dialog>
