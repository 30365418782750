import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { CelebrityAliasClubsResponseModel, ClubResponseWithTotalMemberModel } from 'src/app/core/api/be/models';
import { CelebrityService } from 'src/app/core/api/be/services';

@Component({
  selector: 'app-celeb-club-linked',
  templateUrl: './celeb-club-linked.component.html',
  styleUrls: ['./celeb-club-linked.component.scss']
})
export class CelebClubLinkedComponent implements OnInit {

  @Input() celebId!: string;

  listClubs: CelebrityAliasClubsResponseModel[] = [];

  sidebarVisible = false;

  currentPage: number = 1;
  totalRecords!: number

  constructor(
    private _celebService: CelebrityService,
    private _translateService: TranslateService
  ) { }

  ngOnInit(): void {}

  getListClubsByCeleb(currentPage: number) {
    this._celebService.inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Json({
      celebrityId: this.celebId,
      limit: 10,
      page: currentPage
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.listClubs = res.data?.page!
          this.totalRecords = res.data?.totalItems!
        }
      })
    ).subscribe();
  }


  getCategoryImage(category: string) {
    let url;
    if (category == 'CLUB_CATEGORY_MUSIC') {
      url = 'https://storage.inlivewith.dev.ilwith.io/categories/music.svg';
    }
    if (category == 'CLUB_CATEGORY_DANCE') {
      url = 'https://storage.inlivewith.dev.ilwith.io/categories/dance.svg';
    }
    if (category == 'CLUB_CATEGORY_HUMOR') {
      url = 'https://storage.inlivewith.dev.ilwith.io/categories/humor.svg';
    }
    if (category == 'CLUB_CATEGORY_MEDIA') {
      url = 'https://storage.inlivewith.dev.ilwith.io/categories/media.svg';
    }
    return url;
  }

  getType(type: string) {
    if (type == 'CLUB_TYPE_FAN') {
      return this._translateService.instant('FAN');
    }
    return this._translateService.instant('TALENT');
  }

  gotoDetail(club: ClubResponseWithTotalMemberModel){

  }

  onRowSelect(event: any) {
    console.log('nudes', event.data);
    // this._router.navigate(['clubs/detail-club'], {
    //   queryParams: { clubId: event.data.id },
    // });
  }

  onRowUnselect(event: any) {
    console.log('nudes');
  }

  isRowSelectable(event: any) {
    return !this.isOutOfStock(event.data);
  }

  isOutOfStock(data: any) {
    return data.inventoryStatus === 'OUTOFSTOCK';
  }

  paginate(event: any) {
    this.currentPage = event.first / 10 + 1;
    this.getListClubsByCeleb(this.currentPage);
  }

}
