import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../core/services/local-storage.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  isAdmin: boolean = false;

  constructor(
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.isAdmin = LocalStorageService.GetLocalStorage('isAdmin') == 'true';
  }

  clearMessage() {
    this.messageService.clear();
  }
}
