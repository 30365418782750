import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IdentityService } from '../services/identity.service';
import { STORAGE_KEY } from '../constant';
import { AccountService } from '../api/be/services';
import { LocalStorageService } from '../services/local-storage.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    private _accountService: AccountService,
    private _router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let req;
    if (request.url.indexOf('/i18n') >= 0) {
      req = request.clone();
    } else {
      req = request.clone({
        setHeaders: {
          Authorization: LocalStorageService.GetLocalStorage(STORAGE_KEY.TOKEN) && !this._router.url.includes('/public-dashboard') ?
          `Bearer ${LocalStorageService.GetLocalStorage(STORAGE_KEY.TOKEN)}` : `Bearer ${LocalStorageService.GetLocalStorage(STORAGE_KEY.TEMP_TOKEN)}`,
        },
      });
    }

    return next.handle(req).pipe(
      map((res: any) => {
        if (res && res.body && res.body['dataStr']) {
          try {
            res.body['data'] =
              JSON.parse(atob(res.body['dataStr'])) ??
              atob(res.body['dataStr']);
            if (res.body['data'] == 'null') {
              res.body['data'] = null;
            }
          } catch {
            res.body['data'] = atob(res.body['dataStr']);
          }
          res.body['dataStr'] = null;
        }
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          if (this._router.url.includes('/public-dashboard')) {
            this.refreshToken().subscribe();
          }
          else {
            localStorage.clear();
            this._router.navigate(['auth/login']).then(() => window.location.reload())
          }
        }
        if (err.status === 500) {
          return of(new HttpResponse({ body: err.error, status: 0 }));
        }
        throw err.message;
      })
    );
  }

  refreshToken() {
    return this._accountService
      .inlivewithAccountRefreshTokenPost$Json({
        token: environment.TEMP_TOKEN,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            const newToken = res.data!;
            LocalStorageService.AddLocalStorage(STORAGE_KEY.TEMP_TOKEN, newToken);
            location.reload();
          } else {
            LocalStorageService.ClearLocalStorage();
            window.open(environment.WEB_PORTAL_URL, '_self');
          }
        })
      );
  }
}
