import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import {
  Account,
  AccountGuidInputModel,
  AccountResponseGeneral,
  ClubCategoriesEnum,
  Hashtag,
  HashtagResponseModel,
  ResultSearchAccountViewModel,
} from 'src/app/core/api/be/models';
import {
  AccountService,
  BackOfficeService,
  HashtagService
} from 'src/app/core/api/be/services';
import { AppEnumService } from 'src/app/core/api/be/services';
import { STORAGE_KEY, defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { AppEnumStateService, IAppEnumStateModel } from 'src/app/core/states/app-enum-state.service';
import { environment } from 'src/environments/environment';

export interface ClubCategoriesEnumE extends ClubCategoriesEnum {
  posterImage: string;
  value: string;
}
@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss'],
})
export class AccountInfoComponent implements OnInit {
  @Input() account!: AccountResponseGeneral;
  @Input() hashtag!: Hashtag[];

  @Output() reloadAccount = new EventEmitter();

  defaultAvatar = defaultAvatar;

  listReasonReports: any[] = [];
  selectedReason!: any;

  openPopupBanInliver = false;
  openPopupEditPrivacy = false;
  openPopupWarn = false;
  openEditHashtag = false;

  isPrivate!: boolean;
  isAdminBO!: boolean;

  editInliverInfo = false;
  accountIdentity!: ResultSearchAccountViewModel;
  firstName: any;
  lastName: any;
  nickName: any;
  phoneNumber: any;
  birthday: any;
  gender: any;

  constructor(
    private _accountService: AccountService,
    private _appEnum: AppEnumService,
    private _backOfficeService: BackOfficeService,
    private _messageService: MessageService,
    private _translateService: TranslateService,
    private _hashtagService: HashtagService
  ) { }

  ngOnInit(): void {
    this.isPrivate = this.account.isPrivate!;
    this.getUserRole()
  }

  getHashTagByAccount() {
    this._accountService
      .inlivewithAccountHashtagsAccountIdGet$Json({
        accountId: this.account!.id!,
      })
      .subscribe((res) => {
        this.hashtag = res.data?.page!;
      });
  }

  onHide() {
    this.openPopupBanInliver = false;
  }

  banInliver() {
    this._backOfficeService
      .inlivewithBackOfficeBannedAccountPut$Json({
        accountId: this.account!.id!,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            window.location.reload();
          }
        })
      )
      .subscribe();
  }

  editPrivacy() {
    if (this.isPrivate) {
      this._accountService
        .inlivewithAccountPrivateAccountIdPut$Json({
          accountId: this.account!.id!,
          body: {
            accountId: this.account!.id!,
          },
        })
        .pipe(
          tap((res) => {
            if (res.res == 0) {
              window.location.reload();
            }
          })
        )
        .subscribe();

      return;
    } else {
      this._accountService
        .inlivewithAccountPublicAccountIdPut$Json({
          accountId: this.account!.id!,
          body: {
            accountId: this.account!.id!,
          },
        })
        .pipe(
          tap((res) => {
            if (res.res == 0) {
              window.location.reload();
            }
          })
        )
        .subscribe();

      return;
    }
  }

  getAppEnum() {
    this._appEnum
      .inlivewithAppEnumReportAccountReasonsGet$Json({
        limit: 100,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.listReasonReports = res.data?.page!;
          }
        })
      )
      .subscribe();
  }

  // updateAccountInfo() {
  //   this._accountService.inlivewithAccountUpdateByAdministratorsIdPut$Json({
  //     id: this.account.id!,
  //     body: {
  //       firstName: this.firstName ?? this.account.firstName,
  //       lastName: this.lastName ?? this.account.lastName,
  //       displayName: this.nickName ?? this.account.nickName,
  //       phoneNumber: this.phoneNumber ?? this.account.phoneNumber,
  //       gender: this.gender ?? this.account.gender,
  //       birthday: this.birthday ?? this.account.birthday
  //       //addressComp: this.addressComp ?? this.celeb.addressComp
  //     }
  //   }).pipe(
  //     tap(res => {
  //       if (res.res == 0) {
  //       }
  //     })
  //   ).subscribe();
  // }

  createReportWarnInliver() {
    this._backOfficeService.inlivewithBackOfficeCreateReportWarnAccountPost$Json({
      body: {
        accountId: this.account.id!,
        adminstatorId: AuthService.GetAccountInfo().id!,
        description: "",
        reasonReport: this.selectedReason?.value!
      }
    }).pipe(
      tap(res => {
        this.openPopupWarn = false;
        this.showNotification(this._translateService.instant('CHANGE_INFOR_CLUB'))
        this.reloadAccount.emit();
      })
    ).subscribe();
  }

  showNotification(subtitle: string) {
    this._messageService.add({
      severity: 'success',
      summary: '',
      detail: subtitle,
      key: 'notificationOnWeb',
      closable: false,
      data: {},
      life: 3 * 1000,
    });
  }

  edit() {
    const tab = window.open(
      environment.WEB_PORTAL_URL +
      `/profile-bo/${this.account.email}?callback=${window.origin
      }/home/edit-user&lang=${LocalStorageService.GetLocalStorage(
        'language'
      ).toLowerCase()}&token=${LocalStorageService.GetLocalStorage(STORAGE_KEY.TOKEN)}`,
      'targetWindow',
      `toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1250, height=840`
    );

    window.addEventListener('message', (e) => {
      if (e.data === 'close') {
        this.getAccountIdentityByEmail().subscribe(() => {
          this.syncAccountInfo(true);
        });
      }
    });
  }

  getAccountIdentityByEmail() {
    return this._accountService
      .inlivewithAccountGetAccountIdentityByEmailEmailGet$Json({
        email: this.account.email!,
      })
      .pipe(
        tap((res) => {
          if (res.res == 0) {
            this.accountIdentity = res.data!;
          }
        })
      );
  }

  syncAccountInfo(isReload?: boolean) {
    if (
      // this.account.lastName !== this.accountIdentity.lastName ||
      // this.account.firstName !== this.accountIdentity.firstName ||
      // this.account.nickName !== this.accountIdentity.displayName ||
      // this.account.phoneNumber !== this.accountIdentity.phoneNumber ||
      this.account.gender !== this.accountIdentity.gender ||
      this.account.birthday !== this.accountIdentity.birthDay ||
      this.account.avatar !== this.accountIdentity.avatarUrl
    ) {
      LoadingService.$(true);
      let body = {
        accountId: this.account.id!,
        // lastName: this.accountIdentity.lastName,
        // firstName: this.accountIdentity.firstName,
        nickName: this.accountIdentity.displayName,
        // phoneNumber: this.accountIdentity.phoneNumber!,
        gender: this.accountIdentity.gender || 'GENDER_UNDEFINED',
        avatar: this.accountIdentity!.avatarUrl!
          ? this.accountIdentity.avatarUrl!?.replace(
            environment.STORAGE_URL_IDENTITY,
            ''
          )
          : '',
      };
      this._accountService
        .inlivewithAccountUpdateAccountIdPut$Json({
          accountId: this.account.id!,
          body: body,
        })
        // .pipe(mergeMap(() => this.setBirthday()))
        .subscribe((res) => {
          // AuthService.SetAccountInfo(res?.data!);
          this.reloadAccount.emit();
          LoadingService.$(false);
          if (isReload) {
            this.showNotification(
              this._translateService.instant('CHANGE_INFOR_CLUB')
            );
          }
        });
    }
  }

  setBirthday() {
    let body = {
      accountId: this.account.id!,
      birthday: new Date(this.accountIdentity.birthDay! + 'Z').toISOString(),
    };
    return this._accountService.inlivewithAccountBirthdayAccountIdPut$Json({
      accountId: this.account.id!,
      body: body,
    });
  }

  getUserRole() {
    this._accountService.inlivewithAccountGetUserRoleAsyncEmailGet$Json({
      email: this.account.email!
    }).pipe(
      tap(res => {
        if (res.data?.includes('administrators')) {
          this.isAdminBO = true;
        }
      })
    ).subscribe();
  }
}
