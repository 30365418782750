import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, tap } from 'rxjs/operators';
import { AccountResponseWithRoleInCLubModel, AlbumAsset, AlbumAssetResponseGeneral, AlbumResponseWithTotalAssetModel, CommentAlbumAssetResponseModel, ReactAlbumAssetResponseModel } from 'src/app/core/api/be/models';
import { AccountService, AlbumService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-detail-album',
  templateUrl: './detail-album.component.html',
  styleUrls: ['./detail-album.component.scss']
})
export class DetailAlbumComponent implements OnInit {

  @Input() album!: AlbumResponseWithTotalAssetModel
  @Output() goBackToHome = new EventEmitter()

  commentsAsset: CommentAlbumAssetResponseModel[] = []
  reactsAsset: ReactAlbumAssetResponseModel[] = []

  albumName!: string;
  albumSceneName!: string;
  albumDescription!: string;

  isEdit: boolean = false
  isPrivate!: boolean
  isViewAssetDetail: boolean = false
  openLikesZone: boolean = false;
  openComentsZone: boolean = false;

  currentControl: any
  currentAsset!: AlbumAssetResponseGeneral
  currentAuthor!: AccountResponseWithRoleInCLubModel

  defaultAvatar = defaultAvatar

  constructor(
    private _accountService: AccountService,
    private _translateService: TranslateService,
    private _albumService: AlbumService
  ) { }

  ngOnInit(): void {
    this.isPrivate = this.album.isPrivate!
  }

  goBack() {
    this.goBackToHome.emit()
  }

  checkTypeMedia(asset: AlbumAssetResponseGeneral): string {

    if (!asset.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return 'VIDEO';
      } else {
        return 'IMAGE'
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    return 'UNKNOWN';
  }

  getUrl(asset: AlbumAssetResponseGeneral) {
    if (!asset?.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return asset?.url!;
        }
      }
    } else {
      if (asset?.url!.split('?')[1].startsWith('i')) {
        return asset?.url!.replace('/file.', '/thumb.')
      }

      if (asset?.url!.split('?')[1].startsWith('v')) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      }

    }
    return asset?.url!;
  }

  deleteAlbum() {
    if (this.isEdit) {
      this.isEdit = false;
    }
  }

  editAlbum() {
    this.isEdit = true;
  }

  openViewAsset(asset: AlbumAssetResponseGeneral) {
    this.getInfoAssetById(asset.authorId!, asset.id!)
    this.isViewAssetDetail = true;
  }

  getInfoAssetById(authorId: string, assetId: string) {
    this._albumService.inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Json({
      accountRequestId: authorId,
      albumAssetId: assetId
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.currentAsset = res.data!
        }
      }),
      switchMap(() => this.getInfoAuthor(authorId)),
      switchMap(() => forkJoin([this.getCommentByAssetId(authorId, assetId), this.getReactByAssetId(authorId, assetId)])),
    ).subscribe();
  }

  getInfoAuthor(authorId: string) {
    return this._accountService.inlivewithAccountClubDetailAccountIdClubIdGet$Json({
      accountId: authorId,
      clubId: this.album.clubId!
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.currentAuthor = res.data!
          this.initZoneControl();
        }
      })
    )
  }

  getCommentByAssetId(authorId: string, assetId: string) {
    return this._albumService.inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Json({
      accountRequestId: authorId,
      albumAssetId: assetId,
      limit: 1000
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.commentsAsset = res.data!.page!;
        }
      })
    )
  }

  getReactByAssetId(authorId: string, assetId: string){
    return this._albumService.inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Json({
      albumAssetId: assetId,
      accountRequestId: authorId,
      limit: 1000
    }).pipe(
      tap(res => {
        if(res.res == 0){
          this.reactsAsset = res.data?.page!
        }
      })
    )
  }

  initZoneControl() {
    var zoneControl: any = [
      {
        id: 0,
        title: this._translateService.instant('LIKES'),
        total: this.currentAsset?.totalReact!,
        command: () => {
          this.currentControl = zoneControl[0]
          this.openLikesZone = true
        }
      },
      {
        id: 1,
        title: this._translateService.instant('COMMENTS'),
        total: this.currentAsset?.totalComment!,
        command: () => {
          this.currentControl = zoneControl[1]
          this.openComentsZone = true
        }
      }
    ]

    if(!this.currentControl){
      this.currentControl = zoneControl[1]
    }

    return zoneControl;
  }

  getRoleInClub(account: AccountResponseWithRoleInCLubModel) {
    if (account.roleInClub == "ROLE_ADMIN") {
      return this._translateService.instant('LIST_CLUB_AUTHOR');
    } else {
      return this._translateService.instant('CLUB_MEMBER');
    }
  }

  timeAgo = (date: string) => {
    let currentLang = LocalStorageService.GetLocalStorage('language').toLowerCase();
    return moment.utc(date).locale(currentLang ? currentLang : 'fr').fromNow();
  };

  checkOverflow(id: string): boolean {
    const elem = document.getElementById(id);
    if (elem) {
      return elem.offsetWidth < elem.scrollWidth;
    } else {
      return false;
    }
  }

  scrollTo(id: string, isLeft: boolean = true) {
    let a = document.getElementById(id)!;
    a.scrollLeft = isLeft ? a.scrollLeft - 400 : a.scrollLeft + 400;
  }


}
