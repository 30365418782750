<div class="flex flex-column" style="width: 220px;">
  <div class="zone-avatar position-relative">
    <img [src]="event.poster" class="fit-size cursor-pointer" (click)="openDetailEvent()">
    <div class="event-plus" *ngIf="!event.isPublic!">
      <img class="inliverplus" src="../../../../assets/images/svgs/inliver-plus.svg" alt="" srcset="">
    </div>
  </div>
  <div class="zone-content">
    <span class="text-blue text-bold">
      {{ event.dateStart + 'Z' | date:'dd/MM/yy HH:mm' }}
    </span>
    <span class="text-blue text-bold">
      {{ event.place!}}
    </span>
    <span class="text-gray text-bold mt-1">
      {{event.name}}
    </span>
    <span class="mt-1">
      {{event.numberAccountJoinEvent!}} {{event.numberAccountJoinEvent! > 1 ? ('PARTICIPANTS' | translate) :
      ('PARTICIPANT' | translate)}}
    </span>
    <span class="fs-12 mt-1">
      {{'CREATED_AT' | translate}} {{event.dateCreated | date:'dd/MM/yyyy'}} {{'BY' | translate}} @{{event.author!.nickName!}}
    </span>
  </div>
</div>
