<div class="flex h-100">

  <div *ngIf='isAdmin' class="layout">
    <div class="menu">
      <ng-container>
        <app-sidebar></app-sidebar>
      </ng-container>
    </div>
  </div>

  <p-toast position="top-right" key="alert">
    <ng-template let-message pTemplate="message">
      <div class="flex align-items-center justify-content-center w-100">
        <div class="flex align-items-center justify-content-center w-20">
          <div class="icon-info"></div>
        </div>
        <div class="flex flex-column w-80">
          <span class="title" [innerHTML]="message.summary"></span>
          <!-- <span class="subtitle" [innerHTML]="message.detail"></span> -->
        </div>
      </div>
    </ng-template>
  </p-toast>

  <p-toast position="top-right" key="notificationOnWeb" (onClose)="clearMessage()">
    <ng-template let-message pTemplate="message">
      <div class="flex align-items-center justify-content-center cursor-pointer w-100">
        <div class="flex align-items-center justify-content-center w-20">
          <div class="icon-info"></div>
        </div>
        <div class="flex flex-column w-80 pt-3 pb-3">
          <span class="title" [innerHTML]="message.summary"></span>
          <span class="subtitle" [innerHTML]="message.detail"></span>
        </div>
      </div>
    </ng-template>
  </p-toast>

  <ng-container>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </ng-container>
</div>
