/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ReportListResponseModel } from '../models/report-list-response-model';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithReportChangeRepostStatusReportStatusPut
   */
  static readonly InlivewithReportChangeRepostStatusReportStatusPutPath = '/inlivewith/Report/ChangeRepostStatus/{reportStatus}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportChangeRepostStatusReportStatusPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportChangeRepostStatusReportStatusPut$Plain$Response(params: {
    reportStatus: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ReportListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportChangeRepostStatusReportStatusPutPath, 'put');
    if (params) {
      rb.path('reportStatus', params.reportStatus, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportChangeRepostStatusReportStatusPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportChangeRepostStatusReportStatusPut$Plain(params: {
    reportStatus: string;
    body?: Array<string>
  }): Observable<ReportListResponseModel> {

    return this.inlivewithReportChangeRepostStatusReportStatusPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportListResponseModel>) => r.body as ReportListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithReportChangeRepostStatusReportStatusPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportChangeRepostStatusReportStatusPut$Json$Response(params: {
    reportStatus: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ReportListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ReportService.InlivewithReportChangeRepostStatusReportStatusPutPath, 'put');
    if (params) {
      rb.path('reportStatus', params.reportStatus, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithReportChangeRepostStatusReportStatusPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithReportChangeRepostStatusReportStatusPut$Json(params: {
    reportStatus: string;
    body?: Array<string>
  }): Observable<ReportListResponseModel> {

    return this.inlivewithReportChangeRepostStatusReportStatusPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportListResponseModel>) => r.body as ReportListResponseModel)
    );
  }

}
