import { Component, Input, OnInit } from '@angular/core';
import { AccountResponseInPost, ReactionPostResponseModel } from 'src/app/core/api/be/models';
import { PostService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';

@Component({
  selector: 'app-list-author-discussion',
  templateUrl: './list-author-discussion.component.html',
  styleUrls: ['./list-author-discussion.component.scss']
})
export class ListAuthorDiscussionComponent implements OnInit {

  @Input() listAuthors: AccountResponseInPost[] = []

  defaultAvatar = defaultAvatar

  constructor() { }

  ngOnInit(): void {}

}
