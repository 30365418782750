import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateLoader } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<JSON> {
    return this.http
      .get<JSON>(`../../../assets/i18n/${lang}.json`)
      .pipe(map((res: JSON) => res));
  }
}
