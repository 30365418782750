<div class="card" *ngIf="report" (click)="setReportSelected()"
  [ngClass]="{'report-selected': reportSelected && checkedClicked}">
  <div class="flex justify-content-between">
    <div class="flex flex-column" [ngClass]="fromOtherPage ? 'w-100' : 'w-60'">
      <div class="zone-info-user">
        <div class="flex align-items-center" *ngIf="report.type == 'REPORT_CLUB'">
          <div class="zone-avt mr-2">
            <img class="fit-size" [src]="report.club?.avatar!">
          </div>
          <div class="zone-name ml-2">
            <span>{{report.club?.name!}}</span>
            <span class="text-red">
              <span>{{report.reports?.length!}} {{(report.reports?.length! > 1 ? 'CLUB_SIGNALEMENTS' :
                'SIGNALEMENT') | translate | lowercase}}</span>
            </span>
          </div>
        </div>
        <div class="flex align-items-center" *ngIf="report.type == 'REPORT_ACCOUNT'">
          <div class="zone-avt mr-2">
            <img class="fit-size" [src]="report.account?.avatar ?? defaultAvatar">
          </div>
          <div class="zone-name ml-2">
            <span>{{report.account?.nickName!}}</span>
            <span class="text-red">
              <span>{{report.reports?.length!}} {{(report.reports?.length! > 1 ? 'CLUB_SIGNALEMENTS' :
                'SIGNALEMENT') | translate | lowercase}}</span>
            </span>
          </div>
        </div>
        <div class="flex flex-column" *ngIf="report.type == 'REPORT_POST'">
          <div class="flex align-items-center">
            <div class="zone-avt mr-2">
              <img class="fit-size" [src]="report.post?.author?.avatar ?? defaultAvatar">
            </div>
            <div class="zone-name ml-2">
              <div class="flex">
                <span class="mr-2">{{report.post?.clubName!}}</span>
                <span class="zone-author-name">{{report.post?.author?.nickName! | limitString:false:40}}</span>
                <span class="ml-2">{{report.authorContentRole! | translate}}</span>
              </div>
              <span class="text-red mt-1">
                <span>{{report.reports?.length!}} {{(report.reports?.length! > 1 ? 'CLUB_SIGNALEMENTS' :
                  'SIGNALEMENT') | translate | lowercase}}</span>
              </span>
            </div>
          </div>
          <div class="zone-post-info">
            <div class="mb-3 mt-3">
              <div class="flex align-items-center">
                <span class="publication mr-2" *ngIf="report.post?.type! == 'POST_PUBLICATION'">
                  {{'POST_TYPE_PUBLICATION_TITLE' | translate}}
                </span>
                <span class="discussion mr-2" *ngIf="report.post?.type! == 'POST_DISCUSSION'">
                  {{'POST_TYPE_DISCUSSION_TITLE' | translate}}
                </span>
              </div>
            </div>
            <div style="overflow-wrap: anywhere;"
              [innerHTML]="urlify(report.post?.type! == 'POST_DISCUSSION' ? report.post?.title : report.post?.message! | limitString:completeText:200)">
            </div>
            <div class="flex list-media" *ngIf="report?.post?.mediaPosts?.length! > 0" [id]="report.post?.id!">
              <div class="mr-1" *ngFor="let asset of report?.post?.mediaPosts!">
                <div class="mr-4" *ngIf="(checkTypeMedia(asset) == 'IMAGE')" style="height: 100px">
                  <img [src]="getUrl(asset)" alt="" style="height: 100%;width: auto;">
                </div>
                <div style="width: 280px;" *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                  <video [id]="asset.id" class="original-size" [src]="asset!.url!" controls></video>
                </div>
                <div *ngIf="checkTypeMedia(asset) == 'AUDIO'">
                  <audio controls>
                    <source [src]="asset?.url!">
                  </audio>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-column"
          *ngIf="report.type == 'REPORT_COMMENT_POST' || report.type == 'REPORT_COMMENT_ALBUM'">
          <div class="flex align-items-center">
            <div class="zone-avt mr-2">
              <img *ngIf="report.type == 'REPORT_COMMENT_POST'" class="fit-size"
                [src]="report.commentPost?.author?.avatar ?? defaultAvatar">
              <img *ngIf="report.type == 'REPORT_COMMENT_ALBUM'" class="fit-size"
                [src]="report.commentAlbumAsset?.author?.avatar ?? defaultAvatar">
            </div>
            <div class="zone-name ml-2">
              <div class="flex" *ngIf="report.type == 'REPORT_COMMENT_POST'">
                <span class="mr-2">{{report.commentPost?.clubName!}}</span>
                <span class="zone-author-name">{{report.commentPost?.author?.nickName!}}</span>
                <span class="ml-2">{{report.authorContentRole! | translate}}</span>
              </div>
              <div class="flex" *ngIf="report.type == 'REPORT_COMMENT_ALBUM'">
                <!-- <span class="mr-2">{{report.commentAlbumAsset?.clubName!}}</span> -->
                <span class="zone-author-name">{{report.commentAlbumAsset?.author?.nickName!}}</span>
                <span class="ml-2">{{report.authorContentRole! | translate}}</span>
              </div>
              <span class="text-red mt-1">
                <span>{{report.reports?.length!}} {{(report.reports?.length! > 1 ? 'CLUB_SIGNALEMENTS' :
                  'SIGNALEMENT') | translate | lowercase}}</span>
              </span>
            </div>
          </div>
          <div class="zone-post-info">
            <div class="mb-3 mt-3">
              <div class="flex align-items-center">
                <span class="publication mr-2">
                  {{'COMMENT' | translate}}
                </span>
              </div>
            </div>
            <div *ngIf="report.type == 'REPORT_COMMENT_POST'">
              <div [innerHTML]="urlify(report.commentPost?.message! | limitString:completeText)"></div>
              <div class="flex list-media" *ngIf="report?.commentPost?.mediaCommentPosts?.length! > 0"
                [id]="report.commentPost?.id!">
                <div class="mr-1" *ngFor="let asset of report?.commentPost?.mediaCommentPosts!">
                  <div class="mr-4" *ngIf="(checkTypeMedia(asset) == 'IMAGE')" style="height: 100px">
                    <img [src]="getUrl(asset)" alt="" style="height: 100%;width: auto;">
                  </div>
                  <div style="width: 280px;" *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                    <video [id]="asset.id" class="original-size" [src]="asset!.url!" controls></video>
                  </div>
                  <div *ngIf="checkTypeMedia(asset) == 'AUDIO'">
                    <audio controls>
                      <source [src]="asset?.url!">
                    </audio>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="report.type == 'REPORT_COMMENT_ALBUM'">
              <div [innerHTML]="urlify(report.commentAlbumAsset?.message! | limitString:completeText)"></div>
              <div class="flex list-media" *ngIf="report?.commentAlbumAsset?.mediaCommentAlbumAssets?.length! > 0"
                [id]="report.commentAlbumAsset?.id!">
                <div class="mr-1" *ngFor="let asset of report?.commentAlbumAsset?.mediaCommentAlbumAssets!">
                  <div class="mr-4" *ngIf="(checkTypeMedia(asset) == 'IMAGE')" style="height: 100px">
                    <img [src]="getUrl(asset)" alt="" style="height: 100%;width: auto;">
                  </div>
                  <div style="width: 280px;" *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                    <video [id]="asset.id" class="original-size" [src]="asset!.url!" controls></video>
                  </div>
                  <div *ngIf="checkTypeMedia(asset) == 'AUDIO'">
                    <audio controls>
                      <source [src]="asset?.url!">
                    </audio>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-column mt-1 ml-5">
        <div class="flex" [ngClass]="fromOtherPage ? 'justify-content-between' : ''"
          [ngStyle]="fromOtherPage ? {'align-items': 'flex-start'} : {}" *ngFor="let reporter of report.reports">
          <div class="flex flex-column ml-4 mt-2 mb-2">
            <div class="zone-info-user">
              <div class="zone-avt mr-1">
                <img class="fit-size" [src]="reporter.reporterAvatar! ? reporter.reporterAvatar : defaultAvatar">
              </div>
              <div class="zone-name ml-1">
                <span>{{reporter.reporterNickname!}}</span>
                <span class="text-disabled">
                  {{timeAgo(reporter.dateCreated!)}}
                </span>
              </div>
            </div>
            <div class="flex flex-column mt-1">
              <span class="text-gray text-bold">{{reporter.reasonReport! | translate}}</span>
              <span class="fs-12" *ngIf="reporter.reasonReport! == 'OTHER'">
                {{reporter.description!}}
              </span>
            </div>
          </div>
          <div *ngIf="fromOtherPage">
            <div class="flex" *ngIf="reporter.status! === 'STATUS_IGNORE'">
              <span class="text-blue">{{'IGNORE_AT' | translate}} {{reporter.dateUpdated | date:"dd/MM/yyyy"}}</span>
            </div>
            <div class="flex" *ngIf="reporter.status! === 'STATUS_ACCEPTED'">
              <span class="text-blue">{{'APPROVED_AT' | translate}} {{reporter.dateUpdated | date:"dd/MM/yyyy"}}</span>
            </div>
            <div class="flex" *ngIf="reporter.status! === 'STATUS_WARN'">
              <span class="text-blue">{{'WARNING_AT' | translate}} {{reporter.dateUpdated | date:"dd/MM/yyyy"}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="action">
      <div class="flex align-items-center" *ngIf="report?.reports![0].status! === 'STATUS_NEW' && checkedClicked">
        <button pButton type="button" (click)="setStatusReport(1)" label="{{'IGNORE' | translate}}"
          class="p-button-outlined btn-transparent text-blue p-button-secondary mr-4"></button>
        <div class="flex align-items-center" *ngIf="!fromContenu">
          <button pButton type="button" (click)="setStatusReport(2)" label="{{'APPROVE' | translate}}"
            class="p-button-outlined btn-white text-blue p-button-secondary ml-4 mr-4"></button>
          <button pButton type="button" (click)="setStatusReport(3)" label="{{'WARNING' | translate}}"
            class="p-button-outlined btn-blue p-button-secondary ml-4"></button>
        </div>
        <button pButton *ngIf="fromContenu" type="button" (click)="setStatusReport(2)"
          label="{{'DELETE_CONTENT_REPORT' | translate}}"
          class="p-button-outlined btn-white text-blue p-button-secondary mr-4"></button>
      </div>
    </div>
    <div style="width: 13%;" *ngIf="!fromOtherPage && !action">
      <div class="flex" *ngIf="report.reports![0].status === 'STATUS_IGNORE'">
        <span class="text-blue">{{'IGNORE_AT' | translate}} {{report.reports![0].dateUpdated |
          date:"dd/MM/yyyy"}}</span>
      </div>
      <div class="flex" *ngIf="report.reports![0].status === 'STATUS_ACCEPTED'">
        <span class="text-blue">{{'APPROVED_AT' | translate}} {{report.reports![0].dateUpdated |
          date:"dd/MM/yyyy"}}</span>
      </div>
      <div class="flex" *ngIf="report.reports![0].status === 'STATUS_WARN'">
        <span class="text-blue">{{'WARNING_AT' | translate}} {{report.reports![0].dateUpdated |
          date:"dd/MM/yyyy"}}</span>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="openPopup">
  <app-card-handle-report [report]="report" [(openPopup)]="openPopup" [actionType]="actionType"
    [handleType]="handleType" [fromOtherPage]="fromOtherPage" [fromContenu]="fromContenu" (refreshListReport)="refreshListReport.emit()"></app-card-handle-report>
</ng-container>
