<p-dialog [visible]="openEditHashtag" [resizable]="false" [draggable]="false" [modal]="true" [closable]="false"
  (onHide)="onHide()">
  <div class="hashtag-card">
    <div class="title">
      {{'HOBBIES' | translate}}
    </div>
    <div class="content">
      <div class="flex flex-wrap mt-3">
        <span *ngFor="let item of hashtag" class="ml-2 mb-2"
          style="color: white;background-color: #D5D7E3;padding: 7px;border-radius: 6px;display: flex;align-items: center;">
          {{ item.name }}
          <i class="pi pi-times ml-2 cursor-pointer" (click)="deleteHashtag(item)"></i>
        </span>
      </div>

      <div class="flex justify-content-between align-items-center text-white">
        <ng-container class="" *ngFor="let c of listCategory | async; let i = index">
          <div class="col-3 flex flex-column mt-3 cursor-pointer" (click)="selectCategory(c.name!)">
            <div class="poster">
              <img [src]="getPosterByCategory(c.name!).posterImage!" class="fit-size" alt="" srcset=""
                [ngStyle]="categorySelected == c.name ? {'border':'solid', 'border-radius':'18px'} : {}">
              <span style="font-size: 16px;text-shadow: 2px 0px 10px black;">
                {{c.value! | translate}}
              </span>
            </div>

          </div>
        </ng-container>
      </div>

      <div class="list-hashtag">
        <div class="flex flex-wrap mt-3">
          <div *ngFor="let h of hashtagByCategory; let i = index;">
            <div (click)="selectHashtag(h)" [id]="h.name"
              [ngStyle]="checkDisable(h) ? {'color':'gray', 'pointer-events':'none'}:{'color':'black',  'border': '1px solid #D5D7E3'}"
              class="p-2 m-1 cursor-pointer" style="border-radius: 5px;">
              {{h?.name!}}
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-content-center">
        <button pButton label="{{'CANCEL' | translate}}" (click)="openEditHashtag = false"
          class="mt-5 mr-3 p-button-outlined blue p-button-secondary"></button>
        <button pButton type="submit" label="{{'CONFIRM' | translate}}" (click)="suivant()"
          [disabled]="hashtag.length < 3 || hashtag.length > 10"
          class="mt-5 ml-3 p-button-outlined btn-blue p-button-secondary"></button>
      </div>
    </div>
  </div>
</p-dialog>
