<span>
  <h3> Meilleurs clubs</h3>
</span>


<p-table [value]="topClubs" [lazy]="true" styleClass="p-datatable-md" [rowHover]="true">
  <ng-template pTemplate="header">
    <tr>
      <th>Nom du club</th>
      <th>Catégorie</th>
      <th>
        <div class="flex justify-content-center">
          Membres
        </div>
      </th>
      <th>Date de création</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-club>
    <tr>
      <td>
        <div class="flex align-items-center">
          <div class="flex">
            <div style="width: 48px; height: 48px; border-radius: 5px;overflow: hidden;">

              <img [src]="club.avatar" class="fit-size" alt="" srcset="">
            </div>
          </div>
          <div class="flex ml-2 align-items-center">{{club.name | translate}}</div>
        </div>
      </td>
      <td>
        <div class="flex align-items-center">
          <div class="flex">
            <img [src]="club.categoryIcon" alt="" srcset="">
          </div>
          <div class="flex ml-2">{{club.category | translate}}</div>
        </div>
      </td>
      <td>
        <div class="flex justify-content-center">
          {{club.totalMember}}
        </div>
      </td>
      <td>{{club.dateCreated | date:'dd/MM/YYYY'}}</td>
    </tr>
  </ng-template>
</p-table>
<div class="flex justify-content-end w-100">
  <span class="cursor-pointer mt-2 flex align-items-center fs-14" style="color: #0058FF;">
    Voir plus >
  </span>
</div>
