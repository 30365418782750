import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard], },
  { path: 'notifications', loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule), canActivate: [AuthGuard], },
  { path: 'messages', loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesModule), canActivate: [AuthGuard], },
  { path: 'celebrities', loadChildren: () => import('./pages/celebrities/celebrities.module').then(m => m.CelebritiesModule), canActivate: [AuthGuard], },
  { path: 'clubs', loadChildren: () => import('./pages/clubs/clubs.module').then(m => m.ClubsModule), canActivate: [AuthGuard], },
  { path: 'inlivers', loadChildren: () => import('./pages/inlivers/inlivers.module').then(m => m.InliversModule), canActivate: [AuthGuard], },
  { path: 'badges', loadChildren: () => import('./pages/badges/badges.module').then(m => m.BadgesModule), canActivate: [AuthGuard], },
  { path: 'hashtags', loadChildren: () => import('./pages/hashtags/hashtags.module').then(m => m.HashtagsModule), canActivate: [AuthGuard], },
  { path: 'signalements', loadChildren: () => import('./pages/signalements/signalements.module').then(m => m.SignalementsModule), canActivate: [AuthGuard], },
  { path: 'roles', loadChildren: () => import('./pages/roles/roles.module').then(m => m.RolesModule), canActivate: [AuthGuard], },
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule) },
  { path: 'public-dashboard', loadChildren: () => import('./pages/public-dashboard/public-dashboard.module').then(m => m.PublicDashboardModule) },
  { path: 'map', loadChildren: () => import('./pages/map/map.module').then(m => m.MapModule) },
  { path: 'content', loadChildren: () => import('./pages/content/content.module').then(m => m.ContentModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
