/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountResponseGeneralPaginationModelResponseModel } from '../models/account-response-general-pagination-model-response-model';
import { AccountWithRelationshipResponseModelPaginationModelResponseModel } from '../models/account-with-relationship-response-model-pagination-model-response-model';

@Injectable({
  providedIn: 'root',
})
export class FriendRelationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithFriendRelationGetFriendListAccountRelatingIdGet
   */
  static readonly InlivewithFriendRelationGetFriendListAccountRelatingIdGetPath = '/inlivewith/FriendRelation/GetFriendList/{accountRelatingId}';

  /**
   * AccountRelating: The Account that being use on the app.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Plain$Response(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationGetFriendListAccountRelatingIdGetPath, 'get');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that being use on the app.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Plain(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * AccountRelating: The Account that being use on the app.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Json$Response(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationGetFriendListAccountRelatingIdGetPath, 'get');
    if (params) {
      rb.path('accountRelatingId', params.accountRelatingId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * AccountRelating: The Account that being use on the app.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Json(params: {
    accountRelatingId: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithFriendRelationGetFriendListAccountRelatingIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet
   */
  static readonly InlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGetPath = '/inlivewith/FriendRelation/SearchFriendLikeNickName/{accountRequestId}/{nickName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Plain$Response(params: {
    accountRequestId: string;
    nickName: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountWithRelationshipResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('nickName', params.nickName, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountWithRelationshipResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Plain(params: {
    accountRequestId: string;
    nickName: string;
    page?: number;
    limit?: number;
  }): Observable<AccountWithRelationshipResponseModelPaginationModelResponseModel> {

    return this.inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountWithRelationshipResponseModelPaginationModelResponseModel>) => r.body as AccountWithRelationshipResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Json$Response(params: {
    accountRequestId: string;
    nickName: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountWithRelationshipResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FriendRelationService.InlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGetPath, 'get');
    if (params) {
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('nickName', params.nickName, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountWithRelationshipResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Json(params: {
    accountRequestId: string;
    nickName: string;
    page?: number;
    limit?: number;
  }): Observable<AccountWithRelationshipResponseModelPaginationModelResponseModel> {

    return this.inlivewithFriendRelationSearchFriendLikeNickNameAccountRequestIdNickNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountWithRelationshipResponseModelPaginationModelResponseModel>) => r.body as AccountWithRelationshipResponseModelPaginationModelResponseModel)
    );
  }

}
