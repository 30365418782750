/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountBirthdayUpdateInputModel } from '../models/account-birthday-update-input-model';
import { AccountFilter } from '../models/account-filter';
import { AccountGuidInputModel } from '../models/account-guid-input-model';
import { AccountHashtagListResponseModel } from '../models/account-hashtag-list-response-model';
import { AccountInputModel } from '../models/account-input-model';
import { AccountObjectUpdateAvatarInputModel } from '../models/account-object-update-avatar-input-model';
import { AccountObjectUpdateInputModel } from '../models/account-object-update-input-model';
import { AccountResponseGeneralPaginationModelResponseModel } from '../models/account-response-general-pagination-model-response-model';
import { AccountResponseGeneralResponseModel } from '../models/account-response-general-response-model';
import { AccountResponseWithRoleInCLubModelResponseModel } from '../models/account-response-with-role-in-c-lub-model-response-model';
import { AccountWithFilterResponsePaginationModelResponseModel } from '../models/account-with-filter-response-pagination-model-response-model';
import { ApplicationUserClaimListResponseModel } from '../models/application-user-claim-list-response-model';
import { ApplicationUserClaimViewModel } from '../models/application-user-claim-view-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { ExportAccountFilter } from '../models/export-account-filter';
import { HashtagPaginationModelResponseModel } from '../models/hashtag-pagination-model-response-model';
import { PlaceResponseModel } from '../models/place-response-model';
import { PlaceUpdateModel } from '../models/place-update-model';
import { ResultSearchAccountViewModelResponseModel } from '../models/result-search-account-view-model-response-model';
import { StringListResponseModel } from '../models/string-list-response-model';
import { StringResponseModel } from '../models/string-response-model';
import { UpdateModel } from '../models/update-model';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithAccountsPageGet
   */
  static readonly InlivewithAccountsPageGetPath = '/inlivewith/Accounts/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsPageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPageGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsPageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPageGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsPageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsPageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPageGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsPageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPageGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsPageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountGetAccountWithFilterPost
   */
  static readonly InlivewithAccountGetAccountWithFilterPostPath = '/inlivewith/Account/GetAccountWithFilter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGetAccountWithFilterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountGetAccountWithFilterPost$Plain$Response(params?: {
    body?: AccountFilter
  }): Observable<StrictHttpResponse<AccountWithFilterResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGetAccountWithFilterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountWithFilterResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGetAccountWithFilterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountGetAccountWithFilterPost$Plain(params?: {
    body?: AccountFilter
  }): Observable<AccountWithFilterResponsePaginationModelResponseModel> {

    return this.inlivewithAccountGetAccountWithFilterPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountWithFilterResponsePaginationModelResponseModel>) => r.body as AccountWithFilterResponsePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGetAccountWithFilterPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountGetAccountWithFilterPost$Json$Response(params?: {
    body?: AccountFilter
  }): Observable<StrictHttpResponse<AccountWithFilterResponsePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGetAccountWithFilterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountWithFilterResponsePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGetAccountWithFilterPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountGetAccountWithFilterPost$Json(params?: {
    body?: AccountFilter
  }): Observable<AccountWithFilterResponsePaginationModelResponseModel> {

    return this.inlivewithAccountGetAccountWithFilterPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountWithFilterResponsePaginationModelResponseModel>) => r.body as AccountWithFilterResponsePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountUpdateAvatarAccountIdPut
   */
  static readonly InlivewithAccountUpdateAvatarAccountIdPutPath = '/inlivewith/Account/UpdateAvatar/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountUpdateAvatarAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAvatarAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountObjectUpdateAvatarInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountUpdateAvatarAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountUpdateAvatarAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAvatarAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountObjectUpdateAvatarInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountUpdateAvatarAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountUpdateAvatarAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAvatarAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountObjectUpdateAvatarInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountUpdateAvatarAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountUpdateAvatarAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAvatarAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountObjectUpdateAvatarInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountUpdateAvatarAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountExportAccountWithFilterPost
   */
  static readonly InlivewithAccountExportAccountWithFilterPostPath = '/inlivewith/Account/ExportAccountWithFilter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountExportAccountWithFilterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountExportAccountWithFilterPost$Plain$Response(params?: {
    body?: ExportAccountFilter
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountExportAccountWithFilterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountExportAccountWithFilterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountExportAccountWithFilterPost$Plain(params?: {
    body?: ExportAccountFilter
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountExportAccountWithFilterPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountExportAccountWithFilterPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountExportAccountWithFilterPost$Json$Response(params?: {
    body?: ExportAccountFilter
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountExportAccountWithFilterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountExportAccountWithFilterPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountExportAccountWithFilterPost$Json(params?: {
    body?: ExportAccountFilter
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountExportAccountWithFilterPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountGetAccountIdentityByEmailEmailGet
   */
  static readonly InlivewithAccountGetAccountIdentityByEmailEmailGetPath = '/inlivewith/Account/GetAccountIdentityByEmail/{email}';

  /**
   * Get account from Identity.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGetAccountIdentityByEmailEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetAccountIdentityByEmailEmailGet$Plain$Response(params: {
    email: string;
  }): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGetAccountIdentityByEmailEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * Get account from Identity.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGetAccountIdentityByEmailEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetAccountIdentityByEmailEmailGet$Plain(params: {
    email: string;
  }): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.inlivewithAccountGetAccountIdentityByEmailEmailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * Get account from Identity.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGetAccountIdentityByEmailEmailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetAccountIdentityByEmailEmailGet$Json$Response(params: {
    email: string;
  }): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGetAccountIdentityByEmailEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * Get account from Identity.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGetAccountIdentityByEmailEmailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetAccountIdentityByEmailEmailGet$Json(params: {
    email: string;
  }): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.inlivewithAccountGetAccountIdentityByEmailEmailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountRefreshTokenPost
   */
  static readonly InlivewithAccountRefreshTokenPostPath = '/inlivewith/Account/RefreshToken';

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountRefreshTokenPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountRefreshTokenPost$Plain$Response(params?: {
    token?: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountRefreshTokenPostPath, 'post');
    if (params) {
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountRefreshTokenPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountRefreshTokenPost$Plain(params?: {
    token?: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountRefreshTokenPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountRefreshTokenPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountRefreshTokenPost$Json$Response(params?: {
    token?: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountRefreshTokenPostPath, 'post');
    if (params) {
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountRefreshTokenPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountRefreshTokenPost$Json(params?: {
    token?: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountRefreshTokenPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountsLikeNicknameNicknameGet
   */
  static readonly InlivewithAccountsLikeNicknameNicknameGetPath = '/inlivewith/Accounts/LikeNickname/{nickname}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsLikeNicknameNicknameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsLikeNicknameNicknameGet$Plain$Response(params: {
    nickname: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsLikeNicknameNicknameGetPath, 'get');
    if (params) {
      rb.path('nickname', params.nickname, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsLikeNicknameNicknameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsLikeNicknameNicknameGet$Plain(params: {
    nickname: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsLikeNicknameNicknameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsLikeNicknameNicknameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsLikeNicknameNicknameGet$Json$Response(params: {
    nickname: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsLikeNicknameNicknameGetPath, 'get');
    if (params) {
      rb.path('nickname', params.nickname, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsLikeNicknameNicknameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsLikeNicknameNicknameGet$Json(params: {
    nickname: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsLikeNicknameNicknameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountByEmailEmailGet
   */
  static readonly InlivewithAccountByEmailEmailGetPath = '/inlivewith/Account/ByEmail/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByEmailEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByEmailEmailGet$Plain$Response(params: {
    email: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByEmailEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByEmailEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByEmailEmailGet$Plain(params: {
    email: string;
    details?: boolean;
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountByEmailEmailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountByEmailEmailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByEmailEmailGet$Json$Response(params: {
    email: string;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountByEmailEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountByEmailEmailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountByEmailEmailGet$Json(params: {
    email: string;
    details?: boolean;
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountByEmailEmailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountsPublicGet
   */
  static readonly InlivewithAccountsPublicGetPath = '/inlivewith/Accounts/Public';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsPublicGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPublicGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsPublicGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsPublicGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPublicGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsPublicGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsPublicGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPublicGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsPublicGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsPublicGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPublicGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsPublicGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountsPrivateGet
   */
  static readonly InlivewithAccountsPrivateGetPath = '/inlivewith/Accounts/Private';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsPrivateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPrivateGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsPrivateGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsPrivateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPrivateGet$Plain(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsPrivateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountsPrivateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPrivateGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountsPrivateGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountsPrivateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountsPrivateGet$Json(params?: {
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAccountsPrivateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralPaginationModelResponseModel>) => r.body as AccountResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountPublicAccountIdPut
   */
  static readonly InlivewithAccountPublicAccountIdPutPath = '/inlivewith/Account/Public/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountPublicAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPublicAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountPublicAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountPublicAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPublicAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountPublicAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountPublicAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPublicAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountPublicAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountPublicAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPublicAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountPublicAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountPrivateAccountIdPut
   */
  static readonly InlivewithAccountPrivateAccountIdPutPath = '/inlivewith/Account/Private/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountPrivateAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPrivateAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountPrivateAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountPrivateAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPrivateAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountPrivateAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountPrivateAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPrivateAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountPrivateAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountPrivateAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountPrivateAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountPrivateAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountHashtagsAccountIdGet
   */
  static readonly InlivewithAccountHashtagsAccountIdGetPath = '/inlivewith/Account/Hashtags/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountHashtagsAccountIdGet$Plain$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountHashtagsAccountIdGet$Plain(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithAccountHashtagsAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountHashtagsAccountIdGet$Json$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountHashtagsAccountIdGet$Json(params: {
    accountId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithAccountHashtagsAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountHashtagsAccountIdPut
   */
  static readonly InlivewithAccountHashtagsAccountIdPutPath = '/inlivewith/Account/Hashtags/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<StrictHttpResponse<AccountHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<AccountHashtagListResponseModel> {

    return this.inlivewithAccountHashtagsAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountHashtagListResponseModel>) => r.body as AccountHashtagListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountHashtagsAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<StrictHttpResponse<AccountHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountHashtagsAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountHashtagsAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountHashtagsAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountGuidInputModel
  }): Observable<AccountHashtagListResponseModel> {

    return this.inlivewithAccountHashtagsAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountHashtagListResponseModel>) => r.body as AccountHashtagListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountClubDetailAccountIdClubIdGet
   */
  static readonly InlivewithAccountClubDetailAccountIdClubIdGetPath = '/inlivewith/Account/Club/Detail/{accountId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubDetailAccountIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubDetailAccountIdClubIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubDetailAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubDetailAccountIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubDetailAccountIdClubIdGet$Plain(params: {
    accountId: string;
    clubId: string;
  }): Observable<AccountResponseWithRoleInCLubModelResponseModel> {

    return this.inlivewithAccountClubDetailAccountIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountClubDetailAccountIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubDetailAccountIdClubIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountClubDetailAccountIdClubIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountClubDetailAccountIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountClubDetailAccountIdClubIdGet$Json(params: {
    accountId: string;
    clubId: string;
  }): Observable<AccountResponseWithRoleInCLubModelResponseModel> {

    return this.inlivewithAccountClubDetailAccountIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountDownloadFilePost
   */
  static readonly InlivewithAccountDownloadFilePostPath = '/inlivewith/Account/DownloadFile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountDownloadFilePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountDownloadFilePost$Plain$Response(params?: {
    body?: string
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountDownloadFilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountDownloadFilePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountDownloadFilePost$Plain(params?: {
    body?: string
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountDownloadFilePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountDownloadFilePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountDownloadFilePost$Json$Response(params?: {
    body?: string
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountDownloadFilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountDownloadFilePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountDownloadFilePost$Json(params?: {
    body?: string
  }): Observable<StringResponseModel> {

    return this.inlivewithAccountDownloadFilePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountUpdateByAdministratorsIdPut
   */
  static readonly InlivewithAccountUpdateByAdministratorsIdPutPath = '/inlivewith/Account/UpdateByAdministrators/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountUpdateByAdministratorsIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateByAdministratorsIdPut$Plain$Response(params: {
    id: string;
    body?: UpdateModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountUpdateByAdministratorsIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountUpdateByAdministratorsIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateByAdministratorsIdPut$Plain(params: {
    id: string;
    body?: UpdateModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountUpdateByAdministratorsIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountUpdateByAdministratorsIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateByAdministratorsIdPut$Json$Response(params: {
    id: string;
    body?: UpdateModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountUpdateByAdministratorsIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountUpdateByAdministratorsIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateByAdministratorsIdPut$Json(params: {
    id: string;
    body?: UpdateModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountUpdateByAdministratorsIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountUpdateUserClaimAsyncIdPut
   */
  static readonly InlivewithAccountUpdateUserClaimAsyncIdPutPath = '/inlivewith/Account/UpdateUserClaimAsync/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountUpdateUserClaimAsyncIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateUserClaimAsyncIdPut$Plain$Response(params: {
    id: string;
    body?: Array<ApplicationUserClaimViewModel>
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountUpdateUserClaimAsyncIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountUpdateUserClaimAsyncIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateUserClaimAsyncIdPut$Plain(params: {
    id: string;
    body?: Array<ApplicationUserClaimViewModel>
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountUpdateUserClaimAsyncIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountUpdateUserClaimAsyncIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateUserClaimAsyncIdPut$Json$Response(params: {
    id: string;
    body?: Array<ApplicationUserClaimViewModel>
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountUpdateUserClaimAsyncIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountUpdateUserClaimAsyncIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateUserClaimAsyncIdPut$Json(params: {
    id: string;
    body?: Array<ApplicationUserClaimViewModel>
  }): Observable<BooleanResponseModel> {

    return this.inlivewithAccountUpdateUserClaimAsyncIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountBirthdayAccountIdPut
   */
  static readonly InlivewithAccountBirthdayAccountIdPutPath = '/inlivewith/Account/Birthday/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountBirthdayAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountBirthdayAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountBirthdayUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountBirthdayAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountBirthdayAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountBirthdayAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountBirthdayUpdateInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountBirthdayAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountBirthdayAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountBirthdayAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountBirthdayUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountBirthdayAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountBirthdayAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountBirthdayAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountBirthdayUpdateInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountBirthdayAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountGetUserClaimAsyncIdGet
   */
  static readonly InlivewithAccountGetUserClaimAsyncIdGetPath = '/inlivewith/Account/GetUserClaimAsync/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGetUserClaimAsyncIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetUserClaimAsyncIdGet$Plain$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<ApplicationUserClaimListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGetUserClaimAsyncIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserClaimListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGetUserClaimAsyncIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetUserClaimAsyncIdGet$Plain(params: {
    id: string;
  }): Observable<ApplicationUserClaimListResponseModel> {

    return this.inlivewithAccountGetUserClaimAsyncIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserClaimListResponseModel>) => r.body as ApplicationUserClaimListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGetUserClaimAsyncIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetUserClaimAsyncIdGet$Json$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<ApplicationUserClaimListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGetUserClaimAsyncIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserClaimListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGetUserClaimAsyncIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetUserClaimAsyncIdGet$Json(params: {
    id: string;
  }): Observable<ApplicationUserClaimListResponseModel> {

    return this.inlivewithAccountGetUserClaimAsyncIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserClaimListResponseModel>) => r.body as ApplicationUserClaimListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountGetUserRoleAsyncEmailGet
   */
  static readonly InlivewithAccountGetUserRoleAsyncEmailGetPath = '/inlivewith/Account/GetUserRoleAsync/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGetUserRoleAsyncEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetUserRoleAsyncEmailGet$Plain$Response(params: {
    email: string;
  }): Observable<StrictHttpResponse<StringListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGetUserRoleAsyncEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGetUserRoleAsyncEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetUserRoleAsyncEmailGet$Plain(params: {
    email: string;
  }): Observable<StringListResponseModel> {

    return this.inlivewithAccountGetUserRoleAsyncEmailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringListResponseModel>) => r.body as StringListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountGetUserRoleAsyncEmailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetUserRoleAsyncEmailGet$Json$Response(params: {
    email: string;
  }): Observable<StrictHttpResponse<StringListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountGetUserRoleAsyncEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountGetUserRoleAsyncEmailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAccountGetUserRoleAsyncEmailGet$Json(params: {
    email: string;
  }): Observable<StringListResponseModel> {

    return this.inlivewithAccountGetUserRoleAsyncEmailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringListResponseModel>) => r.body as StringListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountUpdateAdressIdPlaceIdPut
   */
  static readonly InlivewithAccountUpdateAdressIdPlaceIdPutPath = '/inlivewith/Account/UpdateAdress/{id}/{placeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountUpdateAdressIdPlaceIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAdressIdPlaceIdPut$Plain$Response(params: {
    id: string;
    placeId: string;
    body?: PlaceUpdateModel
  }): Observable<StrictHttpResponse<PlaceResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountUpdateAdressIdPlaceIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('placeId', params.placeId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlaceResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountUpdateAdressIdPlaceIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAdressIdPlaceIdPut$Plain(params: {
    id: string;
    placeId: string;
    body?: PlaceUpdateModel
  }): Observable<PlaceResponseModel> {

    return this.inlivewithAccountUpdateAdressIdPlaceIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PlaceResponseModel>) => r.body as PlaceResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountUpdateAdressIdPlaceIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAdressIdPlaceIdPut$Json$Response(params: {
    id: string;
    placeId: string;
    body?: PlaceUpdateModel
  }): Observable<StrictHttpResponse<PlaceResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountUpdateAdressIdPlaceIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('placeId', params.placeId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlaceResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountUpdateAdressIdPlaceIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAdressIdPlaceIdPut$Json(params: {
    id: string;
    placeId: string;
    body?: PlaceUpdateModel
  }): Observable<PlaceResponseModel> {

    return this.inlivewithAccountUpdateAdressIdPlaceIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PlaceResponseModel>) => r.body as PlaceResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAccountUpdateAccountIdPut
   */
  static readonly InlivewithAccountUpdateAccountIdPutPath = '/inlivewith/Account/Update/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountUpdateAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAccountIdPut$Plain$Response(params: {
    accountId: string;
    body?: AccountObjectUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountUpdateAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountUpdateAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAccountIdPut$Plain(params: {
    accountId: string;
    body?: AccountObjectUpdateInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountUpdateAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAccountUpdateAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAccountIdPut$Json$Response(params: {
    accountId: string;
    body?: AccountObjectUpdateInputModel
  }): Observable<StrictHttpResponse<AccountResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.InlivewithAccountUpdateAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAccountUpdateAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAccountUpdateAccountIdPut$Json(params: {
    accountId: string;
    body?: AccountObjectUpdateInputModel
  }): Observable<AccountResponseGeneralResponseModel> {

    return this.inlivewithAccountUpdateAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseGeneralResponseModel>) => r.body as AccountResponseGeneralResponseModel)
    );
  }

}
