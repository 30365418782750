import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export enum EEvent {
  GET_NOTI = 'GET_NOTI',
  UPDATE_NOTI = 'UPDATE_NOTI',
  CREATE_POST = 'CREATE_POST',
  CREATE_COMMENT = 'CREATE_COMMENT',
  OPEN_POST = 'OPEN_POST',
  END_POST = 'END_POST',
  PLAY_AUDIO = 'PLAY_AUDIO',
  CHANGE_TOTALCOMMENTS = 'CHANGE_TOTALCOMMENTS',
  PLAY_VIDEO = 'PLAY_VIDEO',
}

export interface IEvent {
  action: EEvent,
  payload: any
}

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private subjet = new ReplaySubject<IEvent>();

  constructor() { }

  push(data: IEvent) {
    this.subjet.next(data);
  }

  receive() {
    return this.subjet;
  }
}
