<div class="flex w-100">
  <div class="w-70">
    <div class="card">
      <div class="flex justify-content-between">
        <div>
          <span class="text-bold">{{isPendingFromNewCeleb ? 'Formulaire' : 'Profil Célébrité'}}</span>
        </div>
        <div>
          <span *ngIf="!editCelebInfo" class="cursor-pointer text-gray" (click)="editCelebInfo = true"> Modifier</span>
        </div>
      </div>

      <div class="mt-4 mb-4">
        <!-- <div class="zone-avt">
          <img class="fit-size" [src]="celeb.avatarUrl ?? defaultAvatar" alt="">
        </div> -->
      </div>

      <div class="flex w-100">
        <div class="flex flex-column w-100">
          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-20 text-bold align-items-center">{{'LIST_CLUB_NAME' | translate}}</div>
            <div class="flex mt-2 mb-2 w-80 align-items-center">
              <span *ngIf="!editCelebInfo">
                {{celeb.lastName}}
              </span>
              <input *ngIf="editCelebInfo" type="text" style="width: 500px;" class="p-inputtext-sm" pInputText [value]="celeb.lastName"
                [placeholder]="celeb.lastName" [(ngModel)]="lastName" />
            </div>
          </div>

          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-20 text-bold align-items-center">{{'FIRST_NAME' | translate}}</div>
            <div class="flex mt-2 mb-2 w-80 align-items-center">
              <span *ngIf="!editCelebInfo">
                {{celeb.firstName}}
              </span>
              <input *ngIf="editCelebInfo" type="text" style="width: 500px;" class="p-inputtext-sm" pInputText
                [value]="celeb.firstName" [placeholder]="celeb.firstName" [(ngModel)]="firstName" />
            </div>
          </div>

          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-20 text-bold align-items-center">{{'DISPLAY_NAME' | translate}}</div>
            <div class="flex mt-2 mb-2 w-80 align-items-center">
              <span *ngIf="!editCelebInfo">
                {{celeb.displayName}}
              </span>
              <input *ngIf="editCelebInfo" type="text" style="width: 500px;" class="p-inputtext-sm" pInputText
                [value]="celeb.displayName" [placeholder]="celeb.displayName" [(ngModel)]="displayName" />
              <span *ngIf="showErrorDisplayName" class="text-red ml-2 fs-12">
                Le nom de scène a existé
              </span>
            </div>
          </div>

          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-20 text-bold align-items-center">{{'EMAIL' | translate}}</div>
            <div class="flex mt-2 mb-2 w-80 align-items-center">
              <span *ngIf="!editCelebInfo">
                {{celeb.email}}
              </span>
              <input *ngIf="editCelebInfo" type="text" style="width: 500px;" class="p-inputtext-sm" [disabled]="true"
                pInputText [value]="celeb.email" />
            </div>
          </div>

          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-20 text-bold align-items-center">{{'PASSWORD' | translate}}</div>
            <div class="flex mt-2 mb-2 w-80 align-items-center">
              *************
              <span class="ml-5 text-resend-link cursor-pointer align-items-center">
                {{'SEND_LINK_RESET_PASSWORD' | translate}}
              </span>
            </div>
          </div>

          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-20 text-bold align-items-center">{{'NUMBER_TELEPHONE' | translate}}</div>
            <div class="flex mt-2 mb-2 w-80 align-items-center">
              <span *ngIf="!editCelebInfo">
                {{celeb.phoneNumber}}
              </span>
              <input *ngIf="editCelebInfo" type="text" style="width: 500px;" class="p-inputtext-sm" pInputText
                [value]="celeb.phoneNumber" [placeholder]="celeb.phoneNumber" [(ngModel)]="phoneNumber" />
            </div>
          </div>

          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-20 text-bold align-items-center">{{'NUMBER_SOCIETY' | translate}}</div>
            <div class="flex mt-2 mb-2 w-80 align-items-center">
              <span *ngIf="!editCelebInfo">
                {{celeb.companyName}}
              </span>
              <input *ngIf="editCelebInfo" type="text" style="width: 500px;" class="p-inputtext-sm" pInputText
                [value]="celeb.companyName" [placeholder]="celeb.companyName" [(ngModel)]="companyName" />
            </div>
          </div>

          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-20 text-bold align-items-center">{{'NUMBER_SIRET' | translate}}</div>
            <div class="flex mt-2 mb-2 w-80 align-items-center">
              <span *ngIf="!editCelebInfo">
                {{celeb.siret}}
              </span>
              <input *ngIf="editCelebInfo" type="text" style="width: 500px;" class="p-inputtext-sm" pInputText
                [value]="celeb.siret" [(ngModel)]="siret" [placeholder]="celeb.siret" />
            </div>
          </div>

          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-20 text-bold align-items-center">{{'IBAN_SWIFT_BIC' | translate}}</div>
            <div class="flex mt-2 mb-2 w-80 align-items-center">
              <span *ngIf="!editCelebInfo">
                {{celeb.iban}}
              </span>
              <input *ngIf="editCelebInfo" type="text" style="width: 500px;" class="p-inputtext-sm" pInputText
                [value]="celeb.iban" [(ngModel)]="iBan" [placeholder]="celeb.iban" />
            </div>
          </div>

          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-20 text-bold align-items-center">{{'NUMBER_TVA' | translate}}</div>
            <div class="flex mt-2 mb-2 w-80 align-items-center">
              <span *ngIf="!editCelebInfo">
                {{celeb.vat}}
              </span>
              <input *ngIf="editCelebInfo" type="text" style="width: 500px;" class="p-inputtext-sm" pInputText
                [value]="celeb.vat" [(ngModel)]="vat" [placeholder]="celeb.vat" />
            </div>
          </div>

          <div class="flex w-100">
            <div class="flex mt-2 mb-2 w-20 text-bold align-items-center">{{'ADDRESS_SOCIETY' | translate}}</div>
            <div class="flex mt-2 mb-2 w-80 align-items-center">
              <span *ngIf="!editCelebInfo">
                {{celeb.companyAddressDetail}}
              </span>
              <div class="position-relative flex align-items-center" *ngIf="editCelebInfo">
                <input type="text" style="width: 500px;" class="p-inputtext-sm" pInputText
                  [value]="celeb.companyAddressDetail" [placeholder]="celeb.companyAddressDetail"
                  [(ngModel)]="addressComp" [disabled]="true" />
                <i class="pi pi-pencil position-absolute cursor-pointer" (click)="openMap()"
                  style="right: 10px; color: #a1a7c4;"></i>
              </div>
            </div>
          </div>

          <div class="flex w-100 justify-content-end" *ngIf="editCelebInfo">
            <button pButton type="button" label="{{'CANCEL' | translate}}"
              class="p-button-outlined p-button-secondary mr-2" (click)="editCelebInfo = false"></button>
            <button pButton type="button" label="{{'REGISTER' | translate}}"
              class="p-button-outlined p-button-secondary mr-2 btn-save" (click)="editCeleb()"></button>
          </div>
        </div>
      </div>
    </div>


    <!-- <div class="card" *ngIf="editCelebInfo">
      <ng-container>
        <div class="flex">
          <div class="w-100 text-bold">

            <div class="flex mb-3 mt-3">
              <span> Profil Célébrité </span>
            </div>

            <div class="flex align-items-center">
              <div class="mt-2 mb-3">{{'LIST_CLUB_NAME' | translate}}</div>
              <input type="text" class="p-inputtext-sm ml-3" pInputText [value]="celeb.lastName"
                [placeholder]="celeb.lastName" [(ngModel)]="lastName" />
            </div>

            <div class="flex align-items-center">
              <div class="mt-3 mb-3" (click)="editCelebInfo = false">{{'FIRST_NAME' | translate}} </div>
              <input type="text" class="p-inputtext-sm ml-3" pInputText [value]="celeb.firstName"
                [placeholder]="celeb.firstName" [(ngModel)]="firstName" />
            </div>
            <div class="flex align-items-center">
              <div class="mt-3 mb-3">{{'DISPLAY_NAME' | translate}}</div>
              <input type="text" class="p-inputtext-sm ml-3" pInputText [value]="celeb.displayName"
                [placeholder]="celeb.displayName" [(ngModel)]="displayName" />
              <span *ngIf="showErrorDisplayName" class="text-red ml-2 fs-12">
                Le nom de scène a existé
              </span>
            </div>
            <div class="flex align-items-center">
              <div class="mt-3 mb-3">{{'EMAIL' | translate}} </div>
              <input type="text" class="p-inputtext-sm ml-3" [disabled]="true" pInputText [value]="celeb.email" />
            </div>

            <div class="flex align-items-center">
              <div class="mt-3 mb-3">{{'PASSWORD' | translate}} </div>
              <span class="ml-5 text-resend-link cursor-pointer">
                {{'SEND_LINK_RESET_PASSWORD' | translate}}
              </span>
            </div>

            <div class="flex align-items-center">
              <div class="mt-3 mb-3">{{'NUMBER_TELEPHONE' | translate}} </div>
              <input type="text" class="p-inputtext-sm ml-3" pInputText [value]="celeb.phoneNumber"
                [placeholder]="celeb.phoneNumber" [(ngModel)]="phoneNumber" />
            </div>

            <div class="flex align-items-center">
              <div class="mt-3 mb-3">{{'NUMBER_SOCIETY' | translate}} </div>
              <input type="text" class="p-inputtext-sm ml-3" pInputText [value]="celeb.companyName"
                [placeholder]="celeb.companyName" [(ngModel)]="companyName" />
            </div>

            <div class="flex align-items-center">
              <div class="mt-3 mb-3">{{'NUMBER_SIRET' | translate}} </div>
              <input type="text" class="p-inputtext-sm ml-3" pInputText [value]="celeb.siret" [(ngModel)]="siret"
                [placeholder]="celeb.siret" />
            </div>

            <div class="flex align-items-center">
              <div class="mt-3 mb-3">{{'IBAN_SWIFT_BIC' | translate}} </div>
              <input type="text" class="p-inputtext-sm ml-3" pInputText [value]="celeb.iban" [(ngModel)]="iBan"
                [placeholder]="celeb.iban" />
            </div>

            <div class="flex align-items-center">
              <div class="mt-3 mb-3">{{'NUMBER_TVA' | translate}} </div>
              <input type="text" class="p-inputtext-sm ml-3" pInputText [value]="celeb.vat" [(ngModel)]="vat"
                [placeholder]="celeb.vat" />
            </div>

            <div class="flex align-items-center">
              <div class="mt-3 mb-3">{{'ADDRESS_SOCIETY' | translate}} </div>
              <div class="position-relative flex align-items-center">
                <input type="text" style="width: 500px;" class="p-inputtext-sm ml-3" pInputText
                  [value]="celeb.companyAddressDetail" [placeholder]="celeb.companyAddressDetail"
                  [(ngModel)]="addressComp" [disabled]="true" />
                <i class="pi pi-pencil position-absolute cursor-pointer" (click)="openMap()"
                  style="right: 10px; color: #a1a7c4;"></i>
              </div>
            </div>

          </div>

        </div>
        <div class="flex w-100 justify-content-end">
          <button pButton type="button" label="{{'CANCEL' | translate}}"
            class="p-button-outlined p-button-secondary mr-2" (click)="editCelebInfo = false"></button>
          <button pButton type="button" label="{{'REGISTER' | translate}}"
            class="p-button-outlined p-button-secondary mr-2 btn-save" (click)="editCeleb()"></button>
        </div>
      </ng-container>
    </div> -->

    <div class="card" *ngIf="!openPopupCeleb && !isPendingFromNewCeleb">
      <div class="flex justify-content-between">
        <div>
          <span class="text-bold">
            {{'PLATFORM_ACTIVATED' | translate}}
          </span>
        </div>
        <div>
          <span class="cursor-pointer text-gray" (click)="editPlatform = true"> Modifier</span>
        </div>
      </div>

      <div class="mt-4 mb-4" *ngIf="platform">
        <div class="flex flex-column mt-4">
          <div class="flex align-items-center mt-2"><p-checkbox class="mr-3" name="optionChoosePlatform"
              [(ngModel)]="platform" [value]="'INLIVE_STREAM'"></p-checkbox>
            {{'INLIVE_STREAM' | translate}}
          </div>
          <div class="flex align-items-center mt-2" name="optionChoosePlatform"><p-checkbox class="mr-3"
              [(ngModel)]="platform" [value]="'INLIVE_TICKETS'"></p-checkbox>
            {{'INLIVE_TICKETS' | translate}}
          </div>
          <div class="flex align-items-center mt-2" name="optionChoosePlatform"><p-checkbox class="mr-3"
              [(ngModel)]="platform" [value]="'INLIVE_ME'"></p-checkbox>
            {{'INLIVE_ME' | translate}}
          </div>
          <div class="flex align-items-center mt-2" name="optionChoosePlatform"><p-checkbox class="mr-3"
              [(ngModel)]="platform" [value]="'INLIVE_STORE'"></p-checkbox>
            {{'INLIVE_STORE' | translate}}
          </div>
        </div>
      </div>

      <div class="flex w-100 justify-content-end" *ngIf="editPlatform">
        <button pButton type="button" label="{{'CANCEL' | translate}}" class="p-button-outlined p-button-secondary mr-2"
          (click)="editPlatform = false"></button>
        <button pButton type="button" label="{{'REGISTER' | translate}}"
          class="p-button-outlined p-button-secondary mr-2 btn-save" (click)="editPlatformFunc()"></button>
      </div>

    </div>
  </div>

  <div class="w-30">
    <div class="card-1">
      <div class="flex justify-content-center">
        <div class="zone-avt">
          <img class="fit-size" [src]="celeb.avatarUrl ?? defaultAvatar" alt="">
        </div>
      </div>

      <div class="flex flex-column justify-content-center align-items-center mt-3">
        <div class="text-bold">
          {{celeb.displayName}}
        </div>
        <div>
          <span class="text-bold">
            {{(isPendingFromNewCeleb ? 'SENT_IT' : 'CERTIFIED_SINCE') | translate}}
          </span>
          {{ celeb.dateUpdated + 'Z' | date:'dd/MM/YYYY'}}
        </div>
      </div>

      <div class="flex flex-column" *ngIf="!isPendingFromNewCeleb">
        <div class="flex mt-3 fs-14">
          <div class="flex justify-content-center align-items-center btn-email blue cursor-pointer">
            <i class="pi pi-envelope"></i>
          </div>
          <button pButton label="{{'SEND_MESSAGE' | translate}}"
            class="w-100 p-button-outlined blue mb-1 p-button-secondary mr-2"></button>
        </div>
        <div class="fs-14">

          <button pButton label="{{'DELETE' | translate}}"
            class="w-100 p-button-outlined red mt-1 p-button-secondary mr-2"></button>
        </div>
      </div>
    </div>

    <div class="card-2">
      <div class="flex justify-content-between">
        <div>
          <span class="text-bold">{{'LIST_CLUB_CATEGORY' | translate}}</span>
        </div>
        <div>
          <span class="cursor-pointer text-gray">{{'EDIT' | translate}}</span>
        </div>
      </div>

      <!-- <div class="mt-5">
        <span>
          Compte privé
        </span>
      </div> -->
    </div>

    <div class="card-2" *ngIf="isPendingFromNewCeleb">
      <div class="flex justify-content-between">
        <div>
          <span class="text-bold">#célébrité</span>
        </div>
      </div>

      <div class="mt-3">
        <span>
          {{'YOU_MUST_ASSOCIATE_OR_CREATE_HASHTAG' | translate}}
        </span>
      </div>

      <div class="flex align-items-center cursor-pointer mt-3"
        style="background-color: #e6deda; width: 100%; height: 46px;" (click)="openPopupCeleb = true">
        <div class="flex align-items-center p-2">
          <span *ngIf="isPicked">
            {{selectedCeleb}}
          </span>
          <div *ngIf="celeb.alias!.length! > 0 && !isPicked">
            <div class="flex align-items-center p-2" *ngFor="let nameAlias of celeb.alias">
              <span>
                {{nameAlias}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <span class="text-red" *ngIf="showErrorAddCeleb">
          {{'SELECTION_CELEB_REQUIRE' | translate}}
        </span>
        <span class="text-red" *ngIf="errorAfterLinked">
          {{'CELEBRITY_HAS_BEEN_LINKED_TO_OTHER_ACCOUNT' | translate}}
        </span>
      </div>
    </div>

    <div class="card-2" *ngIf="!isPendingFromNewCeleb">
      <div class="flex justify-content-between">
        <div>
          <span class="text-bold">{{'THEMATIC' | translate}}</span>
        </div>
        <div>
          <span class="cursor-pointer text-gray" (click)="openPopupCeleb = true">{{'EDIT' | translate}}</span>
        </div>
      </div>

      <div class="mt-5">
        <span>
          Si le nom de la célébrité a déjà été créé par un membre, il faut associer ce nom au compte célébrité
        </span>
      </div>

      <div class="flex align-items-center mt-3 cursor-pointer"
        style="background-color: #e6deda; width: 200px; height: 35px;">
        <div class="flex align-items-center p-2">
          <span *ngIf="isPicked">
            {{selectedCeleb}}
          </span>
          <div *ngIf="!isPicked">
            <div class="flex align-items-center p-2" *ngFor="let nameAlias of celeb.alias">
              <span>
                {{nameAlias}}
              </span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-container *ngIf="openPopupCeleb">
  <app-list-celebs [(openPopupListCeleb)]="openPopupCeleb" (selectedCelebChange)="selectedCelebChange($event)"
    [celebAlias]="celeb.alias!" [title]="'#célébrité'" [titleNextAction]="'REGISTER' | translate"
    [currentCelebIsPicked]="celeb" [isPendingFromNewCeleb]="isPendingFromNewCeleb"></app-list-celebs>
</ng-container>

<ng-container *ngIf="editCelebInfo">

</ng-container>
