<div class="card">
  <div class="zone-avatar" [id]="inliver.id" [ngClass]="{selected: inliver.id == accountId}">
    <img class="fit-size" [src]="inliver.avatar ?? defaultAvatar">
  </div>
  <div class="mt-2">
    <div class="text-bold text-gray">
      {{inliver.nickName! | limitString:false:20}}
    </div>
  </div>
  <div class="mt-2">
    {{inliver.totalCommondClub || 0}} <span>Clubs en commun</span>
  </div>
</div>
