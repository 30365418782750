import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { CommentPostResponseModel, PostAdminResponseModel, ReactionPostResponseModel } from 'src/app/core/api/be/models';
import { PostService } from 'src/app/core/api/be/services';
import { MENTION_REGEX, URL_REGEX, defaultAvatar } from 'src/app/core/constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-card-post-inliveteam',
  templateUrl: './card-post-inliveteam.component.html',
  styleUrls: ['./card-post-inliveteam.component.scss']
})
export class CardPostInliveteamComponent implements OnInit {

  @Input() post!: PostAdminResponseModel
  @Input() selectedPost!: PostAdminResponseModel
  @Input() checkClicked: boolean = false;
  @Input() isShowDeletePost: boolean = true;

  @Output() postDeleted = new EventEmitter<string>();
  @Output() postEditting = new EventEmitter();
  @Output() selectedPostChange = new EventEmitter();
  @Output() listCommentsPost = new EventEmitter<CommentPostResponseModel[]>()
  @Output() listReactsPost = new EventEmitter<ReactionPostResponseModel[]>();

  fadePost: boolean = false;
  defaultAvatar = defaultAvatar

  show: boolean = false;
  openLikesZone: boolean = false;
  openComentsZone: boolean = false;
  completeText!: boolean;

  constructor(
    private _translateService: TranslateService,
    private _postService: PostService
  ) { }

  ngOnInit(): void {
    // this.getListComments(this.selectedPost.id!)
    // this.getListReacts(AuthService.GetAccountInfo().id!, this.selectedPost.id!);
  }

  selectedPostEvent() {
    if (this.isShowDeletePost) {
      this.getListComments(this.post.id!)
      this.getListReacts(AuthService.GetAccountInfo().id!, this.post.id!);

      this.selectedPostChange.emit(this.post!);
    }
  }

  getListComments(postId: string) {
    this._postService.inlivewithPostGetCommentsOnPostPostIdGet$Json({
      postId: postId,
      limit: 10000
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.listCommentsPost.emit(res.data?.page!)

          let totalComments = 0;
          res.data?.page?.forEach(x => totalComments = totalComments + x.inverseParent?.length!);
          this.post.totalComment = totalComments + res.data?.page?.map(x => x.id).length!
        }
      })
    ).subscribe();
  }

  getListReacts(accountId: string, postId: string) {
    this._postService.inlivewithPostGetReactOnPostPostIdGet$Json({
      postId: postId,
      limit: 10000
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.listReactsPost.emit(res.data?.page!)
          this.post.totalReact = res.data?.totalItems!
        }
      })
    ).subscribe();
  }

  timeAgo = (date: string) => {
    const timeNow = moment().utc().format('YYYY-MM-DDTHH:mm:ss');
    const parsedDate = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    const currentLang =
      LocalStorageService.GetLocalStorage('language').toLowerCase();

    if (moment.utc().diff(date, 'days') >= 7) {
      return moment
        .utc(date)
        .locale(currentLang ? currentLang : 'fr')
        .format('L');
    }

    /**
     *  If the post has been created less than a minute ago
     */
    if (moment(timeNow).diff(moment(parsedDate), 'seconds') <= 60) {
      return this._translateService.instant('POST_DATE_NOW');
    }

    //   moment.updateLocale('fr', {
    //     relativeTime : {
    //         past:   "%s",
    //         s  : '%d s',
    //         ss : '%d s',
    //         m:  "%d m",
    //         mm: "%d m",
    //         h:  "an h",
    //         hh: "%d h",
    //         d:  "%d j",
    //         dd: "%d j",
    //         M:  "%d mois",
    //         MM: "%d mois",
    //         y:  "%d an",
    //         yy: "%d années"

    let text = moment
      .utc(date)
      .locale(currentLang ? currentLang : 'fr')
      .fromNow();

    text = text.includes('il y a ')
      ? text
        .replace('il y a ', '')
        .replace('une', ' 1')
        .replace('jour', ' j')
        .replace('minute', ' min')
        .replace('heure', ' h')
        .replace('s', '')
        .replace('quelque secondes', '1s')
        .replace('un', ' 1')
      : text;

    return text;
  };

  deletePost(event: Event) {
    event.stopPropagation();
    // this.fadePost = true;
    this.selectedPostChange.emit(this.post!);
    this.postDeleted.emit(this.post.id);
  }

  editPost(event: Event) {
    event.stopPropagation();
    this.selectedPostChange.emit(this.post!);
    this.postEditting.emit(true)
  }

  urlify(text: any) {
    // let hashtagRegex = /\B#\w+/g;

    text = text?.replace(MENTION_REGEX, (username: string) => {
      return `<a target="_blank">${username}</a>`;
    });

    return text?.replace(URL_REGEX, (url: any) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  }

  handleText() {
    this.show = !this.show;
    if (this.show) {
      this.completeText = true;
    } else {
      this.completeText = false;
    }
  }


}
