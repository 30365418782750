import { Injectable } from '@angular/core';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
} from '@microsoft/signalr';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { NotificationResponse } from '../api/be/models';

@Injectable({
  providedIn: 'root',
})
export class NotificationServicee {
  public hubConnection!: HubConnection;
  messages: Subject<string> = new Subject();
  conected$ = new ReplaySubject();

  isReloadPage!: boolean
  isReloadPost!: any
  isCountNotiNotRead!: boolean;

  public isReloadPage$$ = new BehaviorSubject<boolean>(this.isReloadPage);
  public isReloadPost$$ = new BehaviorSubject<any>(this.isReloadPost);
  public isCountNotiNotRead$$ = new BehaviorSubject<boolean>(this.isCountNotiNotRead);

  public listIdPostForNotiDiscussion: any[] = [];

  constructor(
    private messageService: MessageService,
    private _translateService: TranslateService
  ) { }

  init() {
    const id = AuthService.GetAccountInfo().id!;
    const endpoint = `${environment.API_URL}/inlivewith/BackOffice/GetNotificationForBO?isRead=false&limit=1`;
    const url = `${environment.SIGNALR_URL}/signalr?userId=${id}&appType=ILWITH&action=POST&endpoint=${endpoint}`;
    const opt = {
      transport: HttpTransportType.LongPolling | HttpTransportType.WebSockets,
    };

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(url, opt)
      .withAutomaticReconnect()
      .configureLogging(2)
      .build();

    this.hubConnection.onclose(() => {
      console.log('[Developer] SignalR for Chat is "Closed"');
    });

    this.hubConnection.onreconnecting(() => {
      console.log('[Developer] SignalR for Chat is "Closed"');
    });
  }

  connectHub() {
    this.hubConnection
      .start()
      .then(() => {
        console.log('SignalrService: Successful connection message');
        this.conected$.next();
      })
      .catch(() => {
        console.log('SignalrService: Error while establishing connection');
      });
  }

  onInliveGroup(callback: any) {
    this.hubConnection.on('BroadcastInliveGroup', (data) => {
      let accountIds: string[] = data['data']['accountIds'];
      let conversationIds: string[] = data['data']['conversationIds'];
      let isOnline: boolean = data['type'] == 2; // type isonline = 2
      callback(accountIds, conversationIds, isOnline);
    });

    this.hubConnection.onreconnected((data) => {
      console.log('[Developer] SignalR for Chat is "Reconnected"');
      callback(data);
    });
  }

  onNotification(callback: any) {
    this.hubConnection.on('BroadcastNotification', (data) => {
      let mes: NotificationResponse = data['data'];
      mes = _.mapKeys(mes, (value, key) => _.camelCase(key));
      callback(mes);
    });

    this.hubConnection.onreconnected((data) => {
      console.log('[Developer] SignalR for Notification is "Reconnected"');
      callback(data);
    });
  }

  joinGroup(groupName: string) {
    this.hubConnection.invoke('JoinGroup', groupName);
  }

  leaveGroup(groupName: string) {
    this.hubConnection.invoke('LeaveGroup', groupName);
  }

  sendMessage<T>(groupName: string, accountId: string, value: T) {
    this.hubConnection.invoke(
      'SendMessageToGroup',
      groupName,
      accountId,
      value
    );
  }

  onReceiveMessage(callback: any) {
    this.hubConnection.on('SendMessageToGroup', (accountId, value) => {
      callback(accountId, value);
    });
  }

  showNotiDiscussion(data: any) {
    this.messageService.clear();

    this.messageService.add({
      severity: 'success',
      summary: JSON.parse(data?.content!)!.Title!,
      detail: JSON.parse(data?.content!)!.SubTitle!,
      key: 'noti-comment-post-discussion',
      data: data,
      closable: false,
    });
  }
}
