import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountResponseWithRoleInCLubModel, CommentPostResponseModel, MediaPostResponseModel } from 'src/app/core/api/be/models';
import { PostService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';
import { LimitStringPipe } from 'src/app/core/pipe/limit-string.pipe';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-list-comments',
  templateUrl: './list-comments.component.html',
  styleUrls: ['./list-comments.component.scss']
})
export class ListCommentsComponent implements OnInit {

  @Input() listMember: AccountResponseWithRoleInCLubModel[] = [];
  @Input() listCommentsPost: CommentPostResponseModel[] = [];
  @Input() commentFromPostInliveteam: boolean = false;
  @Input() commentPostDiscussion: boolean = false;

  @Output() deleteCommentEvent = new EventEmitter();

  defaultAvatar = defaultAvatar;

  authId = AuthService.GetAccountInfo().id!

  completeText!: boolean;
  show: boolean = false;
  listIsLandscape: boolean[] = [];
  showConfirmDeleteComment: boolean = false;

  commentSelected!: CommentPostResponseModel;

  constructor(
    private _postService: PostService
  ) { }

  ngOnInit(): void {
  }

  checkOverflow(id: string): boolean {
    const elem = document.getElementById(id);
    console.log(elem);

    if (elem) {
      return elem.offsetWidth < elem.scrollWidth;
    } else {
      return false;
    }
  }

  scrollTo(id: string, isLeft: boolean = true) {
    let a = document.getElementById(id)!;
    a.scrollLeft = isLeft ? a.scrollLeft - 400 : a.scrollLeft + 400;
  }

  detachMention(username: string) {
    let uname = username!.replace('@', '');
    let user = this.listMember?.filter(
      (user) => user!.nickName?.replace(' ', '_') == uname
    )[0];

    if (user) {
      return `<a href="account-info?accountId=${user.id}" target="_blank">${username}</a>`;
    } else {
      return `<a href="research?searchText=${username
        .replace('@', '')
        .replace('_', ' ')}" target="_blank">${username}</a>`;
    }
  }

  urlify(text: any) {
    var translatePipe = new LimitStringPipe();

    let mentionRegex = /\B@\w+/g;

    text = text?.replace(mentionRegex, (username: any) => {
      return this.detachMention(username);
    });

    let hashtagRegex = /\B#\w+/g;

    text = text?.replace(hashtagRegex, (hashtag: any) => {
      return `<a href="research?searchText=${hashtag.replace(
        '#',
        ''
      )}" target="_blank">${hashtag}</a>`;
    });

    var urlRegex =
      /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    return text?.replace(urlRegex, (url: any) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  }

  openAccountInfo() {

  }

  getUrl(asset: MediaPostResponseModel) {
    if (!asset?.isProcessed) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return asset?.url!;
        }
      }
    } else {
      if (asset?.url!.split('?')[1].startsWith('i')) {
        return asset?.url!.replace('/file.', '/thumb.')
      }

      if (asset?.url!.split('?')[1].startsWith('v')) {
        return asset?.url!.split('/file')[0].concat('/poster.jpg');
      }

    }
    return asset?.url!;
  }

  handleText() {
    this.show = !this.show;
    if (this.show) {
      this.completeText = true;
    } else {
      this.completeText = false;
    }
  }

  checkTypeMedia(asset: MediaPostResponseModel): string {
    if (!asset?.isProcessed!) {
      return 'UNKNOWN';
    }

    if (!asset?.url) {
      return 'UNKNOWN';
    }

    if (!asset?.url!.includes('?')) {
      if (
        asset?.url!.endsWith('.mp4') ||
        asset?.url!.endsWith('.MP4') ||
        asset?.url!.endsWith('.mov') ||
        asset?.url!.endsWith('.MOV') ||
        asset?.url!.endsWith('.avi') ||
        asset?.url!.endsWith('.AVI')
      ) {
        if (asset?.url!.includes('agora/recording')) {
          return 'RECORD';
        } else {
          return 'VIDEO';
        }
      } else {
        if (asset?.url!.endsWith('.aac') || asset?.url!.endsWith('.wav')) {
          return 'AUDIO';
        }
        return 'IMAGE';
      }
    }

    if (asset?.url!.split('?')[1].startsWith('i')) {
      return 'IMAGE';
    }

    if (asset?.url!.split('?')[1].startsWith('v')) {
      return 'VIDEO';
    }

    if (asset?.url!.split('?')[1].startsWith('a')) {
      return 'AUDIO';
    }

    return 'UNKNOWN';
  }

  reportComment(comment: CommentPostResponseModel) {

  }

  deleteComment() {
    this.showConfirmDeleteComment = false;
    this.deleteCommentEvent.emit(this.commentSelected);
  }

  pickCommentSelected(comment: CommentPostResponseModel) {
    this.commentSelected = comment;
    this.showConfirmDeleteComment = true;
  }

}
