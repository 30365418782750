/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CelebrityAliasClubsResponseModelPaginationModelResponseModel } from '../models/celebrity-alias-clubs-response-model-pagination-model-response-model';
import { CelebrityAliasResponseModelPaginationModelResponseModel } from '../models/celebrity-alias-response-model-pagination-model-response-model';
import { CelebrityPostResponeModelPaginationModelResponseModel } from '../models/celebrity-post-respone-model-pagination-model-response-model';
import { ExportMemberInCelebrityDetail } from '../models/export-member-in-celebrity-detail';
import { MemberInCelebrityDetailPaginationModelResponseModel } from '../models/member-in-celebrity-detail-pagination-model-response-model';
import { StringResponseModel } from '../models/string-response-model';

@Injectable({
  providedIn: 'root',
})
export class CelebrityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithCelebritySearchCelebrityAliasKeywordGet
   */
  static readonly InlivewithCelebritySearchCelebrityAliasKeywordGetPath = '/inlivewith/Celebrity/SearchCelebrityAlias/{keyword}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebritySearchCelebrityAliasKeywordGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebritySearchCelebrityAliasKeywordGet$Plain$Response(params: {
    keyword: string;
    isNotLinkWithAccount?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebritySearchCelebrityAliasKeywordGetPath, 'get');
    if (params) {
      rb.path('keyword', params.keyword, {});
      rb.query('isNotLinkWithAccount', params.isNotLinkWithAccount, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebritySearchCelebrityAliasKeywordGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebritySearchCelebrityAliasKeywordGet$Plain(params: {
    keyword: string;
    isNotLinkWithAccount?: boolean;
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebritySearchCelebrityAliasKeywordGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebritySearchCelebrityAliasKeywordGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebritySearchCelebrityAliasKeywordGet$Json$Response(params: {
    keyword: string;
    isNotLinkWithAccount?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebritySearchCelebrityAliasKeywordGetPath, 'get');
    if (params) {
      rb.path('keyword', params.keyword, {});
      rb.query('isNotLinkWithAccount', params.isNotLinkWithAccount, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebritySearchCelebrityAliasKeywordGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebritySearchCelebrityAliasKeywordGet$Json(params: {
    keyword: string;
    isNotLinkWithAccount?: boolean;
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebritySearchCelebrityAliasKeywordGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet
   */
  static readonly InlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGetPath = '/inlivewith/Celebrity/GetListPublicationForCelebrity/{celebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Plain$Response(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityPostResponeModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityPostResponeModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Plain(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityPostResponeModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityPostResponeModelPaginationModelResponseModel>) => r.body as CelebrityPostResponeModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Json$Response(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityPostResponeModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityPostResponeModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Json(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityPostResponeModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetListPublicationForCelebrityCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityPostResponeModelPaginationModelResponseModel>) => r.body as CelebrityPostResponeModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet
   */
  static readonly InlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGetPath = '/inlivewith/Celebrity/GetListClubHasUseCelebritySuggestName/{celebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Plain$Response(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasClubsResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasClubsResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Plain(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasClubsResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasClubsResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasClubsResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Json$Response(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CelebrityAliasClubsResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasClubsResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Json(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<CelebrityAliasClubsResponseModelPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetListClubHasUseCelebritySuggestNameCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasClubsResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasClubsResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityGetListMembersInCelebrityDetailCelebrityIdGet
   */
  static readonly InlivewithCelebrityGetListMembersInCelebrityDetailCelebrityIdGetPath = '/inlivewith/Celebrity/GetListMembersInCelebrityDetail/{celebrityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetListMembersInCelebrityDetailCelebrityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListMembersInCelebrityDetailCelebrityIdGet$Plain$Response(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<MemberInCelebrityDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetListMembersInCelebrityDetailCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MemberInCelebrityDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetListMembersInCelebrityDetailCelebrityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListMembersInCelebrityDetailCelebrityIdGet$Plain(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<MemberInCelebrityDetailPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetListMembersInCelebrityDetailCelebrityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MemberInCelebrityDetailPaginationModelResponseModel>) => r.body as MemberInCelebrityDetailPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityGetListMembersInCelebrityDetailCelebrityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListMembersInCelebrityDetailCelebrityIdGet$Json$Response(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<MemberInCelebrityDetailPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityGetListMembersInCelebrityDetailCelebrityIdGetPath, 'get');
    if (params) {
      rb.path('celebrityId', params.celebrityId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MemberInCelebrityDetailPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityGetListMembersInCelebrityDetailCelebrityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithCelebrityGetListMembersInCelebrityDetailCelebrityIdGet$Json(params: {
    celebrityId: string;
    page?: number;
    limit?: number;
  }): Observable<MemberInCelebrityDetailPaginationModelResponseModel> {

    return this.inlivewithCelebrityGetListMembersInCelebrityDetailCelebrityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MemberInCelebrityDetailPaginationModelResponseModel>) => r.body as MemberInCelebrityDetailPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithCelebrityExportListMembersInCelebrityDetailPost
   */
  static readonly InlivewithCelebrityExportListMembersInCelebrityDetailPostPath = '/inlivewith/Celebrity/ExportListMembersInCelebrityDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityExportListMembersInCelebrityDetailPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithCelebrityExportListMembersInCelebrityDetailPost$Plain$Response(params?: {
    body?: ExportMemberInCelebrityDetail
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityExportListMembersInCelebrityDetailPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityExportListMembersInCelebrityDetailPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithCelebrityExportListMembersInCelebrityDetailPost$Plain(params?: {
    body?: ExportMemberInCelebrityDetail
  }): Observable<StringResponseModel> {

    return this.inlivewithCelebrityExportListMembersInCelebrityDetailPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithCelebrityExportListMembersInCelebrityDetailPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithCelebrityExportListMembersInCelebrityDetailPost$Json$Response(params?: {
    body?: ExportMemberInCelebrityDetail
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CelebrityService.InlivewithCelebrityExportListMembersInCelebrityDetailPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithCelebrityExportListMembersInCelebrityDetailPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithCelebrityExportListMembersInCelebrityDetailPost$Json(params?: {
    body?: ExportMemberInCelebrityDetail
  }): Observable<StringResponseModel> {

    return this.inlivewithCelebrityExportListMembersInCelebrityDetailPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

}
