/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountClubBannedCreateInputModel } from '../models/account-club-banned-create-input-model';
import { AccountClubBannedResponseModelPaginationModelResponseModel } from '../models/account-club-banned-response-model-pagination-model-response-model';
import { AccountClubBannedResponseModelResponseModel } from '../models/account-club-banned-response-model-response-model';
import { AccountResponseWithRoleInCLubModelPaginationModelResponseModel } from '../models/account-response-with-role-in-c-lub-model-pagination-model-response-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { CelebrityAliasResponseModelPaginationModelResponseModel } from '../models/celebrity-alias-response-model-pagination-model-response-model';
import { ClubFilterInputModel } from '../models/club-filter-input-model';
import { ClubGuidsUpdateInputModel } from '../models/club-guids-update-input-model';
import { ClubHashtagListResponseModel } from '../models/club-hashtag-list-response-model';
import { ClubInputModel } from '../models/club-input-model';
import { ClubResponseWithTotalMemberAndGoInliveModelResponseModel } from '../models/club-response-with-total-member-and-go-inlive-model-response-model';
import { ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel } from '../models/club-response-with-total-member-and-role-model-pagination-model-response-model';
import { ClubResponseWithTotalMemberModelPaginationModelResponseModel } from '../models/club-response-with-total-member-model-pagination-model-response-model';
import { ClubResponseWithTotalMemberModelResponseModel } from '../models/club-response-with-total-member-model-response-model';
import { ClubStringUpdateInputModel } from '../models/club-string-update-input-model';
import { ClubUpdatePolicy } from '../models/club-update-policy';
import { HashtagPaginationModelResponseModel } from '../models/hashtag-pagination-model-response-model';
import { ProcessFileModel } from '../models/process-file-model';
import { StringResponseModel } from '../models/string-response-model';

@Injectable({
  providedIn: 'root',
})
export class ClubService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithClubsByAdministratorAdminIdGet
   */
  static readonly InlivewithClubsByAdministratorAdminIdGetPath = '/inlivewith/Clubs/ByAdministrator/{adminId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsByAdministratorAdminIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByAdministratorAdminIdGet$Plain$Response(params: {
    adminId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsByAdministratorAdminIdGetPath, 'get');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsByAdministratorAdminIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByAdministratorAdminIdGet$Plain(params: {
    adminId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel> {

    return this.inlivewithClubsByAdministratorAdminIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsByAdministratorAdminIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByAdministratorAdminIdGet$Json$Response(params: {
    adminId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsByAdministratorAdminIdGetPath, 'get');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsByAdministratorAdminIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByAdministratorAdminIdGet$Json(params: {
    adminId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel> {

    return this.inlivewithClubsByAdministratorAdminIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsByAccountAccountIdGet
   */
  static readonly InlivewithClubsByAccountAccountIdGetPath = '/inlivewith/Clubs/ByAccount/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsByAccountAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByAccountAccountIdGet$Plain$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsByAccountAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsByAccountAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByAccountAccountIdGet$Plain(params: {
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel> {

    return this.inlivewithClubsByAccountAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsByAccountAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByAccountAccountIdGet$Json$Response(params: {
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsByAccountAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsByAccountAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsByAccountAccountIdGet$Json(params: {
    accountId: string;
    page?: number;
    limit?: number;
  }): Observable<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel> {

    return this.inlivewithClubsByAccountAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubByIdClubIdGet
   */
  static readonly InlivewithClubByIdClubIdGetPath = '/inlivewith/Club/ById/{clubId}';

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubByIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubByIdClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubByIdClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelResponseModel>;
      })
    );
  }

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubByIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubByIdClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<ClubResponseWithTotalMemberAndGoInliveModelResponseModel> {

    return this.inlivewithClubByIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndGoInliveModelResponseModel)
    );
  }

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubByIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubByIdClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubByIdClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelResponseModel>;
      })
    );
  }

  /**
   * Need to use Bearer token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubByIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubByIdClubIdGet$Json(params: {
    clubId: string;
  }): Observable<ClubResponseWithTotalMemberAndGoInliveModelResponseModel> {

    return this.inlivewithClubByIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndGoInliveModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndGoInliveModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsSearchAccountIdPost
   */
  static readonly InlivewithClubsSearchAccountIdPostPath = '/inlivewith/Clubs/search/{accountId}';

  /**
   * accountId: The Profile you want to see, AccountRequestId: account Id that are using app.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsSearchAccountIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubsSearchAccountIdPost$Plain$Response(params: {
    accountId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
    body?: ClubFilterInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsSearchAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * accountId: The Profile you want to see, AccountRequestId: account Id that are using app.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsSearchAccountIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubsSearchAccountIdPost$Plain(params: {
    accountId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
    body?: ClubFilterInputModel
  }): Observable<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel> {

    return this.inlivewithClubsSearchAccountIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel)
    );
  }

  /**
   * accountId: The Profile you want to see, AccountRequestId: account Id that are using app.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsSearchAccountIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubsSearchAccountIdPost$Json$Response(params: {
    accountId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
    body?: ClubFilterInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsSearchAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * accountId: The Profile you want to see, AccountRequestId: account Id that are using app.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsSearchAccountIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubsSearchAccountIdPost$Json(params: {
    accountId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
    body?: ClubFilterInputModel
  }): Observable<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel> {

    return this.inlivewithClubsSearchAccountIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberAndRoleModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubUpdateSuggestionCelebritiesClubIdPut
   */
  static readonly InlivewithClubUpdateSuggestionCelebritiesClubIdPutPath = '/inlivewith/Club/UpdateSuggestionCelebrities/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubUpdateSuggestionCelebritiesClubIdPutPath, 'put');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<CelebrityAliasResponseModelPaginationModelResponseModel> {

    return this.inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubUpdateSuggestionCelebritiesClubIdPutPath, 'put');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<CelebrityAliasResponseModelPaginationModelResponseModel> {

    return this.inlivewithClubUpdateSuggestionCelebritiesClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CelebrityAliasResponseModelPaginationModelResponseModel>) => r.body as CelebrityAliasResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubsLikeNameNameGet
   */
  static readonly InlivewithClubsLikeNameNameGetPath = '/inlivewith/Clubs/LikeName/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsLikeNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsLikeNameNameGet$Plain$Response(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsLikeNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsLikeNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsLikeNameNameGet$Plain(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsLikeNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubsLikeNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsLikeNameNameGet$Json$Response(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubsLikeNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubsLikeNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubsLikeNameNameGet$Json(params: {
    name: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<ClubResponseWithTotalMemberModelPaginationModelResponseModel> {

    return this.inlivewithClubsLikeNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelPaginationModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubNameClubIdGet
   */
  static readonly InlivewithClubNameClubIdGetPath = '/inlivewith/Club/Name/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubNameClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubNameClubIdGet$Plain$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubNameClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubNameClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubNameClubIdGet$Plain(params: {
    clubId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithClubNameClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubNameClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubNameClubIdGet$Json$Response(params: {
    clubId: string;
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubNameClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubNameClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubNameClubIdGet$Json(params: {
    clubId: string;
  }): Observable<StringResponseModel> {

    return this.inlivewithClubNameClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubNameAdminIdClubIdPut
   */
  static readonly InlivewithClubNameAdminIdClubIdPutPath = '/inlivewith/Club/Name/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubNameAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubNameAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubNameAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubNameAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubNameAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubNameAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubNameAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubNameAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubNameAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubNameAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubNameAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubNameAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubTypeAdminIdClubIdPut
   */
  static readonly InlivewithClubTypeAdminIdClubIdPutPath = '/inlivewith/Club/Type/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubTypeAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubTypeAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubTypeAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubTypeAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubTypeAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubTypeAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubTypeAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubTypeAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubTypeAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubTypeAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubTypeAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubTypeAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubDescriptionAdminIdClubIdPut
   */
  static readonly InlivewithClubDescriptionAdminIdClubIdPutPath = '/inlivewith/Club/Description/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubDescriptionAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDescriptionAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubDescriptionAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubDescriptionAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDescriptionAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubDescriptionAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubDescriptionAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDescriptionAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubDescriptionAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubDescriptionAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDescriptionAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubDescriptionAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubUpdatePolicyClubIdPut
   */
  static readonly InlivewithClubUpdatePolicyClubIdPutPath = '/inlivewith/Club/UpdatePolicy/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubUpdatePolicyClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdatePolicyClubIdPut$Plain$Response(params: {
    clubId: string;
    body?: ClubUpdatePolicy
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubUpdatePolicyClubIdPutPath, 'put');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubUpdatePolicyClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdatePolicyClubIdPut$Plain(params: {
    clubId: string;
    body?: ClubUpdatePolicy
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubUpdatePolicyClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubUpdatePolicyClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdatePolicyClubIdPut$Json$Response(params: {
    clubId: string;
    body?: ClubUpdatePolicy
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubUpdatePolicyClubIdPutPath, 'put');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubUpdatePolicyClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubUpdatePolicyClubIdPut$Json(params: {
    clubId: string;
    body?: ClubUpdatePolicy
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubUpdatePolicyClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubCategoryAdminIdClubIdPut
   */
  static readonly InlivewithClubCategoryAdminIdClubIdPutPath = '/inlivewith/Club/Category/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCategoryAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCategoryAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCategoryAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCategoryAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCategoryAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubCategoryAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCategoryAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCategoryAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCategoryAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCategoryAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCategoryAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubStringUpdateInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubCategoryAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubHashtagsAdminIdClubIdPut
   */
  static readonly InlivewithClubHashtagsAdminIdClubIdPutPath = '/inlivewith/Club/Hashtags/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<ClubHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<ClubHashtagListResponseModel> {

    return this.inlivewithClubHashtagsAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubHashtagListResponseModel>) => r.body as ClubHashtagListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<StrictHttpResponse<ClubHashtagListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubHashtagListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubHashtagsAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubGuidsUpdateInputModel
  }): Observable<ClubHashtagListResponseModel> {

    return this.inlivewithClubHashtagsAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubHashtagListResponseModel>) => r.body as ClubHashtagListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAvatarAdminIdClubIdPut
   */
  static readonly InlivewithClubAvatarAdminIdClubIdPutPath = '/inlivewith/Club/Avatar/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAvatarAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAvatarAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAvatarAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<StringResponseModel> {

    return this.inlivewithClubAvatarAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAvatarAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAvatarAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAvatarAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<StringResponseModel> {

    return this.inlivewithClubAvatarAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAvatarAdminIdClubIdPost
   */
  static readonly InlivewithClubAvatarAdminIdClubIdPostPath = '/inlivewith/Club/Avatar/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAvatarAdminIdClubIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarAdminIdClubIdPost$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAvatarAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAvatarAdminIdClubIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarAdminIdClubIdPost$Plain(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubAvatarAdminIdClubIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAvatarAdminIdClubIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarAdminIdClubIdPost$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAvatarAdminIdClubIdPostPath, 'post');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAvatarAdminIdClubIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  inlivewithClubAvatarAdminIdClubIdPost$Json(params: {
    adminId: string;
    clubId: string;
    body?: {
'AdminId'?: string;
'ClubId'?: string;
'Files'?: Array<Blob>;
}
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubAvatarAdminIdClubIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubHashtagsClubIdGet
   */
  static readonly InlivewithClubHashtagsClubIdGetPath = '/inlivewith/Club/Hashtags/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHashtagsClubIdGet$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHashtagsClubIdGet$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithClubHashtagsClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubHashtagsClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHashtagsClubIdGet$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<HashtagPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubHashtagsClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubHashtagsClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubHashtagsClubIdGet$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<HashtagPaginationModelResponseModel> {

    return this.inlivewithClubHashtagsClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagPaginationModelResponseModel>) => r.body as HashtagPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubPublicAdminIdClubIdPut
   */
  static readonly InlivewithClubPublicAdminIdClubIdPutPath = '/inlivewith/Club/Public/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubPublicAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPublicAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubPublicAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubPublicAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPublicAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubPublicAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubPublicAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPublicAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubPublicAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubPublicAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPublicAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubPublicAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubPrivateAdminIdClubIdPut
   */
  static readonly InlivewithClubPrivateAdminIdClubIdPutPath = '/inlivewith/Club/Private/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubPrivateAdminIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPrivateAdminIdClubIdPut$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubPrivateAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubPrivateAdminIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPrivateAdminIdClubIdPut$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubPrivateAdminIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubPrivateAdminIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPrivateAdminIdClubIdPut$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubPrivateAdminIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubPrivateAdminIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubPrivateAdminIdClubIdPut$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubPrivateAdminIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubMembersClubIdGet
   */
  static readonly InlivewithClubMembersClubIdGetPath = '/inlivewith/Club/Members/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubMembersClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersClubIdGet$Plain$Response(params: {
    clubId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubMembersClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubMembersClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersClubIdGet$Plain(params: {
    clubId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubMembersClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubMembersClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersClubIdGet$Json$Response(params: {
    clubId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubMembersClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubMembersClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersClubIdGet$Json(params: {
    clubId: string;
    accountRequestId?: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubMembersClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubMembersByRoleClubIdRoleGet
   */
  static readonly InlivewithClubMembersByRoleClubIdRoleGetPath = '/inlivewith/Club/MembersByRole/{clubId}/{role}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubMembersByRoleClubIdRoleGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersByRoleClubIdRoleGet$Plain$Response(params: {
    clubId: string;
    accountRequestId?: string;
    role: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubMembersByRoleClubIdRoleGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.path('role', params.role, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubMembersByRoleClubIdRoleGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersByRoleClubIdRoleGet$Plain(params: {
    clubId: string;
    accountRequestId?: string;
    role: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubMembersByRoleClubIdRoleGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubMembersByRoleClubIdRoleGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersByRoleClubIdRoleGet$Json$Response(params: {
    clubId: string;
    accountRequestId?: string;
    role: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubMembersByRoleClubIdRoleGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('accountRequestId', params.accountRequestId, {});
      rb.path('role', params.role, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubMembersByRoleClubIdRoleGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubMembersByRoleClubIdRoleGet$Json(params: {
    clubId: string;
    accountRequestId?: string;
    role: string;
    page?: number;
    limit?: number;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubMembersByRoleClubIdRoleGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAdministratorsClubIdAccountRequestIdGet
   */
  static readonly InlivewithClubAdministratorsClubIdAccountRequestIdGetPath = '/inlivewith/Club/Administrators/{clubId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAdministratorsClubIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAdministratorsClubIdAccountRequestIdGet$Plain$Response(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAdministratorsClubIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAdministratorsClubIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAdministratorsClubIdAccountRequestIdGet$Plain(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubAdministratorsClubIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAdministratorsClubIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAdministratorsClubIdAccountRequestIdGet$Json$Response(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAdministratorsClubIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAdministratorsClubIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithClubAdministratorsClubIdAccountRequestIdGet$Json(params: {
    clubId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AccountResponseWithRoleInCLubModelPaginationModelResponseModel> {

    return this.inlivewithClubAdministratorsClubIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseWithRoleInCLubModelPaginationModelResponseModel>) => r.body as AccountResponseWithRoleInCLubModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubDeleteAdminIdClubIdDelete
   */
  static readonly InlivewithClubDeleteAdminIdClubIdDeletePath = '/inlivewith/Club/Delete/{adminId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubDeleteAdminIdClubIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDeleteAdminIdClubIdDelete$Plain$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubDeleteAdminIdClubIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubDeleteAdminIdClubIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDeleteAdminIdClubIdDelete$Plain(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubDeleteAdminIdClubIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubDeleteAdminIdClubIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDeleteAdminIdClubIdDelete$Json$Response(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubDeleteAdminIdClubIdDeletePath, 'delete');
    if (params) {
      rb.path('adminId', params.adminId, {});
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubDeleteAdminIdClubIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubDeleteAdminIdClubIdDelete$Json(params: {
    adminId: string;
    clubId: string;
    body?: ClubInputModel
  }): Observable<ClubResponseWithTotalMemberModelResponseModel> {

    return this.inlivewithClubDeleteAdminIdClubIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClubResponseWithTotalMemberModelResponseModel>) => r.body as ClubResponseWithTotalMemberModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubProcessClubAvatarClubIdPut
   */
  static readonly InlivewithClubProcessClubAvatarClubIdPutPath = '/inlivewith/Club/ProcessClubAvatar/{clubId}';

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubProcessClubAvatarClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubProcessClubAvatarClubIdPut$Plain$Response(params: {
    clubId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubProcessClubAvatarClubIdPutPath, 'put');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubProcessClubAvatarClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubProcessClubAvatarClubIdPut$Plain(params: {
    clubId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubProcessClubAvatarClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubProcessClubAvatarClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubProcessClubAvatarClubIdPut$Json$Response(params: {
    clubId: string;
    body?: ProcessFileModel
  }): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubProcessClubAvatarClubIdPutPath, 'put');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubProcessClubAvatarClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubProcessClubAvatarClubIdPut$Json(params: {
    clubId: string;
    body?: ProcessFileModel
  }): Observable<BooleanResponseModel> {

    return this.inlivewithClubProcessClubAvatarClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubCreateAccountClubBannedPost
   */
  static readonly InlivewithClubCreateAccountClubBannedPostPath = '/inlivewith/Club/CreateAccountClubBanned';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCreateAccountClubBannedPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCreateAccountClubBannedPost$Plain$Response(params?: {
    body?: AccountClubBannedCreateInputModel
  }): Observable<StrictHttpResponse<AccountClubBannedResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCreateAccountClubBannedPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubBannedResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCreateAccountClubBannedPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCreateAccountClubBannedPost$Plain(params?: {
    body?: AccountClubBannedCreateInputModel
  }): Observable<AccountClubBannedResponseModelResponseModel> {

    return this.inlivewithClubCreateAccountClubBannedPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubBannedResponseModelResponseModel>) => r.body as AccountClubBannedResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubCreateAccountClubBannedPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCreateAccountClubBannedPost$Json$Response(params?: {
    body?: AccountClubBannedCreateInputModel
  }): Observable<StrictHttpResponse<AccountClubBannedResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubCreateAccountClubBannedPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubBannedResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubCreateAccountClubBannedPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubCreateAccountClubBannedPost$Json(params?: {
    body?: AccountClubBannedCreateInputModel
  }): Observable<AccountClubBannedResponseModelResponseModel> {

    return this.inlivewithClubCreateAccountClubBannedPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubBannedResponseModelResponseModel>) => r.body as AccountClubBannedResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithClubAccountUnBannedAdminClubIdClubIdPut
   */
  static readonly InlivewithClubAccountUnBannedAdminClubIdClubIdPutPath = '/inlivewith/Club/Account/UnBanned/{adminClubId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Plain$Response(params: {
    adminClubId: string;
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AccountClubBannedResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountUnBannedAdminClubIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminClubId', params.adminClubId, {});
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubBannedResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Plain(params: {
    adminClubId: string;
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<AccountClubBannedResponseModelPaginationModelResponseModel> {

    return this.inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubBannedResponseModelPaginationModelResponseModel>) => r.body as AccountClubBannedResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Json$Response(params: {
    adminClubId: string;
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AccountClubBannedResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.InlivewithClubAccountUnBannedAdminClubIdClubIdPutPath, 'put');
    if (params) {
      rb.path('adminClubId', params.adminClubId, {});
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountClubBannedResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Json(params: {
    adminClubId: string;
    clubId: string;
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<AccountClubBannedResponseModelPaginationModelResponseModel> {

    return this.inlivewithClubAccountUnBannedAdminClubIdClubIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountClubBannedResponseModelPaginationModelResponseModel>) => r.body as AccountClubBannedResponseModelPaginationModelResponseModel)
    );
  }

}
