/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AppEnumAllModelResponseModel } from '../models/app-enum-all-model-response-model';
import { ReportAccountReasonEnumPaginationModelResponseModel } from '../models/report-account-reason-enum-pagination-model-response-model';
import { ReportReasonEnumPaginationModelResponseModel } from '../models/report-reason-enum-pagination-model-response-model';

@Injectable({
  providedIn: 'root',
})
export class AppEnumService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithAppEnumReportReasonsGet
   */
  static readonly InlivewithAppEnumReportReasonsGetPath = '/inlivewith/AppEnum/ReportReasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumReportReasonsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReportReasonsGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReportReasonEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumReportReasonsGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportReasonEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumReportReasonsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReportReasonsGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<ReportReasonEnumPaginationModelResponseModel> {

    return this.inlivewithAppEnumReportReasonsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportReasonEnumPaginationModelResponseModel>) => r.body as ReportReasonEnumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumReportReasonsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReportReasonsGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReportReasonEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumReportReasonsGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportReasonEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumReportReasonsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReportReasonsGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<ReportReasonEnumPaginationModelResponseModel> {

    return this.inlivewithAppEnumReportReasonsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportReasonEnumPaginationModelResponseModel>) => r.body as ReportReasonEnumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumReportAccountReasonsGet
   */
  static readonly InlivewithAppEnumReportAccountReasonsGetPath = '/inlivewith/AppEnum/ReportAccountReasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumReportAccountReasonsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReportAccountReasonsGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReportAccountReasonEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumReportAccountReasonsGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportAccountReasonEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumReportAccountReasonsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReportAccountReasonsGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<ReportAccountReasonEnumPaginationModelResponseModel> {

    return this.inlivewithAppEnumReportAccountReasonsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReportAccountReasonEnumPaginationModelResponseModel>) => r.body as ReportAccountReasonEnumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumReportAccountReasonsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReportAccountReasonsGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReportAccountReasonEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumReportAccountReasonsGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportAccountReasonEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumReportAccountReasonsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumReportAccountReasonsGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<ReportAccountReasonEnumPaginationModelResponseModel> {

    return this.inlivewithAppEnumReportAccountReasonsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReportAccountReasonEnumPaginationModelResponseModel>) => r.body as ReportAccountReasonEnumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAppEnumAllGet
   */
  static readonly InlivewithAppEnumAllGetPath = '/inlivewith/AppEnum/All';

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAllGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<AppEnumAllModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AppEnumAllModelResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAllGet$Plain(params?: {
  }): Observable<AppEnumAllModelResponseModel> {

    return this.inlivewithAppEnumAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AppEnumAllModelResponseModel>) => r.body as AppEnumAllModelResponseModel)
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAppEnumAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAllGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<AppEnumAllModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.InlivewithAppEnumAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AppEnumAllModelResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAppEnumAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAppEnumAllGet$Json(params?: {
  }): Observable<AppEnumAllModelResponseModel> {

    return this.inlivewithAppEnumAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AppEnumAllModelResponseModel>) => r.body as AppEnumAllModelResponseModel)
    );
  }

}
