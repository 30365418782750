<div class="content-body" *ngIf="listReports">
  <div class="fs-14 flex">
    <div>
      <span class="text-bold">
        {{totalReports}}
      </span>
      <span>{{'RESULTS' | translate}}</span>
    </div>
  </div>
  <div class="flex flex-column">
    <div class="w-100 mt-4" *ngIf="listReports">
      <div *ngIf="listReports?.length! > 0">
        <div *ngFor="let report of listReports">
          <div *ngFor="let reporter of report.reports">
            <div class="card" *ngIf="reporter.type === 'REPORT_ACCOUNT'">
              <div class="flex align-items-center justify-content-between">
                <div class="zone-info-user">
                  <div class="flex align-items-center">
                    <div class="zone-avt mr-2">
                      <img class="fit-size" [src]="reporter.authorAvatar ?? defaultAvatar">
                    </div>
                    <div class="zone-name">
                      <span style="padding: 0px 12px 0px 9px;">{{reporter.authorNickname! | limitString:false:40}}</span>
                    </div>
                  </div>
                </div>
                <div class="flex align-items-center" *ngIf="reporter.status === 'STATUS_IGNORE'">
                  <span class="text-blue">{{'IGNORE_AT' | translate}} {{reporter.dateUpdated |
                    date:"dd/MM/yyyy"}}</span>
                </div>
                <div class="flex align-items-center" *ngIf="reporter.status === 'STATUS_ACCEPTED'">
                  <span class="text-blue">{{'APPROVED_AT' | translate}} {{reporter.dateUpdated |
                    date:"dd/MM/yyyy"}}</span>
                </div>
                <div class="flex align-items-center" *ngIf="reporter.status === 'STATUS_WARN'">
                  <span class="text-blue">{{'WARNING_AT' | translate}} {{reporter.dateUpdated |
                    date:"dd/MM/yyyy"}}</span>
                </div>
              </div>
              <div class="flex flex-column mt-3 ml-5">
                <div class="flex flex-column ml-4 mt-2 mb-2">
                  <div class="zone-info-user">
                    <div class="zone-avt mr-1">
                      <img class="fit-size" [src]="account.avatar ?? defaultAvatar">
                    </div>
                    <div class="zone-name ml-1">
                      <span>{{account.nickName! | limitString:false:40}}</span>
                      <span>{{timeAgo(reporter.dateCreated!)}}</span>
                    </div>
                  </div>
                  <div class="flex flex-column mt-1">
                    <span class="text-gray text-bold">{{reporter.reasonReport! | translate}}</span>
                    <span class="fs-12" *ngIf="reporter.reasonReport! == 'OTHER'">
                      {{reporter.description!}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div *ngIf="listReports?.clubs?.length! > 0">
        <div *ngFor="let club of listReports?.clubs!">
          <div class="card">
            <div class="flex align-items-center justify-content-between">
              <div class="zone-info-user">
                <div class="flex align-items-center">
                  <div class="zone-avt mr-2">
                    <img class="fit-size" [src]="club?.avatar!">
                  </div>
                  <div class="zone-name ml-2">
                    <span>{{club?.name!}}</span>
                  </div>
                </div>
              </div>
              <div class="flex align-items-center" *ngIf="club.status === 'STATUS_IGNORE'">
                <span class="text-blue">{{'IGNORE_AT' | translate}} {{club.dateUpdated |
                  date:"dd/MM/yyyy"}}</span>
              </div>
              <div class="flex align-items-center" *ngIf="club.status === 'STATUS_ACCEPTED'">
                <span class="text-blue">{{'WARNING_AT' | translate}} {{club.dateUpdated |
                  date:"dd/MM/yyyy"}}</span>
              </div>
            </div>
            <div class="mt-3 flex align-items-center">
              <span class="club-type">
                {{'CLUB' | translate}}
              </span>
            </div>
            <div class="flex flex-column mt-1 ml-5">
              <div class="flex flex-column ml-4 mt-2 mb-2">
                <div class="zone-info-user">
                  <div class="zone-avt mr-1">
                    <img class="fit-size" [src]="club.reporter?.avatar ?? defaultAvatar">
                  </div>
                  <div class="zone-name ml-1">
                    <span>{{club.reporter?.nickName!}}</span>
                    <span>{{timeAgo(club.dateCreated!)}}</span>
                  </div>
                </div>
                <div class="flex flex-column mt-1">
                  <span class="text-gray text-bold">{{club.reasonReport! | translate}}</span>
                  <span class="fs-12" *ngIf="club.reasonReport! == 'OTHER'">
                    {{club.description!}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div *ngIf="listReports?.commentPosts?.length! > 0">
        <div *ngFor="let commentPost of listReports?.commentPosts!">
          <div class="card">
            <div class="flex align-items-center justify-content-between">
              <div class="zone-info-user">
                <div class="flex align-items-center">
                  <div class="zone-avt mr-2">
                    <img class="fit-size" [src]="defaultAvatar">
                  </div>
                  <div class="zone-name">
                    <span class="zone-author-name">{{commentPost?.clubName!}}</span>
                  </div>
                  <span class="ml-2">{{getRole(commentPost) | translate}}</span>
                </div>
              </div>
              <div class="flex align-items-center" *ngIf="commentPost.status === 'STATUS_IGNORE'">
                <span class="text-blue">{{'IGNORE_AT' | translate}} {{commentPost.dateUpdated |
                  date:"dd/MM/yyyy"}}</span>
              </div>
              <div class="flex align-items-center" *ngIf="commentPost.status === 'STATUS_ACCEPTED'">
                <span class="text-blue">{{'WARNING_AT' | translate}} {{report.reports![0].dateUpdated |
                  date:"dd/MM/yyyy"}}</span>
              </div>
            </div>
            <div class="mt-3 mb-3 flex align-items-center">
              <span class="comment-type">
                {{'COMMENT' | translate}}
              </span>
            </div>
            <div [innerHTML]="urlify(commentPost?.message! | limitString:completeText)"></div>
            <div class="flex flex-column mt-3 ml-5">
              <div class="flex flex-column ml-4 mt-2 mb-2">
                <div class="zone-info-user">
                  <div class="zone-avt mr-1">
                    <img class="fit-size" [src]="account.avatar ?? defaultAvatar">
                  </div>
                  <div class="zone-name ml-1">
                    <span>{{account.nickName!}}</span>
                    <span>{{timeAgo(commentPost.dateCreated!)}}</span>
                  </div>
                </div>
                <div class="flex flex-column mt-1">
                  <span class="text-gray text-bold">{{commentPost.reasonReport! | translate}}</span>
                  <span class="fs-12" *ngIf="commentPost.reasonReport! == 'OTHER'">
                    {{commentPost.description!}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div *ngIf="listReports?.posts?.length! > 0">
        <div *ngFor="let post of listReports?.posts!">
          <div class="card">
            <div class="flex flex-column">
              <div class="flex align-items-center">
                <div class="zone-avt mr-2">
                  <img class="fit-size" [src]="post?.author?.avatar!">
                </div>
                <div class="zone-name ml-2">
                  <div class="flex">
                    <span class="mr-2">{{post?.clubName!}}</span>
                    <span class="zone-author-name">{{post?.author?.nickName!}}</span>
                  </div>
                </div>
              </div>
              <div class="zone-post-info">
                <div class="mb-3 mt-3">
                  <div class="flex align-items-center">
                    <span class="publication mr-2" *ngIf="post?.type! == 'POST_PUBLICATION'">
                      {{'POST_TYPE_PUBLICATION_TITLE' | translate}}
                    </span>
                    <span class="discussion mr-2" *ngIf="post?.type! == 'POST_DISCUSSION'">
                      {{'POST_TYPE_DISCUSSION_TITLE' | translate}}
                    </span>
                  </div>
                </div>
                <div
                  [innerHTML]="urlify(post?.type! == 'POST_DISCUSSION' ? post?.title : post?.message! | limitString:completeText)">
                </div>
                <div class="flex list-media" *ngIf="post?.mediaPosts?.length! > 0" [id]="post?.id!">
                  <div class="mr-1" *ngFor="let asset of post?.mediaPosts!">
                    <div class="mr-4" *ngIf="(checkTypeMedia(asset) == 'IMAGE')" style="height: 100px">
                      <img [src]="getUrl(asset)" alt="" style="height: 100%;width: auto;">
                    </div>
                    <div style="width: 280px;" *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                      <video [id]="asset.id" class="original-size" [src]="asset!.url!" controls></video>
                    </div>
                    <div *ngIf="checkTypeMedia(asset) == 'AUDIO'">
                      <audio controls>
                        <source [src]="asset?.url!">
                      </audio>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
