<div class="flex w-100 h-100 cursor-pointer" [ngStyle]="
post?.mediaPosts?.length == 1 ? {'max-height':'50rem'}:
post?.mediaPosts?.length == 2 ?  {'height':'30rem'}: {'height':'20rem'}"
  *ngIf="post?.mediaPosts?.length! > 0 && !typeLayout.includes('AUDIO')">
  <ng-container *ngFor="let asset of post.mediaPosts; let i = index">
    <ng-container *ngIf="checkTypeMedia(asset) !== 'AUDIO'">
      <ng-container *ngIf="!checkFail(asset)">
        <div class="flex justify-content-center ml-1 mr-1 media position-relative">
          <img loading="lazy"
            *ngIf="(checkTypeMedia(asset) == 'IMAGE' || checkTypeMedia(asset) == 'VIDEO') && checkTypeMedia(asset) != 'RECORD'"
            [src]="getUrl(asset)" alt="" (click)="openMedia(i)" (load)="onLoad($event, i)" />
          <video *ngIf="getUrl(asset) == asset?.url! && checkTypeMedia(asset) == 'RECORD'" (click)="openMedia(i)"
            [id]="asset" [src]="getUrl(asset)" controls [muted]="true"></video>
          <div *ngIf="checkTypeMedia(asset) == 'VIDEO'" (click)="openMedia(i)" class="wrap-video">
            <img class="icon-play" src="../../../assets/images/svgs/play-button.png">
          </div>
          <div class="flex w-100 h-100 justify-content-center align-items-center"
            *ngIf="checkTypeMedia(asset) == 'UNKNOWN'">
            <img width="100" height="100" src="../../../../../assets/images/naloader.svg" alt="" srcset="">
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="checkFail(asset)">
        <div class="flex justify-content-center ml-1 mr-1 media position-relative align-items-center zone-media-fail"
          [innerHTML]="'TEXT_WHEN_ERROR_UPLOAD_MEDIA' | translate">
        </div>
      </ng-container>
    </ng-container>

  </ng-container>
</div>


<div class="flex flex-column" *ngIf="typeLayout.includes('AUDIO')">
  <ng-container *ngFor="let asset of post.mediaPosts let i = index">
    <div class="w-100">
      <div class="flex justify-content-center media w-100">
        <div class="flex flex-column w-100 mt-2 mb-2 justify-content-center">
          <app-wave-form-audio [assetUrl]="asset?.url!"></app-wave-form-audio>
        </div>
      </div>
    </div>
    <ng-container *ngIf="checkFail(asset)">
      <div class="flex justify-content-center mt-2 mb-2 media position-relative align-items-center zone-media-fail"
        style="min-height: 70px !important;" [innerHTML]="'TEXT_WHEN_ERROR_UPLOAD_MEDIA' | translate">
      </div>
    </ng-container>
  </ng-container>
</div>
