/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HashtagResponseModelPaginationModelResponseModel } from '../models/hashtag-response-model-pagination-model-response-model';
import { HashtagResponseModelResponseModel } from '../models/hashtag-response-model-response-model';

@Injectable({
  providedIn: 'root',
})
export class HashtagService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithHashtagsPageGet
   */
  static readonly InlivewithHashtagsPageGetPath = '/inlivewith/Hashtags/Page';

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsPageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsPageGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsPageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsPageGet$Plain(params?: {
    page?: number;
    limit?: number;
  }): Observable<HashtagResponseModelPaginationModelResponseModel> {

    return this.inlivewithHashtagsPageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>) => r.body as HashtagResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagsPageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsPageGet$Json$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagsPageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * Bearer token isn't mandatory.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagsPageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagsPageGet$Json(params?: {
    page?: number;
    limit?: number;
  }): Observable<HashtagResponseModelPaginationModelResponseModel> {

    return this.inlivewithHashtagsPageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>) => r.body as HashtagResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagByIdHashtagIdGet
   */
  static readonly InlivewithHashtagByIdHashtagIdGetPath = '/inlivewith/Hashtag/ById/{hashtagId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagByIdHashtagIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByIdHashtagIdGet$Plain$Response(params: {
    hashtagId: string;
  }): Observable<StrictHttpResponse<HashtagResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagByIdHashtagIdGetPath, 'get');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagByIdHashtagIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByIdHashtagIdGet$Plain(params: {
    hashtagId: string;
  }): Observable<HashtagResponseModelResponseModel> {

    return this.inlivewithHashtagByIdHashtagIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelResponseModel>) => r.body as HashtagResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagByIdHashtagIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByIdHashtagIdGet$Json$Response(params: {
    hashtagId: string;
  }): Observable<StrictHttpResponse<HashtagResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagByIdHashtagIdGetPath, 'get');
    if (params) {
      rb.path('hashtagId', params.hashtagId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagByIdHashtagIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithHashtagByIdHashtagIdGet$Json(params: {
    hashtagId: string;
  }): Observable<HashtagResponseModelResponseModel> {

    return this.inlivewithHashtagByIdHashtagIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelResponseModel>) => r.body as HashtagResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithHashtagGetByCategoryPost
   */
  static readonly InlivewithHashtagGetByCategoryPostPath = '/inlivewith/Hashtag/GetByCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagGetByCategoryPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagGetByCategoryPost$Plain$Response(params?: {
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagGetByCategoryPostPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagGetByCategoryPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagGetByCategoryPost$Plain(params?: {
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<HashtagResponseModelPaginationModelResponseModel> {

    return this.inlivewithHashtagGetByCategoryPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>) => r.body as HashtagResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithHashtagGetByCategoryPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagGetByCategoryPost$Json$Response(params?: {
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, HashtagService.InlivewithHashtagGetByCategoryPostPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithHashtagGetByCategoryPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithHashtagGetByCategoryPost$Json(params?: {
    page?: number;
    limit?: number;
    body?: Array<string>
  }): Observable<HashtagResponseModelPaginationModelResponseModel> {

    return this.inlivewithHashtagGetByCategoryPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HashtagResponseModelPaginationModelResponseModel>) => r.body as HashtagResponseModelPaginationModelResponseModel)
    );
  }

}
