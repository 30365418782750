import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  production: false,
  STORAGE_URL: '',
  STORAGE_URL_IDENTITY: 'https://storage.identity.stag.ilwith.io',
  BASE_URL: 'https://stag.inlivewith.bo.139.99.27.199.ilwith.io',
  IDENTITY_URL: 'https://stag.identity.ilwith.io',
  API_URL: 'https://stag.bo.ilw.139.99.27.198.ilwith.io',
  WEB_PORTAL_URL: 'https://stag.identity.ui.ilwith.io',
  INLIVE_GROUP_URL: 'https://inlive-group.com/',
  EMAIL_API_URL: 'https://email.ilwith.io',
  STANDARD_URL: '',
  HASH_TAG_URL: '',
  FCM_TEST_URL: '',
  FCM_APP_ID: '',
  GOOGLE_API_KEY: 'AIzaSyDtLxBSzPRNpy-u_bALJbNCAcpAlKvky5Y',
  SIGNALR_URL: 'https://signalr.ilwith.io',
  TEMP_TOKEN: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InBoYW10cmFucXVlYW5oQGdtYWlsLmNvbSIsImVtYWlsIjoicGhhbXRyYW5xdWVhbmhAZ21haWwuY29tIiwic3ViIjoiZGY1YmQwNWYtNDAyYy00YWE5LTlmYWQtYjg0YjQwMjlkNzk5IiwianRpIjoiYjNhZjE1MjUtNDYxMS00M2FiLTgzMDAtNzAxYjBjOTYyODE4IiwiaWF0IjoxNjk1MTA4MTAyLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJhZG1pbmlzdHJhdG9ycyIsIm5iZiI6MTY5NTEwODEwMSwiZXhwIjoxNjk1MTE1MzAxLCJpc3MiOiJ3ZWJBcGkiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjUwMzEvIn0.MvUtdVvEm1HhpkO3NBQBkjTEPARCEPcRCJaetz_z7d4',
  VERSION: 'stag - 0.9.2',
  ADDRESS_WEB_URL: 'https://www.address.ilwith.io',
  INLIVEWITH_API: 'https://stag.inlivewith.api.ilwith.io',
  UPLOAD_URL: "https://stag.upload.ilwith.io",
  SERVICE_ID: 'inlivewith-stag'
};
