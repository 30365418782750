import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengModule } from './primeng/primeng.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
// import { httpTranslateLoader } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslationService } from '../core/services/translation.service';
import { CustomTranslatePipe } from '../core/services/translation-custom.pipe';
import { SafeHtmlPipe } from '../core/services/safehtml.pipe';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CardAlbumComponent } from './widgets/card-album/card-album.component';
import { DetailAlbumComponent } from './components/detail-album/detail-album.component';
import { AccountInfoComponent } from './components/account-info/account-info.component';
import { ListClubManageComponent } from './components/list-club-manage/list-club-manage.component';
import { ListClubFollowComponent } from './components/list-club-follow/list-club-follow.component';
import { ListFriendComponent } from './components/list-friend/list-friend.component';
import { ListClubMembersComponent } from './components/list-club-members/list-club-members.component';
import { ListClubScenesComponent } from './components/list-club-scenes/list-club-scenes.component';
import { ListClubEventsComponent } from './components/list-club-events/list-club-events.component';
import { CardInliverComponent } from './widgets/card-inliver/card-inliver.component';
import { ReportAccountComponent } from './components/report-account/report-account.component';
import { ClubPostsPublicationComponent } from './components/club-posts-publication/club-posts-publication.component';
import { WaveFormAudioComponent } from './components/wave-form-audio/wave-form-audio.component';
import { NgWaveformModule } from 'ng-waveform';
import { CardEventComponent } from './widgets/card-event/card-event.component';
import { DetailEventComponent } from './components/detail-event/detail-event.component';
import { ListClubReportsComponent } from './components/list-club-reports/list-club-reports.component';
import { CardReportComponent } from './widgets/card-report/card-report.component';
import { CardPostComponent } from './widgets/card-post/card-post.component';
import { LimitStringPipe } from '../core/pipe/limit-string.pipe';
import { CardPostDiscussionComponent } from './widgets/card-post-discussion/card-post-discussion.component';
import { ListLikesComponent } from './components/list-likes/list-likes.component';
import { ListCommentsComponent } from './components/list-comments/list-comments.component';
import { LayoutMediaPostComponent } from './widgets/layout-media-post/layout-media-post.component';
import { ReportingAccountComponent } from './components/reporting-account/reporting-account.component';
import { CelebInfoComponent } from './components/celeb-info/celeb-info.component';
import { CelebClubLinkedComponent } from './components/celeb-club-linked/celeb-club-linked.component';
import { CelebPostsComponent } from './components/celeb-posts/celeb-posts.component';
import { LoadingComponent } from './widgets/loading/loading.component';
import { CardHandleReportComponent } from './widgets/card-handle-report/card-handle-report.component';
import { ListCelebsComponent } from './components/list-celebs/list-celebs.component';
import { FilterListItemsPipe } from '../core/pipe/filterListItems.pipe';
import { UploadAvatarComponent } from './widgets/upload-avatar/upload-avatar.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CardConfirmComponent } from './widgets/card-confirm/card-confirm.component';
import { ListTopClubComponent } from './list-top-club/list-top-club.component';
import { EditCategoryComponent } from './components/edit-club/edit-category.component';
import { EditHashtagsInliverComponent } from './components/edit-hashtags-inliver/edit-hashtags-inliver.component';
import { CardCommentComponent } from './widgets/card-comment/card-comment.component';
import { VideoLayerComponent } from './widgets/video-layer/video-layer.component';
import { IlwInfinityScrollComponent } from './components/ilw-infinity-scroll/ilw-infinity-scroll.component';
import { CardPostInliveteamComponent } from './components/card-post-inliveteam/card-post-inliveteam.component';
import { PostSkeletonComponent } from './widgets/post-skeleton/post-skeleton.component';
import { RecordAudioV2Component } from './widgets/record-audio-v2/record-audio-v2.component';
import { ListAuthorDiscussionComponent } from './widgets/list-author-discussion/list-author-discussion.component';
import { MentionModule } from 'angular-mentions';
import { CelebMembersComponent } from './components/celeb-members/celeb-members.component';
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    CustomTranslatePipe,
    SafeHtmlPipe,
    CardAlbumComponent,
    DetailAlbumComponent,
    AccountInfoComponent,
    ListClubManageComponent,
    ListClubFollowComponent,
    ListFriendComponent,
    CardInliverComponent,
    ReportAccountComponent,
    WaveFormAudioComponent,
    CardEventComponent,
    DetailEventComponent,
    CardReportComponent,
    CardPostDiscussionComponent,
    LimitStringPipe,
    CardPostComponent,
    ListCommentsComponent,
    ListLikesComponent,
    LayoutMediaPostComponent,
    ReportingAccountComponent,
    CelebInfoComponent,
    CelebClubLinkedComponent,
    CelebPostsComponent,
    LoadingComponent,
    CardHandleReportComponent,
    ListCelebsComponent,
    FilterListItemsPipe,
    UploadAvatarComponent,
    CardConfirmComponent,
    ListTopClubComponent,
    EditCategoryComponent,
    EditHashtagsInliverComponent,
    CardCommentComponent,
    VideoLayerComponent,
    IlwInfinityScrollComponent,
    CardPostInliveteamComponent,
    PostSkeletonComponent,
    RecordAudioV2Component,
    ListAuthorDiscussionComponent,
    CelebMembersComponent
  ],
  imports: [
    CommonModule,
    PrimengModule,
    NgWaveformModule,
    FormsModule,
    MentionModule,
    ImageCropperModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationService,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    MentionModule,
    PrimengModule,
    FormsModule,
    SafeHtmlPipe,
    NgxIntlTelInputModule,
    TranslateModule,
    CardAlbumComponent,
    DetailAlbumComponent,
    AccountInfoComponent,
    ListClubManageComponent,
    ListClubFollowComponent,
    ListFriendComponent,
    CardInliverComponent,
    ReportAccountComponent,
    CardEventComponent,
    DetailEventComponent,
    CardReportComponent,
    CardPostComponent,
    CardPostDiscussionComponent,
    WaveFormAudioComponent,
    ListCommentsComponent,
    ListLikesComponent,
    LimitStringPipe,
    ReportingAccountComponent,
    CelebInfoComponent,
    CelebClubLinkedComponent,
    CelebPostsComponent,
    LoadingComponent,
    CardHandleReportComponent,
    ListCelebsComponent,
    FilterListItemsPipe,
    UploadAvatarComponent,
    CardConfirmComponent,
    ListTopClubComponent,
    EditCategoryComponent,
    CardCommentComponent,
    VideoLayerComponent,
    FilterListItemsPipe,
    IlwInfinityScrollComponent,
    CardPostInliveteamComponent,
    PostSkeletonComponent,
    RecordAudioV2Component,
    ListAuthorDiscussionComponent,
    CelebMembersComponent
  ],
})
export class SharedModule {}
