<div class="col-12 flex align-items-start">
  <div class="zone-left">
    <div *ngFor="let post of listPosts">
      <app-card-post [post]="post" [postCeleb]="post" [(selectedPost)]="postSelected"
        [checkClicked]="postSelected ? postSelected.id == post.id : false"
        (listCommentsPost)="getListCommentsPost($event)" (listReactsPost)="getListReactsPost($event)"></app-card-post>
    </div>
  </div>
  <div class="col-4 mt-3 zone-right" *ngIf="postSelected">
    <div class="zone-content-asset">
      <div class="pt-3 zone-comment-asset">
        <div class="flex ml-3 mb-3">
          <div *ngFor="let control of initZoneControl()">
            <span class="mr-5 cursor-pointer" (click)="control.command()"
              [ngStyle]="currentControl.id === control.id ? {'color': '#0058FF'} : {'color':'#A1A7C4'}">
              {{control.title}} ({{control.total}})
            </span>
          </div>
        </div>
        <div *ngIf="currentControl.id == 1">
          <app-list-comments [listCommentsPost]="listCommentsPost"></app-list-comments>
        </div>
        <div *ngIf="currentControl.id == 0">
          <app-list-likes [listReactsPost]="listReactsPost"></app-list-likes>
        </div>
      </div>
    </div>
  </div>
</div>
