/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AlbumAssetResponseGeneralListResponseModel } from '../models/album-asset-response-general-list-response-model';
import { AlbumAssetResponseGeneralPaginationModelResponseModel } from '../models/album-asset-response-general-pagination-model-response-model';
import { AlbumAssetResponseGeneralResponseModel } from '../models/album-asset-response-general-response-model';
import { AlbumDeleteFilesInputModel } from '../models/album-delete-files-input-model';
import { AlbumInputModel } from '../models/album-input-model';
import { AlbumResponseWithTotalAssetModelListResponseModel } from '../models/album-response-with-total-asset-model-list-response-model';
import { AlbumResponseWithTotalAssetModelPaginationModelResponseModel } from '../models/album-response-with-total-asset-model-pagination-model-response-model';
import { AlbumResponseWithTotalAssetModelResponseModel } from '../models/album-response-with-total-asset-model-response-model';
import { CommentAlbumAssetResponseModelPaginationModelResponseModel } from '../models/comment-album-asset-response-model-pagination-model-response-model';
import { CommentAlbumAssetResponseModelResponseModel } from '../models/comment-album-asset-response-model-response-model';
import { ReactAlbumAssetResponseModelPaginationModelResponseModel } from '../models/react-album-asset-response-model-pagination-model-response-model';

@Injectable({
  providedIn: 'root',
})
export class AlbumService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inlivewithAlbumsByClubClubIdGet
   */
  static readonly InlivewithAlbumsByClubClubIdGetPath = '/inlivewith/Albums/ByClub/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsByClubClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsByClubClubIdGet$Plain$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsByClubClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsByClubClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsByClubClubIdGet$Plain(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsByClubClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumsByClubClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsByClubClubIdGet$Json$Response(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumsByClubClubIdGetPath, 'get');
    if (params) {
      rb.path('clubId', params.clubId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumsByClubClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumsByClubClubIdGet$Json(params: {
    clubId: string;
    page?: number;
    limit?: number;
  }): Observable<AlbumResponseWithTotalAssetModelPaginationModelResponseModel> {

    return this.inlivewithAlbumsByClubClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelPaginationModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumMutiDeleteDelete
   */
  static readonly InlivewithAlbumMutiDeleteDeletePath = '/inlivewith/Album/MutiDelete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumMutiDeleteDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumMutiDeleteDelete$Plain$Response(params?: {
    adminId?: string;
    clubId?: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumMutiDeleteDeletePath, 'delete');
    if (params) {
      rb.query('adminId', params.adminId, {});
      rb.query('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumMutiDeleteDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumMutiDeleteDelete$Plain(params?: {
    adminId?: string;
    clubId?: string;
    body?: Array<string>
  }): Observable<AlbumResponseWithTotalAssetModelListResponseModel> {

    return this.inlivewithAlbumMutiDeleteDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelListResponseModel>) => r.body as AlbumResponseWithTotalAssetModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumMutiDeleteDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumMutiDeleteDelete$Json$Response(params?: {
    adminId?: string;
    clubId?: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumMutiDeleteDeletePath, 'delete');
    if (params) {
      rb.query('adminId', params.adminId, {});
      rb.query('clubId', params.clubId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumMutiDeleteDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumMutiDeleteDelete$Json(params?: {
    adminId?: string;
    clubId?: string;
    body?: Array<string>
  }): Observable<AlbumResponseWithTotalAssetModelListResponseModel> {

    return this.inlivewithAlbumMutiDeleteDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelListResponseModel>) => r.body as AlbumResponseWithTotalAssetModelListResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet
   */
  static readonly InlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGetPath = '/inlivewith/Album/GetAlbumAssetById/{albumAssetId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Plain$Response(params: {
    albumAssetId: string;
    accountRequestId: string;
  }): Observable<StrictHttpResponse<AlbumAssetResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('albumAssetId', params.albumAssetId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Plain(params: {
    albumAssetId: string;
    accountRequestId: string;
  }): Observable<AlbumAssetResponseGeneralResponseModel> {

    return this.inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetResponseGeneralResponseModel>) => r.body as AlbumAssetResponseGeneralResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Json$Response(params: {
    albumAssetId: string;
    accountRequestId: string;
  }): Observable<StrictHttpResponse<AlbumAssetResponseGeneralResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('albumAssetId', params.albumAssetId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetResponseGeneralResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Json(params: {
    albumAssetId: string;
    accountRequestId: string;
  }): Observable<AlbumAssetResponseGeneralResponseModel> {

    return this.inlivewithAlbumGetAlbumAssetByIdAlbumAssetIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetResponseGeneralResponseModel>) => r.body as AlbumAssetResponseGeneralResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet
   */
  static readonly InlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGetPath = '/inlivewith/Album/GetCommentAlbumAssetByAlbumAssetId/{albumAssetId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Plain$Response(params: {
    albumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('albumAssetId', params.albumAssetId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Plain(params: {
    albumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<CommentAlbumAssetResponseModelPaginationModelResponseModel> {

    return this.inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelPaginationModelResponseModel>) => r.body as CommentAlbumAssetResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Json$Response(params: {
    albumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('albumAssetId', params.albumAssetId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Json(params: {
    albumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<CommentAlbumAssetResponseModelPaginationModelResponseModel> {

    return this.inlivewithAlbumGetCommentAlbumAssetByAlbumAssetIdAlbumAssetIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelPaginationModelResponseModel>) => r.body as CommentAlbumAssetResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet
   */
  static readonly InlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGetPath = '/inlivewith/Album/GetCommentAlbumAssetById/{commentAlbumAssetId}/{accountRequestId}/{clubId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Plain$Response(params: {
    commentAlbumAssetId: string;
    accountRequestId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGetPath, 'get');
    if (params) {
      rb.path('commentAlbumAssetId', params.commentAlbumAssetId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Plain(params: {
    commentAlbumAssetId: string;
    accountRequestId: string;
    clubId: string;
  }): Observable<CommentAlbumAssetResponseModelResponseModel> {

    return this.inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>) => r.body as CommentAlbumAssetResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Json$Response(params: {
    commentAlbumAssetId: string;
    accountRequestId: string;
    clubId: string;
  }): Observable<StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGetPath, 'get');
    if (params) {
      rb.path('commentAlbumAssetId', params.commentAlbumAssetId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.path('clubId', params.clubId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Json(params: {
    commentAlbumAssetId: string;
    accountRequestId: string;
    clubId: string;
  }): Observable<CommentAlbumAssetResponseModelResponseModel> {

    return this.inlivewithAlbumGetCommentAlbumAssetByIdCommentAlbumAssetIdAccountRequestIdClubIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CommentAlbumAssetResponseModelResponseModel>) => r.body as CommentAlbumAssetResponseModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet
   */
  static readonly InlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGetPath = '/inlivewith/Album/GetReactOnAlbumAsset/{albumAssetId}/{accountRequestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Plain$Response(params: {
    albumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactAlbumAssetResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('albumAssetId', params.albumAssetId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactAlbumAssetResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Plain(params: {
    albumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<ReactAlbumAssetResponseModelPaginationModelResponseModel> {

    return this.inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReactAlbumAssetResponseModelPaginationModelResponseModel>) => r.body as ReactAlbumAssetResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Json$Response(params: {
    albumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ReactAlbumAssetResponseModelPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGetPath, 'get');
    if (params) {
      rb.path('albumAssetId', params.albumAssetId, {});
      rb.path('accountRequestId', params.accountRequestId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReactAlbumAssetResponseModelPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Json(params: {
    albumAssetId: string;
    accountRequestId: string;
    page?: number;
    limit?: number;
  }): Observable<ReactAlbumAssetResponseModelPaginationModelResponseModel> {

    return this.inlivewithAlbumGetReactOnAlbumAssetAlbumAssetIdAccountRequestIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReactAlbumAssetResponseModelPaginationModelResponseModel>) => r.body as ReactAlbumAssetResponseModelPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumFilesAccountIdClubIdAlbumIdGet
   */
  static readonly InlivewithAlbumFilesAccountIdClubIdAlbumIdGetPath = '/inlivewith/Album/Files/{accountId}/{clubId}/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Plain$Response(params: {
    accountId: string;
    clubId: string;
    albumId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumAssetResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesAccountIdClubIdAlbumIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Plain(params: {
    accountId: string;
    clubId: string;
    albumId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumAssetResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetResponseGeneralPaginationModelResponseModel>) => r.body as AlbumAssetResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Json$Response(params: {
    accountId: string;
    clubId: string;
    albumId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<StrictHttpResponse<AlbumAssetResponseGeneralPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesAccountIdClubIdAlbumIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('clubId', params.clubId, {});
      rb.path('albumId', params.albumId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('details', params.details, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetResponseGeneralPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Json(params: {
    accountId: string;
    clubId: string;
    albumId: string;
    page?: number;
    limit?: number;
    details?: boolean;
  }): Observable<AlbumAssetResponseGeneralPaginationModelResponseModel> {

    return this.inlivewithAlbumFilesAccountIdClubIdAlbumIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetResponseGeneralPaginationModelResponseModel>) => r.body as AlbumAssetResponseGeneralPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumDeleteDelete
   */
  static readonly InlivewithAlbumDeleteDeletePath = '/inlivewith/Album/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumDeleteDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumDeleteDelete$Plain$Response(params?: {
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumDeleteDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumDeleteDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumDeleteDelete$Plain(params?: {
    body?: AlbumInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumDeleteDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumDeleteDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumDeleteDelete$Json$Response(params?: {
    body?: AlbumInputModel
  }): Observable<StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumDeleteDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumDeleteDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumDeleteDelete$Json(params?: {
    body?: AlbumInputModel
  }): Observable<AlbumResponseWithTotalAssetModelResponseModel> {

    return this.inlivewithAlbumDeleteDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumResponseWithTotalAssetModelResponseModel>) => r.body as AlbumResponseWithTotalAssetModelResponseModel)
    );
  }

  /**
   * Path part for operation inlivewithAlbumFilesAccountIdAlbumIdDelete
   */
  static readonly InlivewithAlbumFilesAccountIdAlbumIdDeletePath = '/inlivewith/Album/Files/{accountId}/{albumId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesAccountIdAlbumIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumFilesAccountIdAlbumIdDelete$Plain$Response(params: {
    accountId: string;
    albumId: string;
    body?: AlbumDeleteFilesInputModel
  }): Observable<StrictHttpResponse<AlbumAssetResponseGeneralListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesAccountIdAlbumIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetResponseGeneralListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesAccountIdAlbumIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumFilesAccountIdAlbumIdDelete$Plain(params: {
    accountId: string;
    albumId: string;
    body?: AlbumDeleteFilesInputModel
  }): Observable<AlbumAssetResponseGeneralListResponseModel> {

    return this.inlivewithAlbumFilesAccountIdAlbumIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetResponseGeneralListResponseModel>) => r.body as AlbumAssetResponseGeneralListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inlivewithAlbumFilesAccountIdAlbumIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumFilesAccountIdAlbumIdDelete$Json$Response(params: {
    accountId: string;
    albumId: string;
    body?: AlbumDeleteFilesInputModel
  }): Observable<StrictHttpResponse<AlbumAssetResponseGeneralListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AlbumService.InlivewithAlbumFilesAccountIdAlbumIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.path('albumId', params.albumId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlbumAssetResponseGeneralListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inlivewithAlbumFilesAccountIdAlbumIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  inlivewithAlbumFilesAccountIdAlbumIdDelete$Json(params: {
    accountId: string;
    albumId: string;
    body?: AlbumDeleteFilesInputModel
  }): Observable<AlbumAssetResponseGeneralListResponseModel> {

    return this.inlivewithAlbumFilesAccountIdAlbumIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlbumAssetResponseGeneralListResponseModel>) => r.body as AlbumAssetResponseGeneralListResponseModel)
    );
  }

}
