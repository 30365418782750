<p-dialog #mydialog [(visible)]="openPopup" [draggable]="false" [closable]="false" [resizable]="false" [modal]="true"
  (onHide)="onHide()" (onShow)="getAppEnum()" [header]="(actionType == 'STATUS_IGNORE' && !fromContenu ? 'IGNORE_REPORT' : actionType == 'STATUS_ACCEPTED' && !fromContenu ? 'APPROVE_REPORT' :
    actionType == 'STATUS_ACCEPTED' && fromContenu ? 'DELETE_CONTENT_REPORT' : 'WARN_REPORT') | translate">
  <div class="flex flex-column justify-content-center" style="width: 350px">
    <div *ngIf="actionType == 'STATUS_IGNORE'" class="flex justify-content-center text-center"
      [innerHTML]="'IGNORE_REPORT_TITLE' | translate:{value: report.club?.name!}"></div>
    <div *ngIf="actionType == 'STATUS_ACCEPTED' && !fromContenu" class="flex justify-content-center text-center"
      [innerHTML]="'ARPROVE_REPORT_TITLE' | translate:{value: report.account?.nickName ?? report.club?.name!}">
    </div>
    <div *ngIf="actionType == 'STATUS_ACCEPTED' && fromContenu" class="flex justify-content-center text-center"
      [innerHTML]="'DELETE_CONTENT_REPORT_TITLE' | translate:{value: report.post?.author?.nickName ?? report.commentPost?.author?.nickName ?? report.commentAlbumAsset?.author?.nickName}">
    </div>
    <div *ngIf="actionType == 'STATUS_WARN'" class="flex justify-content-center text-center"
      [innerHTML]="'WARN_REPORT_TITLE' | translate:{value: report.account?.nickName ?? report.club?.name!}"></div>

    <div class="w-100 mt-3" *ngIf="actionType == 'STATUS_ACCEPTED' || actionType == 'STATUS_WARN'">
      <p-dropdown [options]="listReasonReports" optionLabel="value" [(ngModel)]="selectedReason" [appendTo]="mydialog"
        placeholder="{{'SELECT_REASON' | translate}}">
        <ng-template pTemplate="selectedItem">
          <div class="flex align-items-center gap-2" *ngIf="selectedReason">
            <div>{{ selectedReason.value | translate }}</div>
          </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>{{ item.value | translate }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>

    <div class="flex justify-content-center mt-5 align-items-center">
      <button pButton type="button" label="{{'CANCEL' | translate}}" (click)="openPopup = false"
        class="p-button-outlined blue p-button-secondary ml-2 mr-2"></button>
      <button pButton type="button" label="{{'CONFIRM' | translate}}"
        [disabled]="!selectedReason && actionType !== 'STATUS_IGNORE'" (click)="handleReport(report)"
        class="p-button-outlined btn-blue p-button-secondary ml-2"></button>
    </div>
  </div>
</p-dialog>
