
import { AccountService, AppEnumService } from './core/api/be/services';
import { AppEnumStateService, IAppEnumStateModel, InitAppEnumState, } from './core/states/app-enum-state.service';
import { AuthService } from './core/services/auth.service';
import { Component, ElementRef, OnInit } from '@angular/core';
import { GROUP_ILW_DISCUSSION, ILWAppEnum, STORAGE_KEY } from './core/constant';
import { LocalStorageService } from './core/services/local-storage.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { filter, tap } from 'rxjs/operators';
import jwt_decode from "jwt-decode";
import { MessageService } from 'primeng/api';
import { NotificationServicee } from './core/services/notification.service';
import { EventService, IEvent } from './core/services/event.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  email!: string;
  @Select(AppEnumStateService) _state$!: Observable<IAppEnumStateModel>;

  constructor(
    private _router: Router,
    private _appEnumService: AppEnumService,
    private _store: Store,
    private messageService: MessageService,
    private _notificationService: NotificationServicee,
    private _signalR: NotificationServicee,
    private eventService: EventService,
    private el: ElementRef,
  ) {
    this._InitAppEnum().subscribe();
    this._state$
      .pipe(
        tap((res) => {
          ILWAppEnum.ENUM_ILW = res?.appEnum!;
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.userLogged();
    this.handleReceipeDataFromIdentity();
    if (AuthService.GetAccountInfo() && AuthService.GetAccountInfo().id) {
      this._signalR.init();
      this.connectConversation();
      this.connectNotification();
    }

    this.eventService
      .receive()
      .pipe(
        filter((p: IEvent) => p.action == 'PLAY_AUDIO'),
        tap((res) => {
          this.handlePlayAudio(res.payload);
        })
      )
      .subscribe();

    this.eventService
      .receive()
      .pipe(
        filter((p: IEvent) => p.action == 'PLAY_VIDEO'),
        tap((res) => {
          this.handlePlayVideo(res.payload);
        })
      )
      .subscribe();
  }

  handlePlayAudio(currentId: string) {
    this.stopAllVideo();

    let waves = this.el.nativeElement.getElementsByClassName(
      'zone-audio'
    ) as NodeListOf<HTMLDivElement>;
    if (Array.from(waves).length > 0) {
      Array.from(waves).forEach((res) => {
        if (res.id !== currentId) {
          let btnPause = res.getElementsByClassName('btn-pause');
          if (btnPause.item(0)) {
            let el = btnPause.item(0) as HTMLElement;
            el.click();
          }
        }
      });
    }

    document.getElementById(currentId)?.click();
  }

  handlePlayVideo(data: any) {
    this.stopAllAudio();

    if (data.isPlaying) {
      let videos = document.querySelectorAll('video');
      videos.forEach((v) => {
        if (v.id !== data.videoId) {
          v!.pause();
        }
      });
    }
  }

  stopAllVideo() {
    let videos = document.querySelectorAll('video');
    videos.forEach((v) => {
      v!.pause();
    });
  }

  stopAllAudio() {
    let waves = this.el.nativeElement.getElementsByClassName(
      'zone-audio'
    ) as NodeListOf<HTMLDivElement>;
    if (Array.from(waves).length > 0) {
      Array.from(waves).forEach((res) => {
        let btnPause = res.getElementsByClassName('btn-pause');
        if (btnPause.item(0)) {
          let el = btnPause.item(0) as HTMLElement;
          el.click();
        }
      });
    }
    return;
  }

  connectConversation() {
    this._signalR.connectHub();
    // this._signalR.onConversation(this.receiveMessage.bind(this));
  }

  connectNotification() {
    this._signalR.onNotification(this.showNotification.bind(this));
    this._signalR.conected$.subscribe(() => {
      this._signalR.joinGroup(GROUP_ILW_DISCUSSION);
    });
  }

  userLogged(): any {
    const token = LocalStorageService.GetLocalStorage(STORAGE_KEY.TOKEN)
    const isAdminFromLocalStorage = LocalStorageService.GetLocalStorage(STORAGE_KEY.IS_ADMIN);
    console.log(this._router.url);

    if (isAdminFromLocalStorage == "" && this._router.url.includes('/public-dashboard')) {
      this._router.navigate(['auth/login']);
      LocalStorageService.ClearLocalStorage();
      return;
    }

    if (token == null && this._router.url.includes('/public-dashboard')) {
      this._router.navigate(['auth/login']);
      LocalStorageService.ClearLocalStorage();
      return;
    }
  }

  checkAdminRole(token: string): boolean {
    let decoded: any = jwt_decode(token);

    let key = Object.keys(decoded).find(
      (k) => k.indexOf('identity/claims/role') >= 0
    );

    if (!key) {
      return false;
    }

    let obj = Object.assign(decoded);
    if (typeof obj[key] == 'string') {
      if (obj[key!] !== 'administrators') {
        return false;
      } else {
        let roles: string[] = obj[key!];
        if (!roles.includes('administrators')) {
          return false;
        }
      }
    }

    return true;
  }

  handleReceipeDataFromIdentity() {
    window.addEventListener('message', (event) => {
      let data: {
        accountId: string;
        token: string;
        email: string;
        isCreated: string;
        confirmEmailUrl: string;
        action: string;
      } = event.data;

      if (data && data.accountId) {
        const isAdmin = this.checkAdminRole(data!.token!);

        if (!isAdmin) {
          this._router.navigate(['auth/unauthorized'])
            .then(() => {
              localStorage.clear();
            })
          return;
        } else {
          data.token
            ? LocalStorageService.AddLocalStorage('access_token', data.token!)
            : '';

          data.email
            ? LocalStorageService.AddLocalStorage('email', data.email!)
            : '';

          this._router.navigate(['auth/verify'])
        }
      }
    });
  }

  private _InitAppEnum() {
    return this._appEnumService.inlivewithAppEnumAllGet$Json().pipe(
      tap((res) => {
        if (res.res == 0) {
          this._store.dispatch(new InitAppEnumState(res.data!));
        }
      })
    );
  }

  showNotification(data: any) {
    console.log(data);

    if (!data!.name!) {
      return;
    }

    switch (data!.name!) {
      case 'ADMIN_CREATE_NEW_POST':
        this._notificationService.isReloadPage$$.next(true);
        break;
      case 'NOTI_POST_FILE_PROCESS_DONE':
        this._notificationService.isReloadPost$$.next(data);
        break;
      case 'NOTI_CREATE_REPORT_CLUB':
        this._notificationService.isCountNotiNotRead$$.next(true);
        break;
      case 'NOTI_CREATE_REPORT_CONTENT':
        this._notificationService.isCountNotiNotRead$$.next(true);
        break;
      case 'NOTI_CREATE_REPORT_ACCOUNT':
        this._notificationService.isCountNotiNotRead$$.next(true);
        break;
      case 'NOTI_CREATE_CELEBRITY_ALIAS':
        this._notificationService.isCountNotiNotRead$$.next(true);
        break;
      case 'NOTI_CREATE_ACCOUNT_CELEB':
        this._notificationService.isCountNotiNotRead$$.next(true);
        break;
      default:
        break;
    }

    // if () {
    //   this.showNewNotification(
    //     'notification',
    //     JSON.parse(data?.content!)!.Title!,
    //     data
    //   );
    // }
  }

  showNewNotification(keyMessage: string, detail: string, data: any) {
    this.messageService.add({
      severity: 'success',
      summary: JSON.parse(data?.content!)!.Title!,
      detail: JSON.parse(data?.content!)!.SubTitle!,
      key: keyMessage,
      closable: false,
      data: data,
      life: 3 * 1000,
    });
  }
}
