import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { STORAGE_KEY } from "../constant";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      !localStorage.getItem(STORAGE_KEY.TOKEN) ||
      !localStorage.getItem(STORAGE_KEY.EMAIL) ||
      !localStorage.getItem(STORAGE_KEY.IS_ADMIN)
    ) {
      window.open(environment.BASE_URL + '/auth/login', "_self");
      return false;
    }
    return true;
  }
}
