import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventWithCheckAccountJoinEventResponseModel } from 'src/app/core/api/be/models';
import { defaultAvatar } from 'src/app/core/constant';

@Component({
  selector: 'app-detail-event',
  templateUrl: './detail-event.component.html',
  styleUrls: ['./detail-event.component.scss']
})
export class DetailEventComponent implements OnInit {

  @Input() event!: EventWithCheckAccountJoinEventResponseModel
  @Output() goBackToHome = new EventEmitter()

  eventName!: string;
  eventPlaceName!: string;
  eventDescription!: string;

  isEdit: boolean = false
  isPublic!: boolean

  defaultAvatar = defaultAvatar

  constructor() { }

  ngOnInit(): void {
    this.isPublic = this.event.isPublic!
  }

  goBack() {
    this.goBackToHome.emit()
  }

  deleteEvent() {
    if (this.isEdit) {
      this.isEdit = false;
    }
  }

  editEvent() {
    this.isEdit = true
  }

}
