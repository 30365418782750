import { Injectable } from "@angular/core";
import { CryptoService } from "./crypto.service";

@Injectable({
  providedIn: "root",
})
export abstract class LocalStorageService {
  public static AddLocalStorage(key: string, value: string): void {
    localStorage.setItem(key, this._Add(value));
  }

  public static RemoveLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  public static GetLocalStorage(key: string): string {
    let resultStr = this._Get(localStorage.getItem(key) || "");
    return resultStr;
  }

  public static ClearLocalStorage(): void {
    localStorage.clear();
  }

  public static AddSessionStorage(key: string, value: string): void {
    sessionStorage.setItem(key, this._Add(value));
  }

  public static RemoveSessionStorage(key: string): void {
    sessionStorage.removeItem(key);
  }

  public static GetSessionStorage(key: string): string {
    let resultStr = this._Get(sessionStorage.getItem(key) || "");
    return resultStr;
  }

  private static _Add(value: string): string {
    let result: string = CryptoService.EncryptUsingAES256(value);
    return result;
  }

  private static _Get(value: string): string {
    let result: string = CryptoService.DecryptUsingAES256(value);
    result.split("\\").join("");
    result.slice(0, 1);
    result.slice(result.length - 1, 1);
    return result == "" ? "" : JSON.parse(result);
  }
}
