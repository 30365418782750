<div class="flex align-items-start justify-content-between">
  <div class="item" style="border-left: 4px solid #D1DEFB; width: 100%;">
    <div class="avatar">
      <img class="fit-size cursor-pointer" [src]="item.author?.avatar! ? item.author?.avatar! : defaultAvatar" alt="">
    </div>
    <div class="detail">
      <div class="name">
        {{item.author?.nickName!}}
      </div>
      <div class="content" [innerHTML]="urlify(item.message)"></div>
      <div style="position: relative" *ngIf="!item.isBannedByClub">

        <!-- <div class="flex justify-content-between zone-arrow"
            *ngIf="checkOverflow(item?.id!)">
            <i class="pi pi-arrow-left arrow cursor-pointer"
                (click)="scrollTo(item.id!)"></i>
            <i class="pi pi-arrow-right arrow cursor-pointer"
                (click)="scrollTo(item.id!, false)"></i>
        </div> -->

        <div class="flex flex-wrap" *ngIf="item?.mediaCommentPosts?.length! > 0" [id]="item.id">
          <div *ngFor="let asset of item?.mediaCommentPosts!; let i = index">
            <ng-container *ngIf="!checkProcessMediaFail(asset)">
              <div class="list-media flex justify-content-center align-items-center"
                *ngIf="(checkTypeMedia(asset) == 'UNKNOWN')">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>

              <div class="list-media w-100 h-100 cursor-pointer" *ngIf="(checkTypeMedia(asset) == 'IMAGE')">
                <img [src]="getUrl(asset)" class="fit-size" (click)="showMultiMedia(item.mediaCommentPosts!, i)">
              </div>

              <div class="list-media w-100 h-100 cursor-pointer" *ngIf="checkTypeMedia(asset) == 'VIDEO'">
                <app-video-layer *ngIf="asset?.url!" [layout]="'full'" [url]="asset?.url!" [poster]="getUrl(asset)"
                  (dblclick)="showMultiMedia(item.mediaCommentPosts!, i)">
                </app-video-layer>
              </div>
            </ng-container>

            <ng-container *ngIf="checkProcessMediaFail(asset)">
              <div class="list-media flex justify-content-center position-relative align-items-center zone-media-fail"
                [innerHTML]="'TEXT_WHEN_ERROR_UPLOAD_MEDIA' | translate">
              </div>
            </ng-container>
          </div>
        </div>
        <div class="mr-1 w-100" *ngFor="let asset of item?.mediaCommentPosts!; let i = index">
          <div class="w-100" style="margin: 10px 0px;" *ngIf="checkTypeMedia(asset) == 'AUDIO'">
            <app-wave-form-audio [assetUrl]="asset?.url!"></app-wave-form-audio>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mr-5 mt-4 cursor-pointer">
    <span class="text-error" (click)="deleteComment()">{{'DELETE' | translate}}</span>
  </div>
</div>
