import { Component, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { TopClubForDashboardResponse } from 'src/app/core/api/be/models';
import { BackOfficeService } from 'src/app/core/api/be/services';

@Component({
  selector: 'app-list-top-club',
  templateUrl: './list-top-club.component.html',
  styleUrls: ['./list-top-club.component.scss'],
})
export class ListTopClubComponent implements OnInit {
  topClubs!: TopClubForDashboardResponse[];

  constructor(private _backOfficeService: BackOfficeService) {}

  ngOnInit(): void {
    this.getTopClub();
  }

  getTopClub() {
    this._backOfficeService
      .inlivewithBackOfficeGetTopClubForDashboardPost$Json({
        limit: 100000000,
      })
      .pipe(
        tap((res) => {
          this.topClubs = res!.data?.page!;
        })
      )
      .subscribe();
  }
}
