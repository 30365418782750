import { Subject } from 'rxjs';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ilw-infinity-scroll',
  templateUrl: './ilw-infinity-scroll.component.html',
  styleUrls: ['./ilw-infinity-scroll.component.scss'],
})
export class IlwInfinityScrollComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly TIME_DELAY = 100;
  private readonly EVENT_NAME = 'scroll';
  private readonly PERCENT_LOADING_ELEMENT = 0.8;

  @Input() elementScroll!: HTMLElement;
  @Input() isLoading: boolean = false;
  @Input() isDone: boolean = false;
  @Input() isScrollToTop: boolean = false;
  @Input() isHorizontalScroll: boolean = true;
  @Input() templateLoading!: TemplateRef<HTMLElement>;

  private _isUnsubscribe: boolean = false;
  @Input() set isUnsubscribe(value: boolean) {
    this._isUnsubscribe = value;
    if (value) {
      this._sub.unsubscribe();
    }
  }
  get isUnsubscribe() {
    return this._isUnsubscribe;
  }

  @Input() handlingAfterGetData: Subject<any> = new Subject();

  @Output() scrollEnd = new EventEmitter<HTMLElement>();

  @ViewChild('loading') elementLoading!: ElementRef<HTMLDivElement>;

  private current = 0;
  private _sub: Subscription = new Subscription();

  constructor() { }

  ngOnInit(): void {
    this.handlingAfterGetData.subscribe(() => {
      if (this.isScrollToTop)
        setTimeout(() => {
          this.elementScroll.scrollTop = this.elementScroll.scrollTop + this.elementScroll.scrollHeight - this.current;
        });
    })
  }

  ngAfterViewInit(): void {
    if (this.elementScroll) this._sub.add(this.addEventScroll());
    if (this.isScrollToTop) {
      this.elementScroll.style.scrollBehavior = 'auto';
      setTimeout(() => {
        this.elementScroll.scrollTop = this.elementScroll.scrollHeight;
      }, 100);
    }
  }

  private addEventScroll(): Subscription {
    console.log("????");

    return fromEvent(this.elementScroll, this.EVENT_NAME).pipe(debounceTime(this.TIME_DELAY))
      .subscribe(() => {
        if (!this.isLoading) {
          const check: boolean = this.isHorizontalScroll ? this.checkScrollEndHorizontal() : this.checkScrollEndVertical();
          if (check) {
            this.current = this.elementScroll.scrollHeight;
            this.scrollEnd.emit(this.elementScroll);
          }
        }
      })
  }

  private checkScrollEndVertical(): boolean {
    const elementLoading: HTMLDivElement = this.elementLoading.nativeElement;
    return this.elementScroll.scrollLeft + this.elementScroll.getBoundingClientRect().width >= this.elementScroll.scrollWidth - elementLoading.getBoundingClientRect().width * this.PERCENT_LOADING_ELEMENT;
  }

  private checkScrollEndHorizontal(): boolean {
    const elementLoading: HTMLDivElement = this.elementLoading.nativeElement;
    return this.isScrollToTop ? this.elementScroll.scrollTop === 0 : this.elementScroll.scrollTop + this.elementScroll.getBoundingClientRect().height >= this.elementScroll.scrollHeight - elementLoading.getBoundingClientRect().height * this.PERCENT_LOADING_ELEMENT;
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
