<p-dialog [(visible)]="isShowPopup" [modal]="true" [draggable]="false" [resizable]="false" (onHide)="closePopup()"
    [closable]="true">
    <div>
        <div class="flex flex-column justify-content-center text-center text-black">

            <span class="fs-20 text-bold">
                {{title}}
            </span>

            <div class="mt-3 fs-14">
                {{content}}
            </div>

        </div>
        <div class="flex align-items-center justify-content-center mt-5">
            <button pButton [label]="firstButtonTitle"
                class="p-button-red p-button-secondary btn-white bg-transparent border-white ml-2"
                (click)="closePopup()">
            </button>
            <button pButton [label]="secondButtonTitle"
                class="p-button-red p-button-secondary btn-blue bg-white border-white ml-2"
                (click)="doAction.emit()">
            </button>
        </div>
    </div>
</p-dialog>
