import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { AccountResponseGeneral, AccountResponseWithRoleInCLubModel, CommentPostReportResponseModel, ReportModelAllWithDetail, ReportResponseModel } from 'src/app/core/api/be/models';
import { BackOfficeService } from 'src/app/core/api/be/services';
import { TYPE_STATUS_REPORT, defaultAvatar } from 'src/app/core/constant';
import { LimitStringPipe } from 'src/app/core/pipe/limit-string.pipe';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-reporting-account',
  templateUrl: './reporting-account.component.html',
  styleUrls: ['./reporting-account.component.scss']
})
export class ReportingAccountComponent implements OnInit {

  @Input() account!: AccountResponseGeneral

  listReports: ReportModelAllWithDetail[] = [];
  totalReports: number = 0

  defaultAvatar = defaultAvatar

  constructor(
    private _backOfficeService: BackOfficeService
  ) { }

  ngOnInit(): void {
    this.getListReports();
  }

  getListReports() {
    this._backOfficeService.inlivewithBackOfficeGetAllReportByAccountIdIdentityUserIdPost$Json({
      identityUserId: this.account.identityUserId!,
      limit: 1000,
      body: [TYPE_STATUS_REPORT.STATUS_ACCEPTED, TYPE_STATUS_REPORT.STATUS_IGNORE, TYPE_STATUS_REPORT.STATUS_WARN]
    }).pipe(
      tap(res => {
        if (res.res == 0) {
          this.listReports = res.data?.page!
          this.listReports.forEach(x => this.totalReports += x.numberReports!)
        }
      })
    ).subscribe();
  }

  timeAgo = (date: string) => {
    let currentLang =
      LocalStorageService.GetLocalStorage('language').toLowerCase();
    let text = moment
      .utc(date)
      .locale(currentLang ? currentLang : 'fr')
      .fromNow();

    text = text.includes('il y a ') ? text.replace('il y a ', '').replace('moi', 'mois').replace('une', '1').replace('jour', 'j').replace('minute', 'm')
      .replace('heure', 'h').replace('s', '').replace('quelquesecondes', '1s').replace('un', '1') : text;

    return text;
  };

}
