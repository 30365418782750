<div class="w-100 flex align-items-start position-relative">
  <div class="post-zone cursor-pointer" *ngIf="post" [ngClass]="{'post-in-deletion': fadePost}"
    [ngClass]="{'post-selected': selectedPost && checkClicked}" (click)="selectedPostEvent()">
    <div class="flex flex-row justify-content-between" style="box-sizing: border-box;">
      <div class="flex align-items-center">
        <div class="zone-avatar">
          <img [src]="post.authorAvatar! ? post.authorAvatar : defaultAvatar" class="fit-size cursor-pointer">
        </div>
        <div class="flex flex-column ml-2">
          <div class="flex align-items-center">
            <span class="mr-2 name-author cursor-pointer">{{post.authorName}} </span>
          </div>
          <span class="text-disabled fs-11 mt-2 text-black">{{timeAgo(post.dateCreated!)}}</span>
        </div>
      </div>
      <div class="flex align-items-center justify-content-between">
        <div>
          <span class="btn-edit-post mr-2" *ngIf="isShowDeletePost" (click)="editPost($event)">
            {{'EDIT' | translate}}
          </span>
        </div>
        <div>
          <span class="btn-delete-post ml-2" *ngIf="isShowDeletePost" (click)="deletePost($event)">
            {{'DELETE' | translate}}
          </span>
        </div>
      </div>
    </div>
    <div class="mb-3 mt-3 stage" [innerHTML]="'CLUB_TOUCHED' | translate:{value:post.totalClub}">
    </div>
    <div class="mt-3 mb-2 text-black" [class.show]="show">
      <div id="content" class="show overflow-wrap-breakword" style="white-space: break-spaces; word-wrap: break-word;"
        [innerHTML]="urlify(post!.message! | limitString:completeText)"></div>
    </div>

    <div class="text-right cursor-pointer" *ngIf="post?.message! && post?.message!.length! > 400">
      <span style="color:#3b82f6" (click)="handleText()">{{(show ? 'SHOW_LESS' : 'SEE_MORE') | translate}}</span>
    </div>

    <div class="flex justify-content-center w-100">
      <app-layout-media-post [id]="post!.id!" [post]="post" [isAdmin]="true">
      </app-layout-media-post>
    </div>

    <div class="flex justify-content-between align-items-center mt-5 mb-3">
      <div class="flex">
        <div class="flex align-items-center" style="width: 72px;  color: #D1DEFB;">
          <i class="pi pi-heart-fill cursor-pointer" style="font-size: 24px;"></i>
          <span class="fs-12 ml-2 cursor-pointer">{{post!.totalReact!}}</span>
        </div>
        <div class="flex ml-5 cursor-pointer align-items-center" style="height: 23px;  color: #D1DEFB;">
          <img src="../../../assets/images/svgs/comment-fill.svg">
          <span class="fs-12 ml-2">{{post!.totalComment!}}</span>
        </div>
      </div>
    </div>

  </div>
</div>
