import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClubResponseWithTotalMemberAndGoInliveModel } from 'src/app/core/api/be/models';
import { ClubService } from 'src/app/core/api/be/services';
import { defaultAvatar } from 'src/app/core/constant';

@Component({
  selector: 'app-list-club-follow',
  templateUrl: './list-club-follow.component.html',
  styleUrls: ['./list-club-follow.component.scss'],
})
export class ListClubFollowComponent implements OnInit {
  @Input() accountId!: string;
  listClub: ClubResponseWithTotalMemberAndGoInliveModel[] = [];
  totalItems!: number;
  currentPage = 1;
  defaultAvatar = defaultAvatar;

  constructor(private _router: Router, private _clubService: ClubService) { }

  ngOnInit(): void {
    this.getClubByAdmin(1);
  }

  getClubByAdmin(page: number) {
    return this._clubService
      .inlivewithClubsByAccountAccountIdGet$Json({
        accountId: this.accountId,
        limit: 10,
        page: this.currentPage,
      })
      .subscribe((res) => {
        this.listClub = res.data?.page!;
        this.totalItems = res.data?.totalItems!;
      });
  }

  gotoDetail(club: any) { }

  getType(type: string) {
    if (type == 'CLUB_TYPE_FAN') {
      return 'Fan';
    }
    return 'Talent';
  }

  getCategoryImage(category: string) {
    let url;
    if (category == 'CLUB_CATEGORY_MUSIC') {
      url = 'https://storage.inlivewith.dev.ilwith.io/categories/music.svg';
    }
    if (category == 'CLUB_CATEGORY_DANCE') {
      url = 'https://storage.inlivewith.dev.ilwith.io/categories/dance.svg';
    }
    if (category == 'CLUB_CATEGORY_HUMOR') {
      url = 'https://storage.inlivewith.dev.ilwith.io/categories/humor.svg';
    }
    if (category == 'CLUB_CATEGORY_MEDIA') {
      url = 'https://storage.inlivewith.dev.ilwith.io/categories/media.svg';
    }
    return url;
  }

  onRowSelect(event: any) {
    console.log('nudes', event.data);
    // this._router.navigate(['clubs/detail-club'], {
    //   queryParams: { clubId: event.data.id },
    // });
  }

  onRowUnselect(event: any) {
    console.log('nudes');
  }

  isRowSelectable(event: any) {
    return !this.isOutOfStock(event.data);
  }

  isOutOfStock(data: any) {
    return data.inventoryStatus === 'OUTOFSTOCK';
  }

  paginate(event: any) {
    this.currentPage = event.first / 10 + 1;
    this.getClubByAdmin(this.currentPage);
  }
}
